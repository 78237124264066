import React, { ReactNode } from "react";

const ConditionalRenderer = ({
  check,
  children,
}: {
  readonly check: boolean;
  readonly children: ReactNode;
}) => {
  if (!check) return <></>;

  return <>{children}</>;
};

export const withNullCheck = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
  check: boolean
) => {
  return (props: P) => {
    if (check) {
      return <WrappedComponent {...props} />;
    }
    return <></>;
  };
};

const TernaryRenderer = ({
  check,
  whenTrue,
  whenFalse,
}: {
  check: boolean;
  whenTrue: ReactNode;
  whenFalse: ReactNode;
}) => {
  return <>{check ? whenTrue : whenFalse}</>;
};

export { ConditionalRenderer, TernaryRenderer };
