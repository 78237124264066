import { Button, ErrorSvg } from "pixel";
import { useErrorBoundary } from "react-error-boundary";

export default function ErrorBoundaryFallback() {
  const { resetBoundary } = useErrorBoundary();

  const titleClasses = `text-primary-DN0 text-center font-semibold text-size-20 pb-2 font-opensans dark:text-primary-DN110`;
  const descriptionClasses = `mb-6 text-primary-N60 text-center text-size-14 mt-0`;
  const panelClasses =
    "panel font-inter bg-neutral-0 rounded-md shadow-4xl dark:bg-neutral-dark-5 dark:border-neutral-dark-10 dark:shadow-transparent";

  return (
    <div className={panelClasses}>
      <div className="flex min-h-screen flex-col items-center justify-center">
        <div className="mb-4">
          <ErrorSvg />
        </div>

        <h3 className={titleClasses}>Something went wrong</h3>

        <p className={descriptionClasses}>Try refreshing the page or try again later</p>

        <Button variant="text" onClick={resetBoundary}>
          Try again
        </Button>
      </div>
    </div>
  );
}
