//Core imports
import { PropsWithChildren, useEffect } from "react";

//Pixel imports

import "pixel/styles/ag-grid-pixel.css";
import "pixel/styles/rs-picker.css";

//Helpers imports
import {
  RootUserFormData,
  WIAMUserFormData,
} from "../packages/components/display/LandingPage/Forms/types";
import LandingPage from "../packages/components/display/LandingPage/LandingPage";
import { AuthContextProps } from "../packages/context/auth/AuthProvider";
import { useAuth } from "../packages/context/auth/useAuth";
import { useFpapi } from "../packages/context/fpapi/useFpapi";
import { useUserInfo } from "../packages/context/userinfo/useUserInfo";
import DashboardMasterLayout from "./DashboardMasterLayout";

const attemptSilentLogin = (auth: AuthContextProps) => {
  const isSilentLoginAttempted = sessionStorage.getItem("isSilentLoginAttempted");

  return (
    !auth.isLoading &&
    !auth.isAuthenticated &&
    (!isSilentLoginAttempted || isSilentLoginAttempted === "false")
  );
};

export default function MasterLayout({ children }: PropsWithChildren) {
  const auth = useAuth();

  const { fpapi } = useFpapi();
  const userInfo = useUserInfo();

  const isReady = Boolean(auth.isAuthenticated && userInfo.data.userInfo && fpapi);

  useEffect(() => {
    if (attemptSilentLogin(auth)) {
      auth.silentLogin();
    }
  }, [auth, auth.isAuthenticated, auth.isLoading]);

  const onLandingPageSubmit = (data: WIAMUserFormData | RootUserFormData) => {
    let extraQueryParamsObject: { email: string; tenant?: string } = {
      email: data.email,
    };

    if ("tenant" in data) {
      extraQueryParamsObject.tenant = data?.tenant?.trim();
    }

    auth.login({
      extraQueryParams: extraQueryParamsObject,
    });
  };

  const render = () => {
    if (!auth.isAuthenticated) {
      return <LandingPage onLandingPageSubmit={onLandingPageSubmit} />;
    } else if (isReady) {
      return <DashboardMasterLayout>{children}</DashboardMasterLayout>;
    } else {
      return null;
    }
  };

  return render();
}
