import * as d3 from "d3";
import { useEffect, useRef } from "react";

const SankeyChart = ({
  inputDDL,
  customSetting,
}: {
  inputDDL: string;
  customSetting: Record<string, string | number>;
}) => {
  const chartRef = useRef<HTMLParagraphElement>(null);
  const svgScratchRef = useRef<SVGSVGElement>(null);
  const sankeySvgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // constants.js: Reference file with several values used in sankeyDDLParser.js
    /* eslint-disable no-unused-vars */

    const MAXBREAKPOINT = 9999,
      // skmSettings = Settings required to render a diagram.
      // Format = field_name: [data type, initial value, allowed values]
      // 'Allowed values' contains different things per data type:
      //   whole = [min, [max]], always >= 0
      //   integer = [min, [max]], can be negative
      //   contained = [min, dimension to compare to (either 'h' or 'w')]
      //   breakpoint = [min]
      //   text = [min-length, max-length]
      //   radio & list = [literal list of allowed values]
      // These types' constraints are NOT specified here; they are enforced in code:
      //   decimal = always 0.0 - 1.0
      //   color = always a hex color spec
      //   yn = always y or n
      skmSettings = new Map([
        ["size_w", ["whole", 1000, [40]]],
        ["size_h", ["whole", 600, [40]]],
        ["margin_l", ["contained", 12, [0, "w"]]],
        ["margin_r", ["contained", 12, [0, "w"]]],
        ["margin_t", ["contained", 18, [0, "h"]]],
        ["margin_b", ["contained", 20, [0, "h"]]],
        ["bg_color", ["color", "#ffffff", []]],
        ["bg_transparent", ["yn", "n", []]],
        ["node_w", ["contained", 9, [0, "w"]]],
        ["node_h", ["half", 50, [0, 100]]],
        ["node_spacing", ["half", 85, [0, 100]]],
        ["node_border", ["contained", 0, [0, "w"]]],
        ["node_theme", ["radio", "none", ["a", "b", "c", "d", "none"]]],
        ["node_color", ["color", "#888888", []]],
        ["node_opacity", ["decimal", 1.0, []]],
        ["flow_curvature", ["decimal", 0.5, []]],
        ["flow_inheritfrom", ["radio", "none", ["source", "target", "outside-in", "none"]]],
        ["flow_color", ["color", "#999999", []]],
        ["flow_opacity", ["decimal", 0.45, []]],
        ["layout_order", ["radio", "automatic", ["automatic", "exact"]]],
        ["layout_justifyorigins", ["yn", "n", []]],
        ["layout_justifyends", ["yn", "n", []]],
        ["layout_reversegraph", ["yn", "n", []]],
        ["layout_attachincompletesto", ["radio", "nearest", ["leading", "nearest", "trailing"]]],
        ["labels_color", ["color", "#000000", []]],
        ["labels_hide", ["yn", "n", []]],
        ["labels_highlight", ["decimal", 0.75, []]],
        ["labels_fontface", ["radio", "sans-serif", ["monospace", "sans-serif", "serif"]]],
        ["labels_linespacing", ["decimal", 0.15, []]],
        ["labels_relativesize", ["whole", 100, [50, 150]]],
        ["labels_magnify", ["whole", 100, [50, 150]]],
        ["labelname_appears", ["yn", "y", []]],
        ["labelname_size", ["half", 16, [6]]],
        ["labelname_weight", ["whole", 400, [100, 700]]],
        ["labelvalue_appears", ["yn", "y", []]],
        ["labelvalue_fullprecision", ["yn", "y", []]],
        ["labelvalue_position", ["radio", "below", ["above", "before", "after", "below"]]],
        ["labelvalue_weight", ["whole", 400, [100, 700]]],
        ["labelposition_autoalign", ["integer", 0, [-1, 1]]],
        ["labelposition_scheme", ["radio", "auto", ["auto", "per_stage"]]],
        ["labelposition_first", ["radio", "before", ["before", "after"]]],
        ["labelposition_breakpoint", ["breakpoint", MAXBREAKPOINT, [2]]],
        ["value_format", ["list", ",.", [",.", ".,", " .", " ,", "X.", "X,"]]],
        ["value_prefix", ["text", "", [0, 99]]],
        ["value_suffix", ["text", "", [0, 99]]],
        ["themeoffset_a", ["whole", 9, [0, 9]]],
        ["themeoffset_b", ["whole", 0, [0, 9]]],
        ["themeoffset_c", ["whole", 0, [0, 7]]],
        ["themeoffset_d", ["whole", 0, [0, 11]]],
        ["meta_mentionsankeymatic", ["yn", "y", []]],
        ["meta_listimbalances", ["yn", "y", []]],
        // 'internal' settings are never exported, but can be imported:
        ["internal_iterations", ["whole", 25, [0, 50]]],
        ["internal_revealshadows", ["yn", "n", []]],
      ]),
      // Some reusable regular expressions to be precompiled:
      reWholeNumber = /^\d+$/,
      reHalfNumber = /^\d+(?:\.5)?$/,
      reInteger = /^-?\d+$/,
      reDecimal = /^\d(?:.\d+)?$/,
      reCommentLine = /^(?:'|\/\/)/, // Line starts with // or '
      reYesNo = /^(?:y|yes|n|no)/i, // = Y/y/Yes/YES/etc. or N/n/No/NO/etc.
      reYes = /^(?:y|yes)/i, // = Y/y/Yes/YES/etc.
      // Settings Notes:
      //   * We look for settings & move lines FIRST.
      //   * If they prove valid, we apply them to the UI and convert them to
      //     COMMENTS in the input (with a checkmark to indicate success).
      //   * The idea here is to avoid having input text conflicting with
      //     the UI controls. Since any valid setting line is immediately
      //     applied and disappears, we can't have a conflict.
      //
      // reSettingsValue:
      // One to two words, followed by a value made up of letters,
      // numbers, decimals and/or dashes.
      // ex. "node theme a", "flow inheritfrom outside-in"
      reSettingsValue = /^((?:\w+\s*){1,2}) (#?[\w.-]+)$/,
      // reSettingsText:
      // One to two words followed by a quoted string (possibly empty):
      // ex: "value prefix ''", "suffix 'M'"
      // If the raw string contains a single quote, it will be doubled here.
      reSettingsText = /^((?:\w+\s*){1,2}) '(.*)'$/,
      reMoveLine = /^move (.+) (-?\d(?:.\d+)?), (-?\d(?:.\d+)?)$/,
      settingsAppliedPrefix = "// \u2713 ", // u2713 = a little check mark
      reNodeLine = /^:(.+) #([a-f0-9]{0,6})?(\.\d{1,4})?\s*(>>|<<)*\s*(>>|<<)*$/i,
      reFlowTargetWithSuffix = /^(.+)\s+(#\S+)$/,
      reColorPlusOpacity = /^#([a-f0-9]{3,6})?(\.\d{1,4})?$/i,
      reBareColor = /^(?:[a-f0-9]{3}|[a-f0-9]{6})$/i,
      reRGBColor = /^#(?:[a-f0-9]{3}|[a-f0-9]{6})$/i,
      colorGray60 = "#999",
      // Some prime constants for enum values:
      [IN, OUT, BEFORE, AFTER] = [13, 17, 19, 23],
      // fontMetrics = measurements relating to labels & their highlights
      //   Structure:
      //     browserKey ('firefox' or '*')
      //       -> font-face or '*'
      //         -> values
      //   Value list:
      //     - dy: what fraction of the BoundingBox to lower labels to make them
      //       vertically-centered relative to their Node
      //     - top, bot: how many x-heights to pad above/below the BoundingBox
      //     - inner: how many em-widths to pad between the label and the
      //       highlight's edge (could be on the left or right)
      //     - outer: how many em-widths to pad at the end furthest from the Node
      //     - marginRight: what multiple of 'inner' to move labels to the right
      //     - marginAdjLeft: offset to add to marginRight when moving labels
      //       to left
      fontMetrics = {
        firefox: {
          "sans-serif": {
            dy: 0.35,
            top: 0.55,
            bot: 0.25,
            inner: 0.35,
            outer: 0.35,
            marginRight: 1.4,
            marginAdjLeft: 0,
          },
          monospace: {
            dy: 0.31,
            top: 0.3,
            bot: 0.25,
            inner: 0.35,
            outer: 0.35,
            marginRight: 1.48,
            marginAdjLeft: -0.08,
          },
          "*": {
            dy: 0.31,
            top: 0.3,
            bot: 0.25,
            inner: 0.35,
            outer: 0.35,
            marginRight: 1.35,
            marginAdjLeft: -0.05,
          },
        },
        "*": {
          monospace: {
            dy: 0.28,
            top: 0.3,
            bot: 0.3,
            inner: 0.35,
            outer: 0.38,
            marginRight: 1.45,
            marginAdjLeft: 0,
          },
          "*": {
            dy: 0.29,
            top: 0.3,
            bot: 0.3,
            inner: 0.35,
            outer: 0.38,
            marginRight: 1.35,
            marginAdjLeft: 0,
          },
        },
      },
      // highlightStyles = settings relating to label highlight appearance
      //   Structure:
      //     mode ('dark' or 'light')
      //       -> state ('orig' or 'hover')
      //         -> values (directly applied as SVG attributes)
      highlightStyles = {
        // When text is dark-on-light:
        dark: {
          orig: {
            fill: "#fff",
            stroke: "none",
            stroke_width: 0,
            stroke_opacity: 0,
          },
          hover: {
            fill: "#ffb",
            stroke: "#440",
            stroke_width: 1,
            stroke_opacity: 0.7,
          },
        },
        // When text is light-on-dark:
        light: {
          orig: {
            fill: "#000",
            stroke: "none",
            stroke_width: 0,
            stroke_opacity: 0,
          },
          hover: {
            fill: "#603",
            stroke: "#fff",
            stroke_width: 1.7,
            stroke_opacity: 0.9,
          },
        },
      };

    /* ******************************************************************************************** */
    const overriddend3 = d3 as any;

    overriddend3.sankey = () => {
      const sankey = {},
        // Set up some handy constants (acting as enums)
        // These numbers are relatively prime so each cross-product is unique
        // (when we need that)
        [SOURCES, TARGETS, TOP, BOTTOM, NEAREST] = [2, 3, 5, 7, 11];

      // Set by inputs:
      let nodeWidth = 9;
      let nodeHeightFactor = 0.5;
      let nodeSpacingFactor = 0.85;
      let size = { w: 1, h: 1 };
      let nodes: any = [];
      let flows: any = [];
      let rightJustifyEndpoints = false;
      let leftJustifyOrigins = false;
      let autoLayout = true;
      let attachIncompletesTo = NEAREST;
      // Calculated:
      let stagesArr: any = [];
      let maximumNodeSpacing = 0;
      let actualNodeSpacing = 0;
      let maxStage = -1;

      // ACCESSORS //
      /* eslint-disable func-names */
      (sankey as any).nodeWidth = function (x: any) {
        if (arguments.length) {
          nodeWidth = +x;
          return sankey;
        }
        return nodeWidth;
      };

      (sankey as any).nodeHeightFactor = function (x: any) {
        if (arguments.length) {
          nodeHeightFactor = +x;
          return sankey;
        }
        return nodeHeightFactor;
      };

      (sankey as any).nodeSpacingFactor = function (x: any) {
        if (arguments.length) {
          nodeSpacingFactor = +x;
          return sankey;
        }
        return nodeSpacingFactor;
      };

      (sankey as any).nodes = function (x: any) {
        if (arguments.length) {
          nodes = x;
          return sankey;
        }
        return nodes;
      };

      (sankey as any).flows = function (x: any) {
        if (arguments.length) {
          flows = x;
          return sankey;
        }
        return flows;
      };

      (sankey as any).size = function (x: any) {
        if (arguments.length) {
          size = x;
          return sankey;
        }
        return size;
      };

      (sankey as any).rightJustifyEndpoints = function (x: any) {
        if (arguments.length) {
          rightJustifyEndpoints = x;
          return sankey;
        }
        return rightJustifyEndpoints;
      };

      (sankey as any).leftJustifyOrigins = function (x: any) {
        if (arguments.length) {
          leftJustifyOrigins = x;
          return sankey;
        }
        return leftJustifyOrigins;
      };

      (sankey as any).autoLayout = function (x: any) {
        if (arguments.length) {
          autoLayout = x;
          return sankey;
        }
        return autoLayout;
      };

      (sankey as any).attachIncompletesTo = function (x: any) {
        if (arguments.length) {
          switch (x.toLowerCase()) {
            case "leading":
              attachIncompletesTo = TOP;
              break;
            case "trailing":
              attachIncompletesTo = BOTTOM;
              break;
            case "nearest":
              attachIncompletesTo = NEAREST;
              break;
            // no default
          }
          return sankey;
        }
        return attachIncompletesTo;
      };

      // Getters:
      (sankey as any).stages = () => stagesArr;

      // FUNCTIONS //

      // valueSum: Add up all the 'value' keys from a list of objects:
      function valueSum(list: any) {
        return overriddend3.sum(list, (d: any) => d.value);
      }

      // divide: Substitute MIN_VALUE if a denominator would be 0:
      function divide(a: any, b: any) {
        return a / (b || Number.MIN_VALUE);
      }

      // yCenter & yBottom: Y-position of the middle and end of a node.
      function yCenter(n: any) {
        return n.y + n.dy / 2;
      }
      function yBottom(n: any) {
        return n.y + n.dy;
      }

      // source___/target___: return the ___ of one end of a flow:
      function sourceTop(f: any) {
        return f.source.y + f.sy;
      }
      function targetTop(f: any) {
        return f.target.y + f.ty;
      }
      function sourceCenter(f: any) {
        return f.source.y + f.sy + f.dy / 2;
      }
      function targetCenter(f: any) {
        return f.target.y + f.ty + f.dy / 2;
      }
      function sourceBottom(f: any) {
        return f.source.y + f.sy + f.dy;
      }
      function targetBottom(f: any) {
        return f.target.y + f.ty + f.dy;
      }

      // Get the extreme bounds across a list of Nodes:
      function leastY(nodeList: any) {
        return overriddend3.min(nodeList, (n: any) => n.y);
      }
      function greatestY(nodeList: any) {
        return overriddend3.max(nodeList, (n: any) => yBottom(n));
      }

      // Sorting functions:
      function bySourceOrder(a: any, b: any) {
        return a.sourceRow - b.sourceRow;
      }
      function byTopEdges(a: any, b: any) {
        return a.y - b.y;
      }

      // connectFlowsToNodes: Populate flows in & out for each node.
      function connectFlowsToNodes() {
        // Initialize the flow buckets:
        nodes.forEach((n: any) => {
          // Lists of flows which use this node as their target or source:
          n.flows = { [IN]: [], [OUT]: [] };
          // Mark these as real nodes we want to see:
          n.isAShadow = false;
        });

        // Connect each flow to its two nodes:
        flows.forEach((f: any) => {
          // When the source or target is a number, that's an index;
          // convert it to the referenced object:
          if (typeof f.source === "number") {
            f.source = nodes[f.source];
          }
          if (typeof f.target === "number") {
            f.target = nodes[f.target];
          }

          // Add this flow to the affected source & target:
          f.source.flows[OUT].push(f);
          f.target.flows[IN].push(f);
          // By default, real flows are used when sorting/placing within a node.
          f.useForVisiblePlacing = true;
          // Mark these as real flows we want to see:
          f.isAShadow = false;
          f.hasAShadow = false;
        });
      }

      // computeNodeValues: Compute the value of each node by summing the
      // associated flows:
      function computeNodeValues() {
        nodes.forEach((n: any) => {
          // Remember the totals in & out:
          n.total = {
            [IN]: valueSum(n.flows[IN]),
            [OUT]: valueSum(n.flows[OUT]),
          };
          // Each node's value will be the greater of the two (or else the
          // smallest positive value):
          n.value = Math.max(n.total[IN], n.total[OUT], Number.MIN_VALUE);
        });
      }

      // allFlowStats(nodeList): provides all components necessary to make
      // weighted-center calculations. These are used to decide where a
      // group of nodes would ideally 'want' to be.
      function allFlowStats(nodeList: any) {
        // flowSetStats: get the total weight+value from a group of flows
        function flowSetStats(whichFlows: any) {
          // Get every flow touching one side & treat them as one list:
          const flowList = nodeList
            .map((n: any) => n.flows[whichFlows])
            .flat()
            // Use the weighted value of a flow (this handles shadows):
            .filter((f: any) => f.weightedValue > 0);
          // If 0 flows, return enough structure to satisfy the caller:
          if (flowList.length === 0) {
            return {
              value: 0,
              sources: { weight: 0 },
              targets: { weight: 0 },
            };
          }

          return {
            value: overriddend3.sum(flowList, (f: any) => f.weightedValue),
            sources: {
              weight: overriddend3.sum(flowList, (f: any) => sourceCenter(f) * f.weightedValue),
              maxSourceStage: overriddend3.max(flowList, (f: any) => f.source.stage),
            },
            targets: {
              weight: overriddend3.sum(flowList, (f: any) => targetCenter(f) * f.weightedValue),
              minTargetStage: overriddend3.min(flowList, (f: any) => f.target.stage),
            },
          };
        }

        // Return the stats for the set of all flows touching these nodes:
        return { [IN]: flowSetStats(IN), [OUT]: flowSetStats(OUT) };
      }

      // placeFlowsInsideNodes(nodeList):
      //   Compute the y-offset of every flow's source and target endpoints,
      //   relative to the each node's y-position.
      function placeFlowsInsideNodes(nodeList: any) {
        // sortFlows(node, placing):
        //   Given a node & a side, reorder that group of flows as best we can.
        //   'placing' indicates which end of the flows we're working on here:
        //      - TARGETS = we're placing the targets of n.flows[IN]
        //      - SOURCES = we're placing the sources of n.flows[OUT]
        function sortFlows(n: any, placing: any) {
          const dir = placing === TARGETS ? IN : OUT,
            fStats = allFlowStats([n]),
            [flowsToSort, totalFlowValue] = [n.flows[dir], n.total[dir]],
            totalFlowWeight = (dir === IN ? fStats[IN].sources : fStats[OUT].targets).weight,
            // Make a Set of flow IDs we can delete from as we go:
            flowsRemaining = new Set(flowsToSort.map((f: any) => f.index)),
            // Calculate how tall the flow group is which will attach to this
            // node (may be less than n.dy):
            totalFlowSpan = overriddend3.sum(
              // Only count the space which is needed for visible flows (when
              // the node is real) OR for flows meeting a shadow node:
              flowsToSort.filter((f: any) => !f.isAShadow || n.isAShadow),
              (f: any) => f.dy
            ),
            // Attach flows to the *top* of the range, *except* when:
            // the entire node's value is not all flowing somewhere, AND
            // - The caller says to attach them to the bottom, OR
            // - The caller says to use the 'nearest' end AND
            //   - the center-of-all-attached-flows is below the node's
            //     own center.
            flowPosition =
              totalFlowValue < n.value &&
              (attachIncompletesTo === BOTTOM ||
                (attachIncompletesTo === NEAREST &&
                  divide(totalFlowWeight, totalFlowValue) > yCenter(n)))
                ? BOTTOM
                : TOP,
            // upper/lower bounds = the range where flows may attach
            bounds =
              flowPosition === TOP
                ? { upper: n.y, lower: n.y + totalFlowSpan }
                : { upper: yBottom(n) - totalFlowSpan, lower: yBottom(n) };
          // Reminder: In SVG-land, y-axis coordinates are inverted...
          //   "upper" & "lower" are meant visually here, not numerically.

          // placeFlow(f, y): Update a flow's position
          function placeFlow(f: any, newTopY: any) {
            // Is the flow actually in the queue? Exit if not. (This can happen
            // when we're placing a shadow flow and offer to update the original
            // flow's Y, but it's in some other stage.)
            if (!flowsRemaining.has(f.index)) {
              return;
            }
            // sy & ty (source/target y) are the vertical *offsets* at each end
            // of a flow, determining where below the node's top edge the flow's
            // top will meet.
            if (placing === TARGETS) {
              f.ty = newTopY - f.target.y;
            } else {
              f.sy = newTopY - f.source.y;
            }
            // Drop the flow we just placed from the queue:
            flowsRemaining.delete(f.index);
          }

          // placeFlowAt(edge, fIndex):
          //   Update the bound, set this flow's offset, update the queue.
          function placeFlowAt(edge: any, fIndex: any) {
            const f = flows[fIndex];
            let newY = 0;
            if (edge === TOP) {
              newY = bounds.upper;
              // If this is real, move the upper bound DOWN.
              if (f.useForVisiblePlacing || n.isAShadow) {
                bounds.upper += f.dy;
              }
            } else {
              // edge === BOTTOM
              // Make room at the bottom of the range for this flow:
              newY = bounds.lower - f.dy;
              // If this is real, move the lower bound UP to match:
              if (f.useForVisiblePlacing || n.isAShadow) {
                bounds.lower = newY;
              }
            }

            // Put the flow where we just decided & drop it from the queue:
            placeFlow(f, newY);

            if (f.useForVisiblePlacing && f.isAShadow) {
              // If this flow should be used for placing a real one AND is a
              // shadow flow, then copy its new position to the true flow & drop
              // that other flow from the queue too:
              placeFlow(flows[f.shadowOf], newY);
            }
          }

          // slopeData keys are the product of an 'edge' & a 'placing' value:
          const slopeData = {
            [TOP * TARGETS]: {
              f: (f: any) => (bounds.upper - sourceTop(f)) / f.dx,
              dir: -1,
            },
            [TOP * SOURCES]: {
              f: (f: any) => (targetTop(f) - bounds.upper) / f.dx,
              dir: 1,
            },
            [BOTTOM * TARGETS]: {
              f: (f: any) => (bounds.lower - sourceBottom(f)) / f.dx,
              dir: 1,
            },
            [BOTTOM * SOURCES]: {
              f: (f: any) => (targetBottom(f) - bounds.lower) / f.dx,
              dir: -1,
            },
          };

          // placeUnhappiestFlowAt(edge):
          //   Figure out which flow is worst off (slope-wise) and place it.
          //   edge = TOP or BOTTOM
          function placeUnhappiestFlowAt(edge: any) {
            // The queue may have been drained early. Guard against that:
            if (!flowsRemaining.size) {
              return;
            }
            const sKey = edge * placing,
              slopeOf = slopeData[sKey].f,
              // flowIndex = the ID of the unhappiest flow
              flowIndex = Array.from(flowsRemaining)
                // Exclude flows with shadows; they'll get their position
                // assigned when their shadow gets placed:
                .filter((i: any) => !flows[i].hasAShadow)
                .sort(
                  (a: any, b: any) =>
                    // For autolayout, use the right slopes in the correct order (asc/dsc):
                    (autoLayout
                      ? slopeData[sKey].dir * (slopeOf(flows[a]) - slopeOf(flows[b])) ||
                        // If there is a tie, sort by x-distance (ascending):
                        flows[a].dx - flows[b].dx
                      : 0) ||
                    // If we are using exact order (OR if there is still a tie),
                    // sort by sourceRow (which is also set for shadow flows)
                    flows[a].sourceRow - flows[b].sourceRow
                )[0];
            // If we found a flow, place it at the correct edge:
            if (flowIndex !== undefined) {
              placeFlowAt(edge, flowIndex);
            }
          }

          // Loop through the flow set, placing them from the outside in.
          // If there are at least 2 flows to be placed, we figure out which is
          // best suited to occupy the top & bottom edge spots.
          // After placing those, the remaining range is reduced & we repeat.
          while (flowsRemaining.size > 1) {
            // Place the least fortunate flows, then subtract their size from
            // the available range:
            placeUnhappiestFlowAt(TOP);
            if (autoLayout) {
              placeUnhappiestFlowAt(BOTTOM);
            }
            // (If using exact order, we want to place top->bottom, NOT alternate.)
          }

          // After that loop, we have 0-1 flows. If there is one, place it:
          flowsRemaining.forEach((i) => placeFlowAt(TOP, i));
        }

        // We have the utility functions defined now; time to actually use them.

        // First, update the x-distance (dx) values for all flows -- they may
        // have moved since their initial placement, due to drags. Two notes:
        // 1) We use the *absolute* value of the x-distance, so even when a node
        //    is dragged to the opposite side of a connected node, the ordering
        //    will remain stable.
        // 2) Denominator dx must not be 0, so MIN_VALUE is substituted if needed.
        flows.forEach((f: any) => {
          f.dx = Math.abs(f.target.x - f.source.x) || Number.MIN_VALUE;
        });

        // Gather all the distinct batches of flows we'll need to process (each
        // node may have 0-2 batches):
        const flowBatches = [
          ...nodeList
            .filter((n: any) => n.flows[IN].length)
            .map((n: any) => ({
              i: n.index,
              len: n.flows[IN].length,
              placing: TARGETS,
            })),
          ...nodeList
            .filter((n: any) => n.flows[OUT].length)
            .map((n: any) => ({
              i: n.index,
              len: n.flows[OUT].length,
              placing: SOURCES,
            })),
        ];

        // Sort the flow batches so that we start with those having the FEWEST
        // flows and work upward.
        // Reason: a 1-flow placement is certain; a 2-flow set is simple; etc.
        // By settling easier cases first, the harder cases end up with fewer
        // wild possibilities for how they may be arranged.
        flowBatches
          .sort((a, b) => a.len - b.len)
          // Finally: Go through every batch & sort its flows anew:
          .forEach((fBatch) => {
            sortFlows(nodes[fBatch.i], fBatch.placing);
          });
      }

      // assignNodesToStages: Iteratively assign the stage (x-group) for each node.
      // Nodes are assigned the maximum stage of their incoming neighbors + 1,
      // then any nodes which can be nudged forward are.
      function assignNodesToStages() {
        const nodesToCheckAgain = new Set();
        // updateNode: Set a node's stage & make sure its targets get another look.
        function updateNode(n: any) {
          n.stage = maxStage;
          n.flows[OUT].forEach((f: any) => {
            nodesToCheckAgain.add(f.target);
          });
        }

        // Work from left to right.
        // Assign every node to stage 0, then keep updating the stage of every node
        // that was a target of a known node. Repeat and fade.
        let nodesToPlace = nodes;
        // The maxStage check is to avoid an infinite loop when there is a cycle:
        while (nodesToPlace.length && maxStage < nodes.length - 1) {
          maxStage += 1;
          nodesToPlace.forEach((n: any) => updateNode(n));
          nodesToPlace = Array.from(nodesToCheckAgain);
          nodesToCheckAgain.clear();
        }

        // Pull any source nodes to the right which have room to move.
        // First, get a COPY of the list of all nodes with targets:
        nodes
          .filter((n: any) => n.flows[OUT].length)
          .slice()
          .sort((a: any, b: any) => b.stage - a.stage) // Sort that by stage, descending
          .forEach((n: any) => {
            // Find n's minimum target stage and use the one right before that:
            const maxNewStage = overriddend3.min(n.flows[OUT], (f: any) => f.target.stage) - 1;
            if (n.stage < maxNewStage) {
              n.stage = maxNewStage;
            }
          });

        // Handle layout checkboxes:
        function setStageWhenNoFlows(direction: any, newStage: any) {
          // For nodes with no flows going {direction}...
          nodes
            .filter((n: any) => !n.flows[direction].length)
            // ...set their stages to newStage:
            .forEach((n: any) => {
              n.stage = newStage;
            });
        }

        // Force origins to appear all the way to the left?
        if (leftJustifyOrigins) {
          setStageWhenNoFlows(IN, 0);
        }

        // Force endpoints all the way to the right?
        if (rightJustifyEndpoints) {
          setStageWhenNoFlows(OUT, maxStage);
        }

        // Now that the main nodes and flows are in place, we also fill in
        // SHADOW nodes & flows to occupy space whenever stages are skipped.
        // To get started, fill in the 'ds' (stage distance) for all flows:
        flows.forEach((f: any) => {
          f.ds = f.target.stage - f.source.stage;
        });

        // Next, operate on flows which cross more than one stage:
        const shadowNodeNames = new Map();
        flows
          .filter((f: any) => Math.abs(f.ds) > 1)
          .forEach((f: any) => {
            const nodesForThisFlow = [f.source];
            // Duplicate the source node as many times as needed (though only
            // as large as this individual flow)
            for (let i = 1; i < f.ds; i += 1) {
              const shadowStage = f.source.stage + i,
                // Create a custom name for the shadow which will still group
                // multiple flows between the same 2 places.
                newNodeName = `sh_${f.source.index}_${f.target.index}_s${shadowStage}`,
                fVal = Number(f.value);
              let shadowNode;
              // Have we already made a shadow node for this source/target?
              if (shadowNodeNames.has(newNodeName)) {
                // If so, let's add value to the node we've already made:
                shadowNode = nodes[shadowNodeNames.get(newNodeName)];
                shadowNode.value += fVal;
                shadowNode.total[IN] += fVal;
                shadowNode.total[OUT] += fVal;
              } else {
                // A shadow node doesn't exist, so we make a fresh one with the
                // same sourceRow as the original flow:
                shadowNode = {
                  index: nodes.length,
                  stage: shadowStage,
                  name: newNodeName,
                  sourceRow: f.sourceRow,
                  isAShadow: true,
                  flows: { [IN]: [], [OUT]: [] },
                  total: { [IN]: fVal, [OUT]: fVal },
                  value: fVal,
                };
                // Add this to the big list and to our shadow-tracking list:
                nodes.push(shadowNode);
                shadowNodeNames.set(newNodeName, shadowNode.index);
              }
              nodesForThisFlow.push(shadowNode);
            }
            nodesForThisFlow.push(f.target);

            // Now that we have a list of all nodes along the way, add shadow
            // flows between each pair (starting from the 2nd item in the list).
            for (let i = 1; i < nodesForThisFlow.length; i += 1) {
              const sourceNode = nodesForThisFlow[i - 1],
                targetNode = nodesForThisFlow[i],
                origSourceRow = Number(f.sourceRow),
                // Take values from the original flow, then override some:
                newFlow = {
                  ...f,
                  source: sourceNode,
                  target: targetNode,
                  index: flows.length,
                  shadowOf: f.index,
                  isAShadow: true,
                  hasAShadow: false,
                  // Make artificial sourceRow numbers so these get prioritized
                  // *with* the original flow:
                  sourceRow: origSourceRow + i / (f.ds + 1),
                  // Should we propagate this shadow's y position to the original
                  // flow? Only at the ends of the shadow path.
                  useForVisiblePlacing:
                    sourceNode.stage === f.source.stage || targetNode.stage === f.target.stage,
                };
              flows.push(newFlow);
              newFlow.source.flows[OUT].push(newFlow);
              newFlow.target.flows[IN].push(newFlow);
            }

            // Now that we're done adopting various values from original flow f,
            // tell f itself that Things have Changed:
            f.useForVisiblePlacing = false;
            f.hasAShadow = true;
          });
      }

      // Set up stagesArr: one array element for each stage, containing that
      // stage's nodes, in stage order.
      // This can also be called when nodes' info may have been updated elsewhere
      // & we need a fresh map generated.
      function updateStagesArray() {
        stagesArr = overriddend3
          .groups(nodes, (d: any) => d.stage) // [stage, [nodes]]
          .sort((a: any, b: any) => a[0] - b[0])
          // Extract each stage and sort its nodes by sourceRow.
          // (This raises shadow nodes to the same rank the original flow is at)
          .map((d: any) => d[1].sort(bySourceOrder)); // [[nodes]]
      }

      // placeNodes(iterations):
      //   Set (and then adjust) the y-position for each node and flow, based
      //   on their connections to other points in the diagram.
      function placeNodes(iterations: any) {
        // nodeSetStats(nodeList):
        //   Get the total weight+value from an assortment of Nodes.
        //   The Nodes are expected to all be in the same Stage.
        function nodeSetStats(nodeList: any) {
          const weight = overriddend3.sum(nodeList, (n: any) => yCenter(n) * n.value),
            value = valueSum(nodeList);
          return {
            stage: nodeList[0].stage,
            weight: weight,
            value: value,
            center: divide(weight, value),
          };
        }

        // Set up the scaling factor and the initial x & y of all the Nodes:
        function initializeNodePositions() {
          // First, calculate the spacing values.
          // How many nodes are in the 'busiest' stage?
          const greatestNodeCount = overriddend3.max(stagesArr, (s: any) => s.length);

          let ky = 0;
          // Special case: What if there's only one node in every stage?
          // That calculation is very different:
          if (greatestNodeCount === 1) {
            [maximumNodeSpacing, actualNodeSpacing] = [0, 0];
            ky =
              nodeHeightFactor *
              overriddend3.min(stagesArr, (s: any) => divide(size.h, valueSum(s)));
          } else {
            // What if each node in the busiest stage got 1 pixel?
            // Figure out how many pixels would be left over.
            // (If pixels < 2, use 2; otherwise the slider has nothing to do.)
            const allAvailablePadding = Math.max(2, size.h - greatestNodeCount);

            // A nodeHeightFactor of 0 means: 'pad as much as possible
            // without making any node less than 1 pixel tall'.
            // Formula for the initial spacing value when nHF = 0:
            //   allAvailablePadding / (# of spaces in the busiest stage)
            maximumNodeSpacing =
              ((1 - nodeHeightFactor) * allAvailablePadding) / (greatestNodeCount - 1);
            actualNodeSpacing = maximumNodeSpacing * nodeSpacingFactor;
            // Finally, calculate the vertical scaling factor for all
            // nodes, given maximumNodeSpacing & the diagram's height:
            ky = overriddend3.min(stagesArr, (s: any) =>
              divide(size.h - (s.length - 1) * maximumNodeSpacing, valueSum(s))
            );
          }
          if (ky === Infinity) {
            ky = 1;
          } // This happens if all Node values are 0

          // Compute all the dy & weighted values using the now-known scale
          // of the graph:
          flows.forEach((f: any) => {
            f.dy = f.value * ky;
            f.weightedValue = f.hasAShadow ? 0 : f.value;
          });
          // Also: Ensure each node has a nonzero height:
          nodes.forEach((n: any) => {
            n.dy = Math.max(n.value * ky, Number.MIN_VALUE);
          });

          // Set the initial positions of all nodes within each stage.
          // The initial stage will start with all nodes centered vertically,
          // separated by the actualNodeSpacing.
          // Each stage afterwards will center on its combined source nodes.
          let targetY;
          stagesArr.forEach((s: any, stageIndex: any) => {
            const stageSize = valueSum(s) * ky + actualNodeSpacing * (s.length - 1);
            targetY = size.h / 2; // default case = center this batch of nodes
            // If we have any flows into the current set of nodes, we have a
            // chicken/egg problem: We want to use weighted centers based on
            // flows (i.e. flowSetStats), but at this point 0 flows are placed.
            // Simpler approach: use the weighted center of nodes flowing in.
            const allFlowsIn = s.map((n: any) => n.flows[IN]).flat();
            if (allFlowsIn.length > 0) {
              const uniqueSourceNodes = new Set(
                allFlowsIn
                  .map((f: any) => f.source)
                  // Since shadows are in every stage, don't look back more than
                  // 1 stage. (And self-loops may mean there are flows from the
                  // *same* stage, currently.)
                  .filter((n: any) => n.stage >= stageIndex - 1)
              );
              targetY = nodeSetStats(Array.from(uniqueSourceNodes)).center;
            }

            // Calculate the first-node-in-this-stage's y position (while not
            // letting it be placed where the stage will exceed either boundary):
            let nextNodePos = Math.max(0, Math.min(targetY - stageSize / 2, size.h - stageSize));
            s.forEach((n: any) => {
              n.y = nextNodePos;
              // Find the y position of the next node:
              nextNodePos = yBottom(n) + actualNodeSpacing;
            });
          });

          // Set up x-values too.
          // Apply a scaling factor based on width per stage:
          const widthPerStage = maxStage > 0 ? (size.w - nodeWidth) / maxStage : 0;
          nodes.forEach((n: any) => {
            n.x = widthPerStage * n.stage;
            n.dx = nodeWidth;
          });

          // With nodes placed, we *also* have to provide an initial
          // placement for all flows, so that their weights can be measured
          // realistically in the placeNodes() routine.
          nodes.forEach((n: any) => {
            // Each flow is initially placed naively, just using the input order.
            // Any misfires will be corrected soon by placeFlowsInsideNodes()
            let [sy, ty] = [0, 0];
            // Shadows touching a real node adopt the same position as their
            // 'true' flow. (NOTE: This works because all shadows initially
            // *follow* all real flows.):
            n.flows[OUT].forEach((f: any) => {
              if (f.isAShadow && !n.isAShadow) {
                f.sy = flows[f.shadowOf].sy;
              } else {
                f.sy = sy;
                sy += f.dy;
              }
            });
            n.flows[IN].forEach((f: any) => {
              if (f.isAShadow && !n.isAShadow) {
                f.ty = flows[f.shadowOf].ty;
              } else {
                f.ty = ty;
                ty += f.dy;
              }
            });
          });
        }

        // findNodeGroupOffset(nodeList):
        //   Figure out where these Nodes want to be, and return the
        //   appropriate y-offset value.
        function findNodeGroupOffset(nodeList: any) {
          // The population of flows to test = the combination of every
          // last flow touching this group of Nodes:
          const fStats = allFlowStats(nodeList),
            totalIn = fStats[IN].value,
            totalOut = fStats[OUT].value;
          // If there are no flows touching *either* side here, there's nothing
          // to offset ourselves relative to, so we can exit early:
          if (totalIn === 0 && totalOut === 0) {
            return 0;
          }

          const nStats = nodeSetStats(nodeList),
            // projectedSourceCenter =
            //   the current Node group's weighted center
            //     MINUS the weighted center of incoming Flows' targets
            //     PLUS the weighted center of incoming Flows' sources.
            // Thought exercise:
            // If 100% of the value of the Node group is flowing in, then this is
            // exactly equivalent to: *the weighted center of all sources*.
            projectedSourceCenter = divide(
              nStats.weight - fStats[IN].targets.weight + fStats[IN].sources.weight,
              nStats.value
            ),
            // projectedTargetCenter = the same idea in the other direction:
            //   current Node group's weighted center
            //     - outgoing weights' center
            //     + final center of those weights
            projectedTargetCenter = divide(
              nStats.weight - fStats[OUT].sources.weight + fStats[OUT].targets.weight,
              nStats.value
            );

          // Time to do the positioning calculations.
          let goalY = 0;
          if (totalOut === 0) {
            // If we have only in-flows, it's simple:
            // Center the current group relative only to its sources.
            goalY = projectedSourceCenter;
          } else if (totalIn === 0) {
            // Only out-flows? Center this group on its targets:
            goalY = projectedTargetCenter;
          } else {
            // There are flows both in & out. Find the slope between the centers:
            const startStage = fStats[IN].sources.maxSourceStage,
              endStage = fStats[OUT].targets.minTargetStage,
              stageDistance = endStage - startStage,
              slopeBetweenCenters =
                stageDistance !== 0 // Avoid divide-by-0 error
                  ? (projectedTargetCenter - projectedSourceCenter) / stageDistance
                  : 0;
            // Where along that line should this current group be centered?
            goalY = projectedSourceCenter + (nStats.stage - startStage) * slopeBetweenCenters;
          }

          // We have a goal Y value! Return the offset from the current center:
          return goalY - nStats.center;
        }

        // updateStageCentering(stage):
        //   Make sure nodes are spaced far enough apart from each other,
        //   AND, after some have been nudged apart, put those
        //   now-locked-together groups of nodes in the best available
        //   position given their group's *overall* connections in & out.
        function updateStageCentering(s: any) {
          // enforceValidNodePositions():
          //   Make sure this stage doesn't extend past either the top or
          //   bottom, and preserve the required spacing between nodes.
          function enforceValidNodePositions() {
            // Nudge down any nodes which are past the top:
            let yPos = 0; // = the current available y closest to the top
            s.forEach((n: any) => {
              // If this node's top is above yPos, nudge the node down:
              if (n.y < yPos) {
                n.y = yPos;
              }
              // Set yPos to the next available y toward the bottom:
              yPos = yBottom(n) + actualNodeSpacing;
            });

            // ... if we've gone *past* the bottom, bump nodes back up.
            yPos = size.h; // = the current available y closest to the bottom
            s.slice()
              .reverse()
              .forEach((n: any) => {
                // if this node's bottom is below yPos, nudge it up:
                if (yBottom(n) > yPos) {
                  n.y = yPos - n.dy;
                }
                // Set yPos to the next available y toward the top:
                yPos = n.y - actualNodeSpacing;
              });
          }

          // nodesAreAdjacent: Given two nodes *in height order*, is the top of n2
          // bumping up against n1's bottom edge?
          function nodesAreAdjacent(n1: any, n2: any) {
            // Is the bottom of the 1st node + the node spacing essentially
            // the same as the 2nd node's top? (i.e. within a tenth of a 'pixel')
            return n2.y - actualNodeSpacing - yBottom(n1) < 0.1;
          }

          function centerNeighborGroups() {
            // First, Gather groups of neighbors. This loop produces arrays
            // of 1 or more nodes which need to be nudged together.
            const neighborGroups: any = [];
            s.forEach((n: any, i: any) => {
              // Can we include this node as a neighbor of its predecessor?
              if (i > 0 && nodesAreAdjacent(s[i - 1], n)) {
                // Yes? Then append it to the 'current' group:
                const lastGroup = neighborGroups.length - 1;
                neighborGroups[lastGroup].push(n);
              } else {
                // No? Start a new group:
                neighborGroups.push([n]);
              }
            });

            // At this point we *may* have node groups which need nudges.
            // For each multi-node group, find the weighted center of its
            // sources/targets, and place that group's center along that
            // line:
            neighborGroups
              .filter((g: any) => g.length > 1)
              .forEach((nodeGroup: any) => {
                // Apply the offset to the entire node group:
                const yOffset = findNodeGroupOffset(nodeGroup);
                nodeGroup.forEach((n: any) => {
                  n.y += yOffset;
                });
              });
          }

          // First, sort this stage's nodes based on either their current
          // positions or on the order they appeared in the data:
          s.sort(autoLayout ? byTopEdges : bySourceOrder);

          // Make sure any overlapping nodes preserve the required spacing.
          // Run the first nudge of all to see what bumps against each other:
          enforceValidNodePositions();

          // Look for sets of neighbors and center them as best we can:
          centerNeighborGroups();
          // Make sure we're still on the canvas:
          enforceValidNodePositions();

          // Since we may have just created more neighbors, iterate 1 more time:
          centerNeighborGroups();
          enforceValidNodePositions();
          // We could keep doing more rounds! But have to stop somewhere.
          // Someday I hope to update this to notice when we've either:
          // 1) stopped bumping into more nodes, or else
          // 2) reached the maximum group (all nodes in 1 neighbor group)
          // For now, this will do.
        }

        // processStages(stageList, factor):
        //   Iterate over a list of stages in the given order, moving Nodes
        //   and Flows around according to the given factor (which proceeds
        //   from 0.99 downwards as the iterations continue).
        function processStages(stageList: any, factor: any) {
          stageList.forEach((s: any) => {
            // Move each node to its ideal vertical position:
            s.forEach((n: any) => {
              n.y += findNodeGroupOffset([n]) * factor;
            });
            // Update this stage's node positions to incorporate their proximity
            // & required spacing *now*, since they'll be used as the basis for
            // weights in the very next stage:
            updateStageCentering(s);
            // Update the flow sorting too; same reason:
            placeFlowsInsideNodes(s);
          });
          // At the end of each round, do a proper final flow placement
          // across the whole diagram. (Some locally-optimized flow choices
          // don't work across the whole and need this resolution step
          // before doing more balancing).
          placeFlowsInsideNodes(nodes);
        }

        // reCenterDiagram:
        // If (the vertical size of the space occupied by the nodes)
        //  < (the total diagram's Height),
        // then offset ALL Nodes' y positions to center the diagram:
        function reCenterDiagram() {
          const minY = leastY(nodes),
            yH = greatestY(nodes) - minY;
          if (yH < size.h) {
            const yOffset = size.h / 2 - (minY + yH / 2);
            nodes.forEach((n: any) => {
              n.y += yOffset;
            });
          }
        }

        // Enough preamble. Lay out the nodes:

        initializeNodePositions();
        // Resolve all collisions/spacing & place all flows to start:
        stagesArr.forEach((s: any) => {
          updateStageCentering(s);
        });
        placeFlowsInsideNodes(nodes);

        let [alpha, counter] = [1, 0];
        while (counter < iterations) {
          counter += 1;
          // Make each round of moves progressively weaker:
          alpha *= 0.99;
          // Run through stages left-to-right, then right-to-left:
          processStages(stagesArr, alpha);
          processStages(stagesArr.slice().reverse(), alpha);
          reCenterDiagram();
        }

        // After the last layout adjustment, remember these node coordinates
        // (for reference when the user is dragging nodes):
        nodes.forEach((n: any) => {
          n.origPos = { x: n.x, y: n.y };
          n.lastPos = { x: n.x, y: n.y };
          n.move = [0, 0];
        });
      }

      // setup() = define the *skeleton* of the diagram -- which nodes link to
      // which, and in which stages -- but no specific positions yet:
      (sankey as any).setup = () => {
        connectFlowsToNodes();
        computeNodeValues();
        assignNodesToStages();
        updateStagesArray();
        return sankey;
      };

      // layout() = Given a complete skeleton, use the given total width/height and
      // set the exact positions of all nodes and flows:
      (sankey as any).layout = (iterations: any) => {
        // In case anything's changed since setup, re-generate our map:
        updateStagesArray();
        // Iterate over the structure several times to make the layout nice:
        placeNodes(iterations);
        return sankey;
      };

      // relayout() = Given a complete diagram with some new node positions,
      // calculate where the flows must now start/end:
      (sankey as any).relayout = () => {
        placeFlowsInsideNodes(nodes);
        return sankey;
      };

      return sankey;
    };

    /* ******************************************************************************************** */
    // sankeyDDLParser
    (function sankeyDDLParser(glob: any) {
      // 'glob' points to the global object, either 'window' (browser) or 'global' (node.js)
      // This lets us contain everything in an IIFE (Immediately-Invoked Function Expression)

      // We store the breakpoint which means 'never' here for easy reference.
      // When there are valid inputs, this is set to (stages count + 1).
      glob.labelNeverBreakpoint = 9999;

      /**
       * Update the range on the label-breakpoint slider
       * @param {number} newMax
       */
      glob.resetMaxBreakpoint = (newMax: any) => {
        glob.labelNeverBreakpoint = newMax;
      };

      // isNumeric: borrowed from jQuery/Angular
      function isNumeric(n: any) {
        return !Number.isNaN(n - parseFloat(n));
      }

      // clamp: Ensure a value n (if numeric) is between min and max.
      // Default to min if not numeric.
      function clamp(n: any, min: any, max: any) {
        return isNumeric(n) ? Math.min(Math.max(Number(n), min), max) : min;
      }

      // rememberedMoves: Used to track the user's repositioning of specific nodes
      // (which should be preserved across diagram renders).
      // Format is: nodeName => [moveX, moveY]
      glob.rememberedMoves = new Map();

      // resetMovesAndRender: Clear all manual moves of nodes AND re-render the
      // diagram:
      glob.resetMovesAndRender = () => {
        glob.rememberedMoves.clear();
        glob.process_sankey();
        return null;
      };

      // contrasting_gray_color:
      // Given any hex color, return a grayscale color which is lower-contrast than
      // pure black/white but still sufficient. (Used for less-important text.)
      function contrasting_gray_color(hc: any) {
        const c = overriddend3.rgb(hc),
          yiq = (c.r * 299 + c.g * 587 + c.b * 114) / 1000,
          // Calculate a value sufficiently far away from this color.
          // If it's bright-ish, make a dark gray; if dark-ish, make a light gray.
          // This algorithm is far from exact! But it seems good enough.
          // Lowest/highest values produced are 59 and 241.
          gray = Math.floor(yiq > 164 ? 0.75 * yiq - 64 : 0.3 * yiq + 192);
        return overriddend3.rgb(gray, gray, gray);
      }

      // escapeHTML: make any input string safe to display.
      // Used for displaying raw <SVG> code
      // and for reflecting the user's input back to them in messages.
      function escapeHTML(unsafeString: any) {
        return unsafeString
          .replaceAll("→", "&#8594;")
          .replaceAll("&", "&amp;")
          .replaceAll("<", "&lt;")
          .replaceAll(">", "&gt;")
          .replaceAll('"', "&quot;")
          .replaceAll("'", "&#039;")
          .replaceAll("\n", "<br />");
      }

      // ep = "Enough Precision". Converts long decimals to have just 5 digits.
      // Why?:
      // SVG diagrams produced by SankeyMATIC don't really benefit from specifying
      // values with more than 3 decimal places, but by default the output has *13*.
      // This is frankly hard to read and actually inflates the size of the SVG
      // output by quite a bit.
      //
      // Result: values like 216.7614485930364 become 216.76145 instead.
      // The 'Number .. toString' call allows shortened output: 8 instead of 8.00000
      function ep(x: any) {
        return Number(x.toFixed(5)).toString();
      }

      // updateMarks: given a US-formatted number string, replace with user's
      // preferred separators:
      function updateMarks(stringIn: any, numberMarks: any) {
        // If the digit-group mark is a comma, implicitly the decimal is a dot...
        // That's what we start with, so return with no changes:
        if (numberMarks.group === ",") {
          return stringIn;
        }

        // Perform hacky mark swap using ! as a placeholder:
        return stringIn
          .replaceAll(",", "!")
          .replaceAll(".", numberMarks.decimal)
          .replaceAll("!", numberMarks.group);
      }

      // formatUserData: produce a value in the user's designated format:
      function formatUserData(numberIn: any, nStyle: any) {
        const nString = updateMarks(
          overriddend3.format(`,.${nStyle.decimalPlaces}${nStyle.trimString}f`)(numberIn),
          nStyle.marks
        );
        return `${nStyle.prefix}${nString}${nStyle.suffix}`;
      }

      // initializeDiagram: Reset the SVG tag to have the chosen size &
      // background (with a pattern showing through if the user wants it to be
      // transparent):
      function initializeDiagram(cfg: any) {
        const svgEl = sankeySvgRef.current;
        if (!svgEl) return;
        svgEl.setAttribute("height", cfg.size_h);
        svgEl.setAttribute("width", cfg.size_w);
        svgEl.setAttribute(
          "class",
          `svg_background_${cfg.bg_transparent ? "transparent" : "default"}`
        );
        svgEl.textContent = ""; // Someday use replaceChildren() instead
      }

      // MARK SVG path specification functions

      // flatFlowPathMaker(f):
      // Returns an SVG path drawing a parallelogram between 2 nodes.
      // Used for the "d" attribute on a "path" element when curvature = 0 OR
      // when there is no curve to usefully draw (i.e. the flow is ~horizontal).
      function flatFlowPathMaker(f: any) {
        const sx = f.source.x + f.source.dx, // source's trailing edge
          tx = f.target.x, // target's leading edge
          syTop = f.source.y + f.sy, // source flow top
          tyBot = f.target.y + f.ty + f.dy; // target flow bottom

        f.renderAs = "flat"; // Render this path as a filled parallelogram

        // This SVG Path spec means:
        // [M]ove to the flow source's top; draw a [v]ertical line down,
        // a [L]ine to the opposite corner, a [v]ertical line up,
        // then [z] close.
        return `M${ep(sx)} ${ep(syTop)}v${ep(f.dy)}` + `L${ep(tx)} ${ep(tyBot)}v${ep(-f.dy)}z`;
      }

      // curvedFlowPathFunction(curvature):
      // Returns an SVG-path-producing /function/ based on the given curvature.
      // Used for the "d" attribute on a "path" element when curvature > 0.
      // Defers to flatFlowPathMaker() when the flow is basically horizontal.
      function curvedFlowPathFunction(curvature: any) {
        return (f: any) => {
          const syC = f.source.y + f.sy + f.dy / 2, // source flow's y center
            tyC = f.target.y + f.ty + f.dy / 2, // target flow's y center
            sEnd = f.source.x + f.source.dx, // source's trailing edge
            tStart = f.target.x; // target's leading edge

          // Watch out for a nearly-straight path (total rise/fall < 2 pixels OR
          // very little horizontal space to work with).
          // If we have one, make this flow a simple 4-sided shape instead of
          // a curve. (This avoids weird artifacts in some SVG renderers.)
          if (Math.abs(syC - tyC) < 2 || Math.abs(tStart - sEnd) < 12) {
            return flatFlowPathMaker(f);
          }

          f.renderAs = "curved"; // Render this path as a curved stroke

          // Make the curved path:
          // Set up a function for interpolating between the two x values:
          const xinterpolate = overriddend3.interpolateNumber(sEnd, tStart),
            // Pick 2 curve control points given the curvature & its converse:
            xcp1 = xinterpolate(curvature),
            xcp2 = xinterpolate(1 - curvature);
          // This SVG Path spec means:
          // [M]ove to the center of the flow's start [sx,syC]
          // Draw a Bezier [C]urve using control points [xcp1,syC] & [xcp2,tyC]
          // End at the center of the flow's target [tx,tyC]
          return (
            `M${ep(sEnd)} ${ep(syC)}C${ep(xcp1)} ${ep(syC)} ` +
            `${ep(xcp2)} ${ep(tyC)} ${ep(tStart)} ${ep(tyC)}`
          );
        };
      }

      // MARK Validation of Settings

      // settingIsValid(metadata, human value, size object {w: _, h: _}):
      // return [true, computer value] IF the given value meets the criteria.
      // Note: The 'size' object is only used when validating 'contained' settings.
      function settingIsValid(sData: any, hVal: any, cfg: any) {
        const [dataType, defaultVal, allowList] = sData;

        // Checkboxes: Translate y/n/Y/N/Yes/No to true/false.
        if (dataType === "yn" && reYesNo.test(hVal)) {
          return [true, reYes.test(hVal)];
        }

        if (["radio", "list"].includes(dataType) && allowList.includes(hVal)) {
          return [true, hVal];
        }

        if (dataType === "color") {
          let rgb;
          if (reRGBColor.test(hVal)) {
            rgb = overriddend3.rgb(hVal);
          } else if (reBareColor.test(hVal)) {
            rgb = overriddend3.rgb(`#${hVal}`);
          } else {
            // maybe it's a CSS name like blue/green/lime/maroon/etc.?
            const namedRGB = overriddend3.color(hVal);
            if (namedRGB) {
              rgb = namedRGB;
            }
          }
          // If we found a real color spec, return the full 6-char html value.
          // (This fixes the problem of a 3-character color like #789.)
          if (rgb) {
            return [true, rgb.formatHex()];
          }
        }

        // valueInBounds: Verify a numeric value is in a range.
        // 'max' can be undefined, which is treated as 'no maximum'
        function valueInBounds(v: any, [min, max]: any) {
          return v >= min && (max === undefined || v <= max);
        }

        if (dataType === "text") {
          // UN-double any single quotes:
          const unescapedVal = hVal.replaceAll("''", "'");
          // Make sure the string's length is in the right range:
          if (valueInBounds(unescapedVal.length, allowList)) {
            return [true, unescapedVal];
          }
        }

        // The only types remaining are numbers:
        const valAsNum = Number(hVal);
        if (dataType === "decimal" && reDecimal.test(hVal) && valueInBounds(valAsNum, [0, 1.0])) {
          return [true, valAsNum];
        }
        if (dataType === "integer" && reInteger.test(hVal) && valueInBounds(valAsNum, allowList)) {
          return [true, valAsNum];
        }
        if (dataType === "half" && reHalfNumber.test(hVal) && valueInBounds(valAsNum, allowList)) {
          return [true, valAsNum];
        }
        if (["whole", "contained", "breakpoint"].includes(dataType) && reWholeNumber.test(hVal)) {
          let [minV, maxV] = [0, 0];
          switch (dataType) {
            case "whole":
              [minV, maxV] = allowList;
              break;
            // Dynamic values (like margins) should be processed after the
            // diagram's size is set so that we can compare them to their
            // specific containing dimension (that's why they appear later
            // in the settings list):
            case "contained":
              maxV = cfg[allowList[1]];
              break;
            // breakpoints: We can't just use the current 'never' value
            // for comparison, since we may be importing a new diagram with
            // a different number of stages:
            case "breakpoint":
              maxV = defaultVal;
              break;
            // no default
          }
          if (valueInBounds(valAsNum, [minV, maxV])) {
            return [true, valAsNum];
          }
        }
        // If we could not affirmatively say this value is good:
        return [false];
      }

      // Take a human-friendly setting and make it JS-friendly:
      function settingHtoC(hVal: any, dataType: any) {
        switch (dataType) {
          case "whole":
          case "half":
          case "decimal":
          case "integer":
          case "contained":
          case "breakpoint":
            return Number(hVal);
          case "yn":
            return reYes.test(hVal);
          default:
            return hVal;
        }
      }

      // MARK Message Display
      // Show a value quoted & bolded & HTML-escaped:
      function highlightSafeValue(userV: any) {
        return `&quot;<strong>${escapeHTML(userV)}</strong>&quot;`;
      }

      // MARK Color Theme handling

      // colorThemes: The available color arrays to assign to Nodes.
      const colorThemes = new Map([
        [
          "a",
          {
            colorset: overriddend3.schemeCategory10,
            nickname: "Categories",
            d3Name: "Category10",
          },
        ],
        [
          "b",
          {
            colorset: overriddend3.schemeTableau10,
            nickname: "Tableau10",
            d3Name: "Tableau10",
          },
        ],
        [
          "c",
          {
            colorset: overriddend3.schemeDark2,
            nickname: "Dark",
            d3Name: "Dark2",
          },
        ],
        [
          "d",
          {
            colorset: overriddend3.schemeSet3,
            nickname: "Varied",
            d3Name: "Set3",
          },
        ],
      ]);

      function approvedColorTheme(themeKey: any) {
        // Give back an empty theme if the key isn't valid:
        return (
          colorThemes.get(themeKey.toLowerCase()) || {
            colorset: [],
            nickname: "Invalid Theme",
            d3Name: "?",
          }
        );
      }

      // rotateColors: Return a copy of a color array, rotated by the offset:
      function rotateColors(colors: any, offset: any) {
        const goodOffset = clamp(offset, 0, colors.length);
        return colors.slice(goodOffset).concat(colors.slice(0, goodOffset));
      }

      // We have to construct this fieldname in a few places:
      function offsetField(key: any) {
        return `themeoffset_${key}`;
      }

      // render_sankey: given nodes, flows, and other config, MAKE THE SVG DIAGRAM:
      function render_sankey(allNodes: any, allFlows: any, cfg: any, numberStyle: any) {
        // Set up functions and measurements we will need:

        // withUnits: Format a value with the current style.
        function withUnits(n: any) {
          return formatUserData(n, numberStyle);
        }

        // To measure text sizes, first we make a dummy SVG area the user won't
        // see, with the same size and font details as the real diagram:
        const scratchRoot = overriddend3
          .select("#svg_scratch")
          .attr("height", cfg.size_h)
          .attr("width", cfg.size_w)
          .attr("text-anchor", "middle")
          .attr("opacity", "0") // Keep all this invisible...
          .attr("font-family", cfg.labels_fontface)
          .attr("font-size", `${ep(cfg.labelname_size)}px`);
        scratchRoot.selectAll("*").remove(); // Clear out any past items

        /**
         * @typedef {(100|400|700)} fontWeight
         *
         * All the data needed to render a text span:
         * @typedef {Object} textFragment
         * @property {string} txt
         * @property {number} size - font size
         * @property {fontWeight} weight
         * @property {boolean} newLine - Should there be a line break
         *    preceding this item?
         */

        /**
         * Add <tspan> elements to an existing SVG <text> node.
         * Put line breaks of reasonable size between them if needed.
         *
         * ISSUE (rare, minor): If a later line has a larger font size which occurs
         *   *after* its first span, we don't catch that here. So the line spacing
         *   *can* look too small in that case.  However, spacing that according to
         *   the biggest size can also look awkward. Leaving this as-is for now.
         * @param {*} d3selection
         * @param {textFragment[]} textObjs
         * @param {number} origSize - the size of the text item we are appending to
         * @param {number} origX - the text item's original X coordinate
         */
        function addTSpans(d3selection: any, textObjs: any, origSize: any, origX: any) {
          let prevLineMaxSize = origSize;
          textObjs.forEach((tspan: any) => {
            // Each span may or may not want a line break before it:
            if (tspan.newLine) {
              // Set up a reasonable spacing given the prior line's maximum font size
              // compared to the new line's:
              const lineSpacing =
                (0.95 + cfg.labels_linespacing) * ((prevLineMaxSize + tspan.size * 3) / 4);
              d3selection
                .append("tspan")
                .attr("x", ep(origX))
                .attr("dy", ep(lineSpacing))
                .attr("font-weight", tspan.weight)
                .attr("font-size", `${ep(tspan.size)}px`)
                .text(tspan.txt);
              prevLineMaxSize = tspan.size; // reset to the new line's initial size
            } else {
              // No new line; just add the new piece in series:
              d3selection
                .append("tspan")
                .attr("font-weight", tspan.weight)
                .attr("font-size", `${ep(tspan.size)}px`)
                .text(tspan.txt);
              prevLineMaxSize = Math.max(prevLineMaxSize, tspan.size);
            }
          });
        }

        /**
         * @typedef {Object} SVGDimensions
         * @property {number} w - width
         * @property {number} h - height
         * @property {number} line1h - height of the entire first displayed line of text
         */

        /**
         * Set up and measure an SVG <text> element, placed at the hidden canvas'
         * midpoint. The text element may be assembled from multiple spans.
         * @param {textFragment[]} txtList
         * @param {string} id
         * @returns {SVGDimensions} dimensions - width, height, and line 1's height
         */
        function measureSVGText(txtList: any, id: any) {
          const firstEl = txtList[0],
            laterSpans = txtList.slice(1),
            firstNewLineIndex = laterSpans.findIndex((tspan: any) => tspan.newLine),
            line1Weight = firstEl.weight ?? cfg.labelname_weight;

          // A bit of complicated measuring to deal with here.
          // Note: Either list here may be empty!
          /** @type {textFragment[]} */
          let line1Suffixes = [],
            laterLines = [],
            /** @type {number} */
            line1Size = firstEl.size ?? cfg.labelname_size;
          if (firstNewLineIndex === -1) {
            // No newlines, only suffixes
            line1Suffixes = laterSpans;
          } else {
            // firstNewLineIndex >= 0
            line1Suffixes = laterSpans.slice(0, firstNewLineIndex);
            laterLines = laterSpans.slice(firstNewLineIndex);
          }

          // Set up the first element:
          const txtId = `bb_${id}`, // (bb for 'BoundingBox')
            [xC, yC] = [cfg.size_w / 2, cfg.size_h / 2], // centers
            textEl = scratchRoot
              .append("text")
              .attr("id", txtId)
              .attr("x", ep(xC))
              .attr("y", ep(yC))
              .attr("font-weight", line1Weight)
              .attr("font-size", `${ep(line1Size)}px`)
              .text(firstEl.txt);

          // Add any remaining line1 pieces so we can know line 1's real height:
          if (line1Suffixes.length) {
            addTSpans(textEl, line1Suffixes, line1Size, xC);
            // Update line1Size IF any suffixes were larger:
            line1Size = Math.max(line1Size, ...line1Suffixes.map((s: any) => s.size));
          }
          // Measure this height before we add more lines:
          const line1height = textEl?.node()?.getBBox().height;

          if (laterLines.length) {
            addTSpans(textEl, laterLines, line1Size, xC);
          }
          const totalBB = textEl?.node()?.getBBox(); // size after all pieces are added

          return {
            h: totalBB?.height,
            w: totalBB?.width,
            line1h: line1height,
          };
        }

        // setUpTextDimensions():
        //   Compute padding values for label highlights, etc.
        function setUpTextDimensions() {
          // isFirefox(): checks for Firefox-ness of the browser.
          // Why? Because we have to adjust SVG font spacing for Firefox's
          // sake.
          // It would be better if SVG-font-sizing differences were detectable
          // directly, but so far I haven't figured out how to test for just
          // that, so we check for Firefox. (Many use 'InstallTrigger' to
          // check for FF, but that's been deprecated.)
          function isFirefox() {
            return navigator && /firefox/i.test(navigator.userAgent || navigator.vendor || "");
          }

          // First, how big are an em and an ex in the current font, roughly?
          const emSize = measureSVGText([{ txt: "m" }], "em"),
            boundingBoxH = emSize.h, // (same for all characters)
            emW = emSize.w,
            // The WIDTH of an 'x' is a crude estimate of the x-HEIGHT, but
            // it's what we have for now:
            exH = measureSVGText([{ txt: "x" }], "ex").w,
            // Firefox has unique SVG measurements in 2022, so we look for it:
            browserKey = isFirefox() ? "firefox" : "*",
            metrics =
              (fontMetrics as any)[browserKey][cfg.labels_fontface] || fontMetrics[browserKey]["*"],
            m: any = {
              dy: metrics.dy * (boundingBoxH ?? 1),
              top: metrics.top * (exH ?? 1),
              bot: metrics.bot * (exH ?? 1),
              inner: metrics.inner * (emW ?? 1),
              outer: metrics.outer * (emW ?? 1),
              dyFactor: metrics.dy,
            };
          // Compute the remaining values (which depend on values above).
          // lblMarginAfter = total margin to give a label when it is after a node
          //   (Note: this value basically includes m.inner)
          // lblMarginBefore = total margin when label is before a node
          m.lblMarginAfter = cfg.node_border / 2 + metrics.marginRight * m.inner;
          m.lblMarginBefore =
            cfg.node_border / 2 + (metrics.marginRight + metrics.marginAdjLeft) * m.inner;
          return m;
        }

        const pad = setUpTextDimensions(),
          // Create the sankey object & the properties needed for the skeleton.
          // NOTE: The call to overriddend3.sankey().setup() will MODIFY the allNodes and
          // allFlows objects -- filling in specifics about connections, stages,
          // etc.
          sankeyObj = (overriddend3 as any)
            .sankey()
            .nodes(allNodes)
            .flows(allFlows)
            .rightJustifyEndpoints(cfg.layout_justifyends)
            .leftJustifyOrigins(cfg.layout_justifyorigins)
            .setup();

        // After the .setup() step, Nodes are divided up into Stages.
        // stagesArr = each Stage in the diagram (and the Nodes inside them)
        let stagesArr = sankeyObj.stages();
        // Update the label breakpoint controls based on the # of stages.
        // We need a value meaning 'never'; that's 1 past the (1-based) end of the
        // array, so: length + 1
        const newMax = stagesArr.length + 1,
          oldMax = glob.labelNeverBreakpoint;
        // Has the 'never' value changed?
        if (newMax !== oldMax) {
          // Update the slider's range with the new maximum:
          glob.resetMaxBreakpoint(newMax);
          // If the stage count has become lower than the breakpoint value, OR
          // if the stage count has increased but the old 'never' value was chosen,
          // we also need to adjust the slider's value to be the new 'never' value:
          if (cfg.labelposition_breakpoint > newMax || cfg.labelposition_breakpoint === oldMax) {
            cfg.labelposition_breakpoint = newMax;
          }
        }

        // MARK Shadow logic

        // shadowFilter(i): true/false value indicating whether to display an item.
        // Normally shadows are hidden, but the revealshadows flag can override.
        // i can be either a node or a flow.
        function shadowFilter(i: any) {
          return !i.isAShadow || cfg.internal_revealshadows;
        }

        if (cfg.internal_revealshadows) {
          // Add a usable tipname since they'll be used (i.e. avoid 'undefined'):
          allNodes
            .filter((n: any) => n.isAShadow)
            .forEach((n: any) => {
              n.tipname = "(shadow)";
            });
        }
        // MARK Label-measuring time
        // Depending on where labels are meant to be placed, we measure their
        // sizes and calculate how much room has to be reserved for them (and
        // subtracted from the graph area):

        /**
         * Given a Node, list all the label pieces we'll need to display.
         * Also, scale their sizes according to the user's instructions.
         * @param {object} n - Node we are making the label for
         * @param {number} magnification - amount to scale this entire label
         * @returns {textFragment[]} List of text items
         */
        function getLabelPieces(n: any, magnification: any) {
          const overallSize = cfg.labelname_size * magnification,
            // The relative-size values 50 to 150 become -.5 to .5:
            relativeSizeAdjustment = (cfg.labels_relativesize - 100) / 100,
            nameSize = overallSize * (1 - relativeSizeAdjustment),
            valueSize = overallSize * (1 + relativeSizeAdjustment),
            nameParts = String(n.name).split("\\n"), // Use \n for multiline labels
            nameObjs = nameParts.map((part, i) => ({
              txt: part,
              weight: cfg.labelname_weight,
              size: nameSize,
              newLine: i > 0 || (cfg.labelvalue_appears && cfg.labelvalue_position === "above"),
            })),
            valObj = {
              txt: withUnits(n.value),
              weight: cfg.labelvalue_weight,
              size: valueSize,
              newLine: cfg.labelname_appears && cfg.labelvalue_position === "below",
            };
          if (!cfg.labelvalue_appears) {
            return nameObjs;
          }
          if (!cfg.labelname_appears) {
            return [valObj];
          }
          switch (cfg.labelvalue_position) {
            case "before": // separate the value from the name with 1 space
              valObj.txt += " "; // FALLS THROUGH to 'above'
            case "above":
              return [valObj, ...nameObjs];
            case "after": // Add a colon just before the value
              nameObjs[nameObjs.length - 1].txt += ": "; // FALLS THROUGH
            default:
              return [...nameObjs, valObj]; // 'below'
          }
        }

        /**
         * @typedef {('start'|'middle'|'end')} SVGAnchorString
         */

        /**
         * Derives the SVG anchor string for a label based on the diagram's
         * labelposition_scheme (which can be 'per_stage' or 'auto').
         * @param {object} n - a Node object.
         * @returns {SVGAnchorString}
         */
        function labelAnchor(n: any) {
          if (cfg.labelposition_scheme === "per_stage") {
            const bp = cfg.labelposition_breakpoint - 1,
              anchorAtEnd = cfg.labelposition_first === "before" ? n.stage < bp : n.stage >= bp;
            return anchorAtEnd ? "end" : "start";
          }
          // Scheme = 'auto' here. Put the label on the empty side if there is one.
          // We check the *count* of flows in/out, because their sum might be 0:
          if (!n.flows[IN].length) {
            return "end";
          }
          if (!n.flows[OUT].length) {
            return "start";
          }
          switch (cfg.labelposition_autoalign) {
            case -1:
              return "end";
            case 1:
              return "start";
            default:
              return "middle";
          }
        }

        // Make a function to easily find a value's place in the overall range of
        // Node sizes:
        const [minVal, maxVal]: any = overriddend3.extent(allNodes, (n: any) => n.value),
          nodeScaleFn = // returns a Number from 0 to 1:
            (v: any) => (minVal === maxVal ? 1 : (v - minVal) / (maxVal - minVal));

        // Set up label information for each Node:
        if (cfg.labelname_appears || cfg.labelvalue_appears) {
          allNodes
            .filter(shadowFilter)
            .filter((n: any) => !n.hideLabel)
            .forEach((n: any) => {
              const totalRange = (Math.abs(cfg.labels_magnify - 100) * 2) / 100,
                nFactor = nodeScaleFn(n.value),
                nAbsolutePos = cfg.labels_magnify >= 100 ? nFactor : 1 - nFactor,
                // Locate this value in the overall range of sizes, then
                // scoot that range to be centered on 0:
                nodePositionInRange = nAbsolutePos * totalRange - totalRange / 2,
                magnifyLabel = cfg.labels_magnify === 100 ? 1 : 1 + nodePositionInRange,
                id = `label${n.index}`; // label0, label1..
              n.labelList = getLabelPieces(n, magnifyLabel);
              n.label = {
                dom_id: id,
                anchor: labelAnchor(n),
                bb: measureSVGText(n.labelList, id),
              };
            });
        }

        // maxLabelWidth(stageArr, labelsBefore):
        //   Compute the total space required by the widest label in a stage
        function maxLabelWidth(stageArr: any, labelsBefore: any) {
          let maxWidth = 0;
          stageArr
            .filter((n: any) => n.labelList?.length)
            .forEach((n: any) => {
              const labelTotalW =
                n.label.bb.w +
                (labelsBefore ? pad.lblMarginBefore : pad.lblMarginAfter) +
                pad.outer;
              maxWidth = Math.max(maxWidth, labelTotalW);
            });
          return maxWidth;
        }

        // setUpDiagramSize(): Compute the final size of the graph
        function setUpDiagramSize() {
          // Calculate the actual room we have to draw in...
          // Start from the user's declared canvas size + margins:
          const graphW = cfg.size_w - cfg.margin_l - cfg.margin_r,
            graphH = cfg.size_h - cfg.margin_t - cfg.margin_b,
            lastStage = stagesArr.length - 1,
            labelsBeforeFirst = stagesArr[0].filter((n: any) => n.label?.anchor === "end"),
            labelsAfterLast = stagesArr[lastStage].filter((n: any) => n.label?.anchor === "start"),
            // If any labels are BEFORE stage 0, get its maxLabelWidth:
            leadingW =
              labelsBeforeFirst.length > 0
                ? maxLabelWidth(stagesArr[0], true)
                : cfg.node_border / 2,
            // If any labels are AFTER the last stage, get its maxLabelWidth:
            trailingW =
              labelsAfterLast.length > 0
                ? maxLabelWidth(stagesArr[lastStage], false)
                : cfg.node_border / 2,
            // Compute the ideal width to fit everything successfully:
            idealW = graphW - leadingW - trailingW,
            // Find the smallest width we will allow -- all the Node widths
            // plus (5px + node_border) for every Flow region:
            minimumW = stagesArr.length * cfg.node_w + lastStage * (cfg.node_border + 5),
            // Pick which width we will actually use:
            finalW = Math.max(idealW, minimumW),
            // Is any part of the diagram going to be cut off?
            // If so, we have to decide how to distribute the bad news.
            //
            // This derives the proportion of any potential cut-off area
            // which shall be attributed to the leading side:
            leadingShareOfError =
              leadingW + trailingW > 0 ? leadingW / (leadingW + trailingW) : 0.5,
            // The actual amount of error (if any) for the leading side:
            leadingCutOffAdjustment =
              idealW < minimumW ? (idealW - minimumW) * leadingShareOfError : 0;
          return {
            w: finalW,
            h: graphH,
            final_margin_l: cfg.margin_l + leadingW + leadingCutOffAdjustment,
          };
        }

        const graph = setUpDiagramSize();

        // Ready for final layout!
        // We have the skeleton set up; add the remaining dimension values.
        // (Note: This call further ALTERS allNodes & allFlows with their
        // specific coordinates.)
        sankeyObj
          .size({ w: graph.w, h: graph.h })
          .nodeWidth(cfg.node_w)
          .nodeHeightFactor(cfg.node_h / 100)
          .nodeSpacingFactor(cfg.node_spacing / 100)
          .autoLayout(cfg.layout_order === "automatic")
          .attachIncompletesTo(cfg.layout_attachincompletesto)
          .layout(cfg.internal_iterations); // Note: The 'layout()' step must be LAST

        // We *update* the final stages array here, because in theory it may
        // have been changed. The final array will be used for some layout
        // questions (like where labels will land inside the diagram, or for
        // the 'outside-in' flow color style):
        stagesArr = sankeyObj.stages();

        // Now that the stages & values are known, we can finish preparing the
        // Node & Flow objects for the SVG-rendering routine.
        const userColorArray =
            cfg.node_theme === "none"
              ? [cfg.node_color] // (User wants just one color)
              : rotateColors(
                  approvedColorTheme(cfg.node_theme).colorset,
                  cfg[offsetField(cfg.node_theme)]
                ),
          colorScaleFn = overriddend3.scaleOrdinal(userColorArray),
          // Drawing curves with curvature of <= 0.1 looks bad and produces visual
          // artifacts, so let's just take the lowest value on the slider (0.1)
          // and use that value to mean 0/flat:
          flowsAreFlat = cfg.flow_curvature <= 0.1,
          // flowPathFn is a function producing an SVG path; the same function is
          // used for all Flows. (Flat flows use a simpler function.)
          flowPathFn = flowsAreFlat
            ? flatFlowPathMaker
            : curvedFlowPathFunction(cfg.flow_curvature),
          // Is the diagram background dark or light?
          darkBg = cfg.bg_color.toUpperCase() < "#888",
          // Is the label color more like black or like white?
          darkLabel = cfg.labels_color.toUpperCase() < "#AAA",
          // Set up label highlight values:
          hlStyle: any = highlightStyles[darkLabel ? "dark" : "light"];
        hlStyle.orig.fill_opacity = Number(cfg.labels_highlight);
        // Given the user's opacity, calculate a reasonable hover
        // value (2/3 of the distance to 1):
        hlStyle.hover.fill_opacity = 0.666 + Number(cfg.labels_highlight) / 3;

        // stagesMidpoint: Helpful value for deciding if something is in the first
        // or last half of the diagram:
        function stagesMidpoint() {
          return (stagesArr.length - 1) / 2;
        }

        // Fill in presentation values for each Node (so the render routine
        // doesn't have to do any thinking):
        allNodes.filter(shadowFilter).forEach((n: any) => {
          n.dom_id = `r${n.index}`; // r0, r1... ('r' = '<rect>')
          // Everything with this class value will move with the Node when it is
          // dragged:
          n.css_class = `for_${n.dom_id}`; // for_r0, for_r1...
          n.tooltip = `${n.tipname}:\n${withUnits(n.value)}`;
          n.opacity = n.opacity || cfg.node_opacity;

          // Fill in any missing Node colors. (Flows may inherit from these.)
          if (typeof n.color === "undefined" || n.color === "") {
            // Use the first non-blank portion of a label as the basis for
            // adopting an already-used color or picking a new one.
            // (Note: this is case sensitive!)
            // If there are no non-blank strings in the node name, substitute
            // a word-ish value (rather than crash):
            const colorKeyString = (n.tipname?.match(/^\s*(\S+)/) || [null, "name-is-blank"])[1];
            // Don't use up colors on shadow nodes:
            n.color = n.isAShadow ? colorGray60 : colorScaleFn(colorKeyString);
          }
          // Now that we're guaranteed a color, we can calculate a border shade:
          n.border_color = darkBg
            ? overriddend3.rgb(n.color).brighter(2)
            : overriddend3.rgb(n.color).darker(2);

          // Set up label presentation values:
          if (n.labelList?.length && !n.hideLabel) {
            // Which side of the node will the label be on?
            switch (n.label.anchor) {
              case "start":
                n.label.x = n.x + n.dx + pad.lblMarginAfter;
                break;
              case "end":
                n.label.x = n.x - pad.lblMarginBefore;
                break;
              default:
                n.label.x = n.x + n.dx / 2;
            }
            n.label.y = n.y + n.dy / 2; // This is the vcenter of the node
            // To set the text element's baseline, we have to work with the height
            // of the first text line in the label:
            n.label.dy = pad.dyFactor * n.label.bb.line1h - (n.label.bb.h - n.label.bb.line1h) / 2;

            // Will there be any highlights? If not, n.label.bg will be null:
            if (hlStyle.orig.fill_opacity > 0) {
              n.label.bg = {
                dom_id: `${n.label.dom_id}_bg`, // label0_bg, label1_bg..
                offset: {
                  x: n.label.anchor === "end" ? -pad.outer : -pad.inner,
                  y: -pad.top,
                  w: pad.inner + pad.outer,
                  h: pad.top + pad.bot,
                },
                ...hlStyle.orig,
              };
            }
          }
        });

        // ...and fill in more Flow details as well:
        allFlows.filter(shadowFilter).forEach((f: any) => {
          f.dom_id = `flow${f.index}`; // flow0, flow1...
          f.tooltip = `${f.source.tipname} → ${f.target.tipname}: ${withUnits(f.value)}`;
          // Fill in any missing opacity values and the 'hover' counterparts:
          f.opacity = f.opacity || cfg.flow_opacity;
          // Hover opacity = halfway between the user's opacity and 1.0:
          f.opacity_on_hover = 0.5 + Number(f.opacity) / 2;

          // Derive any missing Flow colors.
          if (f.color === "") {
            // Stroke Color priority order:
            // 0. If it's a shadow, just color it gray.
            // 1. color given directly to the flow (filtered out above)
            // 2. inheritance-from-node-with-specific-paint-direction
            // 3. default-inheritance-direction OR default flow color
            if (f.isAShadow) {
              f.color = colorGray60;
            } else if (f.source.paint[AFTER]) {
              f.color = f.source.color;
            } else if (f.target.paint[BEFORE]) {
              f.color = f.target.color;
            } else {
              const flowMidpoint = (f.source.stage + f.target.stage) / 2;
              switch (cfg.flow_inheritfrom) {
                case "source":
                  f.color = f.source.color;
                  break;
                case "target":
                  f.color = f.target.color;
                  break;
                case "outside-in":
                  // Is the flow's midpoint in the right half, or left?
                  // (In the exact middle, we use the source color.)
                  f.color = flowMidpoint <= stagesMidpoint() ? f.source.color : f.target.color;
                  break;
                case "none":
                  f.color = cfg.flow_color;
                // no default
              }
            }
          }
          // Set up alternative values to enable the current flow to be
          // rendered as either flat or curved:
          // When a flow is FLAT:
          //  * It's really a parallelogram, so it needs a 'fill' value.
          //  * We still add a stroke because very angled flows can look too
          //  thin otherwise. (They still can, even with the stroke.)
          // When a flow is CURVED:
          //  * No fill; only stroke-width!
          //  * stroke-width is set to at least 1px so tiny flows can be seen.
          f.fill = { flat: f.color, curved: "none" };
          f.stroke_width = { flat: 0.5, curved: Math.max(1, f.dy) };
        });

        // At this point, allNodes and allFlows are ready to go. Draw!

        // Clear out any old contents & update the size and class:
        initializeDiagram(cfg);

        // Select the svg canvas:
        const diagramRoot = overriddend3.select("#sankey_svg");

        // If a background color is defined, add a backing rectangle with that color:
        if (!cfg.bg_transparent) {
          // Note: This just adds the rectangle *without* changing the overriddend3
          // selection stored in diagramRoot:
          diagramRoot
            .append("rect")
            .attr("height", cfg.size_h)
            .attr("width", cfg.size_w)
            .attr("fill", cfg.bg_color);
        }

        // Add a [g]roup translating the remaining elements 'inward' by the margins:
        const diagMain = diagramRoot
          .append("g")
          .attr("transform", `translate(${ep(graph.final_margin_l)},${ep(cfg.margin_t)})`);

        // MARK Functions for Flow hover effects
        // applyFlowEffects(flow, opacity, styles):
        //   Update a flow & its related labels based on the hover state:
        function applyFlowEffects(f: any, o: any, s: any) {
          // Use overall 'opacity' because f might use either a fill or stroke:
          overriddend3.select(`#${f.dom_id}`).attr("opacity", o);
          [f.source, f.target]
            .filter((n) => n.label?.bg)
            .forEach((n) => {
              overriddend3
                .select(`#${n.label.bg.dom_id}`)
                .attr("fill", s.fill)
                .attr("fill-opacity", ep(s.fill_opacity))
                .attr("stroke", s.stroke)
                .attr("stroke-width", ep(s.stroke_width))
                .attr("stroke-opacity", ep(s.stroke_opacity));
            });
        }

        // Hovering over a flow increases its opacity & highlights the labels of
        // the source+target:
        function turnOnFlowHoverEffects(_: any, f: any) {
          f.hovering = true;
          applyFlowEffects(f, f.opacity_on_hover, hlStyle.hover);
        }

        // Leaving a flow restores its original appearance:
        function turnOffFlowHoverEffects(_: any, f: any) {
          applyFlowEffects(f, f.opacity, hlStyle.orig);
          // don't clear the flag until the job is done:
          f.hovering = false;
        }

        // Set up the [g]roup of rendered flows:
        // diagFlows = the overriddend3 selection of all flow paths:
        const diagFlows = diagMain
          .append("g")
          .attr("id", "sankey_flows")
          .selectAll()
          .data(allFlows.filter(shadowFilter))
          .enter()
          .append("path")
          .attr("id", (f: any) => f.dom_id)
          .attr("d", flowPathFn) // set the SVG path for each flow
          .attr("fill", (f: any) => f.fill[f.renderAs])
          .attr("stroke-width", (f: any) => ep(f.stroke_width[f.renderAs]))
          .attr("stroke", (f: any) => f.color)
          .attr("opacity", (f: any) => f.opacity)
          // add emphasis-on-hover behavior:
          .on("mouseover", turnOnFlowHoverEffects)
          .on("mouseout", turnOffFlowHoverEffects)
          // Sort flows to be rendered:
          // Shadows first (i.e. at the back), then largest-to-smallest
          // (so if flows cross, the smaller ones are drawn on top):
          .sort((a: any, b: any) => b.isAShadow - a.isAShadow || b.dy - a.dy);

        // Add a tooltip for each flow:
        diagFlows.append("title").text((f: any) => f.tooltip);

        // MARK Drag functions for Nodes

        // isAZeroMove: simple test of whether every offset is 0 (no move at all):
        function isAZeroMove(a: any) {
          return a.every((m: any) => m === 0);
        }

        // Given a Node index, apply its move to the SVG & remember it for later:
        function applyNodeMove(index: any) {
          const n = allNodes[index],
            // In the case of a reversed graph, we negate the x-move:
            myXMove = n.move[0] * (cfg.layout_reversegraph ? -1 : 1),
            availableW = graph.w - n.dx,
            availableH = graph.h - n.dy;

          // Apply the move to the node (halting at the edges of the graph):
          n.x = Math.max(0, Math.min(availableW, n.origPos.x + availableW * myXMove));
          n.y = Math.max(0, Math.min(availableH, n.origPos.y + availableH * n.move[1]));

          // Find everything which shares the class of the dragged Node and
          // translate all of them with these offsets.
          // Currently this means the Node and the label+highlight, if present.
          // (Why would we apply a null transform? Because it may have been
          // transformed already & we are now undoing the previous operation.)
          overriddend3
            .selectAll(`#sankey_svg .${n.css_class}`)
            .attr(
              "transform",
              isAZeroMove(n.move)
                ? null
                : `translate(${ep(n.x - n.origPos.x)},${ep(n.y - n.origPos.y)})`
            );
        }

        // Set the new starting point of any constrained move:
        function updateLastNodePosition(n: any) {
          n.lastPos = { x: n.x, y: n.y };
        }

        // rememberNodeMove: Save a move so it can be re-applied.
        // The value saved is the % of the available size that the node was moved,
        // not the literal pixel move. This helps when the user is changing
        // spacing or diagram size.
        function rememberNodeMove(n: any) {
          // Always update lastPos when remembering moves:
          updateLastNodePosition(n);
          if (isAZeroMove(n.move)) {
            // There's no actual move now. If one was stored, forget it:
            glob.rememberedMoves.delete(n.name);
          } else {
            // We save moves keyed to their NAME (not their index), so they
            // can be remembered even when the inputs change their order.
            //
            // In the case of a move already remembered, this will replace the
            // original moves with an identical copy...seems less trouble than
            // checking first.
            glob.rememberedMoves.set(n.name, n.move);
          }
        }

        // After one or more Node moves are done, call this:
        function reLayoutDiagram() {
          // Recalculate all flow positions given new node position(s):
          sankeyObj.relayout();

          // For every flow, update its 'd' path attribute with the new
          // calculated path.
          diagFlows
            .attr("d", flowPathFn)
            // (This may *also* change how the flow must be rendered,
            // so derive those attributes again:)
            .attr("fill", (f: any) => f.fill[f.renderAs])
            .attr("stroke-width", (f: any) => ep(f.stroke_width[f.renderAs]));
        }

        // Show helpful guides/content for the current drag. We put it all in a
        // distinct 'g'roup for helper content so we can remove it easily later:
        function dragNodeStarted(event: any, n: any) {
          const grayColor = contrasting_gray_color(cfg.bg_color);
          let diagHelperLayer = diagMain.select("#helper_layer");
          // Create the helper layer if it doesn't exist:
          if (!diagHelperLayer.nodes.length) {
            // Insert it just before (i.e. 'under') the 'nodes' layer, so it
            // doesn't interfere with things like double-clicks on nodes.
            (diagHelperLayer as any) = diagMain
              .insert("g", "#sankey_nodes")
              .attr("id", "helper_layer")
              // Set up attributes common to all the stuff inside here..
              .attr("fill", grayColor as any)
              .attr("fill-opacity", 0.5)
              .attr("stroke", "none");
          }

          // Draw 4 horizontal/vertical guide lines, along the edges of the
          // place where the drag began (d.lastPos):
          diagHelperLayer
            .append("path")
            .attr("id", "helper_lines")
            // This SVG Path spec means:
            // [M]ove to the left edge of the graph at this node's top
            // [h]orizontal line across the whole graph width
            // [m]ove down by this node's height
            // [H]orizontal line back to the left edge (x=0)
            // ..Then the same operation [v]ertically, using this node's width.
            .attr(
              "d",
              `M0 ${ep(n.lastPos.y)} h${ep(graph.w)} m0 ${ep(n.dy)} H0` +
                `M${ep(n.lastPos.x)} 0 v${ep(graph.h)} m${ep(n.dx)} 0 V0`
            )
            .attr("stroke", grayColor as any)
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", "1 3")
            .attr("stroke-opacity", 0.7);

          // Put a ghost rectangle where this node started out:
          diagHelperLayer
            .append("rect")
            .attr("id", "helper_original_rect")
            .attr("x", ep(n.origPos.x))
            .attr("y", ep(n.origPos.y))
            .attr("height", ep(n.dy))
            .attr("width", ep(n.dx))
            .attr("fill", n.color)
            .attr("fill-opacity", 0.3);

          // Check for the Shift key. If it's down when starting the drag, skip
          // the hint:
          if (!(event.sourceEvent && event.sourceEvent.shiftKey)) {
            // Place hint text where it can hopefully be seen,
            // in a [g]roup which can be removed later during dragging:
            const shiftHints = diagHelperLayer
                .append("g")
                .attr("id", "helper_shift_hints")
                .attr("font-size", "14px")
                .attr("font-weight", "400"),
              hintHeights = graph.h > 350 ? [0.05, 0.95] : [0.4];
            // Show the text so it's visible but not overwhelming:
            hintHeights.forEach((h) => {
              shiftHints
                .append("text")
                .attr("text-anchor", "middle")
                .attr("x", graph.w / 2)
                .attr("y", graph.h * h)
                .text("Hold down Shift to move in only one direction");
            });
          }
          return null;
        }

        // This is called _during_ Node drags:
        function draggingNode(event: any, n: any) {
          // Fun fact: In this context, event.subject is the same thing as 'd'.
          let myX = event.x,
            myY = event.y;
          const graphIsReversed = false;

          // Check for the Shift key:
          if (event.sourceEvent && event.sourceEvent.shiftKey) {
            // Shift is pressed, so this is a constrained drag.
            // Figure out which direction the user has dragged _further_ in:
            if (Math.abs(myX - n.lastPos.x) > Math.abs(myY - n.lastPos.y)) {
              myY = n.lastPos.y; // Use X move; keep Y constant
            } else {
              myX = n.lastPos.x; // Use Y move; keep X constant
            }
            // If they've Shift-dragged, they don't need the hint any more -
            // remove it and don't bring it back until the next gesture.
            const shiftHint = diagMain.select("#helper_shift_hints");
            if ((shiftHint as any).nodes) {
              shiftHint.remove();
            }
          }

          // Calculate the percentages we want to save (which will stay
          // independent of the graph's edge constraints, even if the spacing,
          // etc. changes to distort them):
          n.move = [
            // If the graph is RTL, calculate the x-move as though it is LTR:
            (graphIsReversed ? -1 : 1) * ((myX - n.origPos.x) / (graph.w - n.dx)),
            graph.h === n.dy ? 0 : (myY - n.origPos.y) / (graph.h - n.dy),
          ];

          applyNodeMove(n.index);
          // Note: We DON'T rememberNodeMove after every pixel-move of a drag;
          // just when a gesture is finished.
          reLayoutDiagram();
          return null;
        }

        // (Investigate: This is called on every ordinary *click* as well; look
        // into skipping this work if no actual move has happened.)
        function dragNodeEnded(event: any, n: any) {
          // Take away the helper guides:
          const helperLayer = diagMain.select("#helper_layer");
          if ((helperLayer as any).nodes) {
            helperLayer.remove();
          }

          // After a drag is finished, any new constrained drag should use the
          // _new_ position as 'home'. Therefore we have to set this as the
          // 'last' position:
          rememberNodeMove(n);

          // Sometimes the pointer has ALSO been over a flow, which means
          // that any flow & its labels could be highlighted in the produced
          // SVG and PNG - which is not what we want.
          // Therefore, at the end of any drag, turn *off* any lingering
          // hover-effects before we render the PNG+SVG:
          allFlows
            .filter((f: any) => f.hovering)
            .forEach((f: any) => {
              turnOffFlowHoverEffects(null, f);
            });

          reLayoutDiagram();
          return null;
        }

        // A double-click resets a node to its default rendered position:
        function doubleClickNode(event: any, n: any) {
          n.move = [0, 0];
          applyNodeMove(n.index);
          rememberNodeMove(n);
          reLayoutDiagram();
          return null;
        }

        // Set up the <g>roup of Nodes, including drag behavior:
        const diagNodes = diagMain
          .append("g")
          .attr("id", "sankey_nodes")
          .selectAll(".node")
          .data(allNodes.filter(shadowFilter))
          .enter()
          .append("g")
          .attr("class", "node")
          .call(
            (overriddend3 as any)
              .drag()
              .on("start", dragNodeStarted)
              .on("drag", draggingNode)
              .on("end", dragNodeEnded)
          )
          .on("dblclick", doubleClickNode);

        // Set up Node borders, if specified:
        if (cfg.node_border) {
          diagNodes
            .append("rect")
            .attr("id", (n: any) => `${n.dom_id}_border`)
            .attr("class", (n: any) => n.css_class)
            .attr("x", (n: any) => ep(n.x))
            .attr("y", (n: any) => ep(n.y))
            .attr("height", (n: any) => ep(n.dy))
            .attr("width", (n: any) => ep(n.dx))
            .attr("stroke", (n: any) => n.border_color)
            .attr("stroke-width", cfg.node_border)
            .attr("fill", "none");
        }

        // Construct the main <rect>angles for NODEs:
        diagNodes
          .append("rect")
          // Give a unique ID & class to each rect that we can reference:
          .attr("id", (n: any) => n.dom_id)
          .attr("class", (n: any) => n.css_class)
          .attr("x", (n: any) => ep(n.x))
          .attr("y", (n: any) => ep(n.y))
          .attr("height", (n: any) => ep(n.dy))
          .attr("width", (n: any) => ep(n.dx))
          // we made sure above there will be a color defined:
          .attr("fill", (n: any) => n.color)
          .attr("fill-opacity", (n: any) => n.opacity)
          // Add tooltips showing node totals:
          .append("title")
          .text((n: any) => n.tooltip);

        // Create a top layer for labels & highlights, so nodes can't block them:
        const diagLabels = diagMain
          .append("g")
          .attr("id", "sankey_labels")
          // These font spec defaults apply to all labels within
          .attr("font-family", cfg.labels_fontface)
          .attr("font-size", `${ep(cfg.labelname_size)}px`)
          .attr("fill", cfg.labels_color);

        if (!cfg.labels_hide && (cfg.labelname_appears || cfg.labelvalue_appears)) {
          // Add labels in a distinct layer on the top (so nodes can't block them)
          diagLabels
            .selectAll()
            .data(allNodes.filter(shadowFilter))
            .enter()
            .filter((n: any) => !n.hideLabel)
            .append("text")
            .attr("id", (n: any) => n.label.dom_id)
            // Associate this label with its Node using the CSS class:
            .attr("class", (n: any) => n.css_class)
            .attr("text-anchor", (n: any) => n.label.anchor)
            .attr("x", (n: any) => ep(n.label.x))
            .attr("y", (n: any) => ep(n.label.y))
            .attr("font-weight", (n: any) => n.labelList[0].weight)
            .attr("font-size", (n: any) => `${ep(n.labelList[0].size)}px`)
            // Nudge the text to be vertically centered:
            .attr("dy", (n: any) => ep(n.label.dy))
            .text((n: any) => n.labelList[0].txt)
            .filter((n: any) => n.labelList.length > 1)
            .each(function handleSpans(n: any) {
              addTSpans(
                // @ts-expect-error Parameter 'name' implicitly has an 'any' type.ts(7006)
                overriddend3.select(this),
                n.labelList.slice(1),
                n.labelList[0].size,
                n.label.x
              );
            });

          // For any nodes with a label highlight defined, render it:
          allNodes
            .filter(shadowFilter)
            .filter((n: any) => n.label?.bg)
            .forEach((n: any) => {
              // Use each label's size to make custom round-rects underneath:
              const labelTextSelector = `#${n.label.dom_id}`,
                labelBB = (diagLabels as any).select(labelTextSelector).node().getBBox(),
                bg = n.label.bg;
              // Put the highlight rectangle just before each text:
              diagLabels
                .insert("rect", labelTextSelector)
                .attr("id", bg.dom_id)
                // Attach a class to make a drag operation affect a Node's label too:
                .attr("class", n.css_class)
                .attr("x", ep(labelBB.x + bg.offset.x))
                .attr("y", ep(labelBB.y + bg.offset.y))
                .attr("width", ep(labelBB.width + bg.offset.w))
                .attr("height", ep(labelBB.height + bg.offset.h))
                .attr("rx", ep(cfg.labelname_size / 4))
                .attr("fill", bg.fill)
                .attr("fill-opacity", ep(bg.fill_opacity))
                .attr("stroke", bg.stroke)
                .attr("stroke-width", ep(bg.stroke_width))
                .attr("stroke-opacity", ep(bg.stroke_opacity));
            });
        }

        // Now that all of the SVG nodes and labels exist, it's time to re-apply
        // any remembered moves:
        if (glob.rememberedMoves.size) {
          // Make a copy of the list of moved-Node names (so we can destroy it):
          const movedNodes = new Set(glob.rememberedMoves.keys());

          // Look for all node objects matching a name in the list:
          allNodes
            .filter(shadowFilter)
            .filter((n: any) => movedNodes.has(n.name))
            .forEach((n: any) => {
              n.move = glob.rememberedMoves.get(n.name);
              // Make this move visible in the diagram:
              applyNodeMove(n.index);
              updateLastNodePosition(n);
              // DON'T 'rememberNodeMove' here - if we do, then the last
              // manual move will be unintentionally modified when only the
              // spacing was changed, for example.

              // Delete this moved node's name from the Set:
              movedNodes.delete(n.name);
            });
          // Any remaining items in movedNodes must refer to Nodes which are no
          // longer with us. Delete those from the global memory:
          movedNodes.forEach((nodeName) => {
            glob.rememberedMoves.delete(nodeName);
          });

          // Re-layout the diagram once, after all of the above moves:
          reLayoutDiagram();
        }
      }
      glob.render_sankey = render_sankey;
      // end of render_sankey

      // MAIN FUNCTION:
      // process_sankey: Called directly from the page and within this script.
      // Gather inputs from user; validate them; render updated diagram
      const process_sankey = () => {
        let [maxDecimalPlaces, maxNodeIndex, maxNodeVal] = [0, 0, 0];
        const uniqueNodes = new Map();

        // Update the display of all known themes given their offsets:
        function updateColorThemeDisplay() {
          // template string for the color swatches:
          const makeSpanTag = (color: any, count: any, themeName: any) =>
            `<span style="background-color: ${color};" ` +
            `class="color_sample_${count}" ` +
            `title="${color} from overriddend3 color scheme ${themeName}">` +
            "&nbsp;</span>";
          for (const t of (colorThemes as any).keys()) {
            const theme = approvedColorTheme(t),
              themeOffset = 0, // 0/9
              colorset = rotateColors(theme.colorset, themeOffset),
              // Show the array rotated properly given the offset:
              renderedGuide = colorset
                .map((c: any) => makeSpanTag(c, colorset.length, theme.d3Name))
                .join("");
          }
        }

        // NODE-handling functions:

        /**
         * Parse the node name to find out if it is in strike-through format
         * (e.g. '-hidden label-').
         * @param {string} rawName a node name from the input data
         * @returns {object} nameInfo
         * @returns {string} nameInfo.trueName The real node name (without dashes)
         * @returns {boolean} nameInfo.hideLabel True if the name was struck through
         */
        function parseNodeName(rawName: any) {
          const hiddenNameMatches = rawName.match(/^-(.*)-$/),
            hideThisLabel = hiddenNameMatches !== null,
            trueName = hideThisLabel ? hiddenNameMatches[1] : rawName;
          return { trueName: trueName, hideLabel: hideThisLabel };
        }

        /**
         * Make sure a node's name is present in the main list, with the lowest row
         * number the node has appeared on.
         * @param {string} nodeName A raw node name from the input data
         * @param {number} row The number of the input row the node appeared on.
         *  (This can be a non-integer; Target node names have 0.5 added to their
         *  row number.)
         * @returns {object} The node's object (from uniqueNodes)
         */
        function setUpNode(nodeName: any, row: any) {
          const { trueName, hideLabel } = parseNodeName(nodeName),
            thisNode = uniqueNodes.get(trueName); // Does this node exist?
          if (thisNode) {
            // If so, should the new row # replace the stored row #?:
            if (thisNode.sourceRow > row) {
              thisNode.sourceRow = row;
            }
            // Update hideLabel if this instance of the name was struck through:
            thisNode.hideLabel ||= hideLabel;
            return thisNode;
          }
          // This is a new Node. Set up its object, keyed to its trueName:
          const newNode = {
            name: trueName,
            tipname: trueName.replaceAll("\\n", " "),
            hideLabel: hideLabel,
            sourceRow: row,
            paintInputs: [],
            unknowns: { [IN]: new Set(), [OUT]: new Set() },
          };
          uniqueNodes.set(trueName, newNode);
          return newNode;
        }

        // updateNodeAttrs: Update an existing node's attributes.
        // Note: If there are multiple lines specifying a value for the same
        // parameter for a node, the LAST declaration will win.
        function updateNodeAttrs(nodeParams: any) {
          // Just in case this is the first appearance of the name (or we've
          // encountered an earlier row than the node declaration), add it to
          // the big list:
          const thisNode = setUpNode(nodeParams.name, nodeParams.sourceRow);

          // We've already used the 'sourceRow' value and don't want it to
          // overwrite anything, so take it out of the params object:
          delete nodeParams.sourceRow;

          // If there's a color and it's a color CODE, put back the #:
          // TODO: honor or translate color names?
          if (reBareColor.test(nodeParams.color)) {
            nodeParams.color = `#${nodeParams.color}`;
          }

          // Don't overwrite the 'name' value here, it can mess up tooltips:
          delete nodeParams.name;

          Object.entries(nodeParams).forEach(([pName, pVal]) => {
            if (typeof pVal !== "undefined" && pVal !== null && pVal !== "") {
              thisNode[pName] = pVal;
            }
          });
        }

        // Go through lots of validation with plenty of bailout points and
        // informative messages for the poor soul trying to do this.

        // Note: Checking the 'Transparent' background-color box *no longer* means
        // that the background-color-picker is pointless; it still affects the color
        // value which will be given to "Made with SankeyMATIC".
        // Therefore, we no longer disable the Background Color element, even when
        // 'Transparent' is checked.

        // Time to parse the user's input.
        // Before we do anything at all, split it into an array of lines with
        // no whitespace at either end.
        // As part of this step, we make sure to drop any zero-width spaces
        // which may have been appended or prepended to lines (e.g. when pasted
        // from PowerPoint), then trim again.

        const origSourceLines = inputDDL.split("\n"),
          sourceLines = origSourceLines.map((l) =>
            l
              .trim()
              .replace(/^\u200B+/, "")
              .replace(/\u200B+$/, "")
              .trim()
          ),
          invalidLines: any = [], // contains objects with a 'value' and 'message'
          linesWithSettings = new Set(),
          linesWithValidSettings = new Set();

        function warnAbout(line: any, warnMsg: any) {
          invalidLines.push({ value: line, message: warnMsg });
        }

        // Search for Settings we can apply:
        let currentSettingGroup = "";
        sourceLines.forEach((lineIn, row) => {
          // Is it a Move line?
          const moveParts = lineIn.match(reMoveLine);
          if (moveParts !== null) {
            linesWithSettings.add(row);
            // Save this as a rememberedMove.
            // We don't verify the name because we don't yet know the list to
            // match against. Assume the node names are provided in good faith.
            const [nodeName, moveX, moveY] = moveParts.slice(-3);
            glob.rememberedMoves.set(nodeName, [Number(moveX), Number(moveY)]);
            linesWithValidSettings.add(row);
            return;
          }

          // Does it look like a regular Settings line (number, keyword, color)
          // OR a Settings line with a quoted string?
          const settingParts = lineIn.match(reSettingsValue) ?? lineIn.match(reSettingsText);

          // If either was found, let's process it:
          if (settingParts !== null) {
            // We found something, so remember this row index:
            linesWithSettings.add(row);

            // Derive the setting name we're looking at:
            let origSettingName = settingParts[1],
              settingName = origSettingName.replace(/\s+/g, "_");

            // Syntactic sugar - if the user typed the long version of a word,
            // fix it up so it's just the 1st letter so it will work:
            "width height left right top bottom" // => w, h, l, r, t, b
              .split(" ")
              .filter((l) => settingName.endsWith(l))
              .forEach((long) => {
                settingName = settingName.replace(long, long[0]);
              });

            // If the given settingName still isn't valid, and it isn't already
            // two words, try it with the prefix from the prior settings row:
            if (
              !skmSettings.has(settingName) &&
              !/_/.test(settingName) &&
              currentSettingGroup.length
            ) {
              settingName = `${currentSettingGroup}_${settingName}`;
              origSettingName = `${currentSettingGroup} ${origSettingName}`;
            }

            // Update the group-prefix, whether or not the value validates
            // below. (Better to honor this prefix than to use one from
            // further up.):
            currentSettingGroup = settingName.split("_")[0];

            const settingData = skmSettings.get(settingName);
            // Validate & apply:
            if (settingData) {
              const settingValue = settingParts[2],
                dataType = settingData[0],
                sizeObj = dataType === "contained" ? { w: 100, h: 100 } : {},
                [validValue, finalValue] = settingIsValid(settingData, settingValue, sizeObj);
              if (validValue) {
                linesWithValidSettings.add(row);
                return;
              }
              // The setting exists but the value wasn't right:
              warnAbout(settingValue, `Invalid value for <strong>${origSettingName}<strong>`);
            } else {
              // There wasn't a setting matching this name:
              warnAbout(origSettingName, "Not a valid setting name");
            }
          }
        });

        //  Parse inputs into: approvedNodes, approvedFlows
        const goodFlows: any = [],
          approvedNodes: any = [],
          approvedFlows: any = [],
          SYM_USE_REMAINDER = "*",
          SYM_FILL_MISSING = "?",
          reFlowLine = new RegExp(
            "^(?<sourceNode>.+)" +
              `\\[(?<amount>[\\d\\s.+-]+|\\${SYM_USE_REMAINDER}|\\${SYM_FILL_MISSING}|)\\]` +
              "(?<targetNodePlus>.+)$"
          );

        /**
         * @param {string} fv A flow's value.
         * @returns {boolean} True if the value is a special calculation symbol
         */
        function flowIsCalculated(fv: any) {
          return [SYM_USE_REMAINDER, SYM_FILL_MISSING].includes(fv);
        }

        // Loop through all the non-setting input lines:
        sourceLines
          .filter((l, i) => !linesWithSettings.has(i))
          .forEach((lineIn, row) => {
            // Is it a blank line OR a comment? Skip it entirely:
            if (lineIn === "" || reCommentLine.test(lineIn)) {
              return;
            }

            // Does this line look like a Node?
            let matches = lineIn.match(reNodeLine);
            if (matches !== null) {
              // Save/update it in the uniqueNodes structure:
              updateNodeAttrs({
                name: matches[1].trim(),
                color: matches[2],
                opacity: matches[3],
                paintInputs: [matches[4], matches[5]],
                sourceRow: row,
              });
              // No need to process this as a Data line, let's move on:
              return;
            }

            // Does this line look like a Flow?
            matches = lineIn.match(reFlowLine);
            if (matches !== null) {
              const amountIn = matches[2].replace(/\s/g, ""),
                isCalculated = flowIsCalculated(amountIn);

              // Is the Amount actually blank? Treat that like a comment (but log it):
              if (amountIn === "") {
                console.error(
                  `<span class="info_text">Skipped empty flow:</span> ${escapeHTML(lineIn)}`
                );
                return;
              }

              // Is Amount a number or a special operation?
              // Reject the line if it's neither:
              if (!isNumeric(amountIn) && !isCalculated) {
                warnAbout(
                  lineIn,
                  `The [Amount] must be a number in the form #.# or a wildcard ("${SYM_USE_REMAINDER}" or "${SYM_FILL_MISSING}").`
                );
                return;
              }
              // Diagrams don't currently support negative numbers:
              if (Number(amountIn) < 0) {
                warnAbout(lineIn, "Amounts must not be negative");
                return;
              }

              // All seems well, save it as good:
              goodFlows.push({
                source: matches[1].trim(),
                target: matches[3].trim(),
                amount: amountIn,
                sourceRow: row,
                // Remember any special symbol even after the amount will be known:
                operation: isCalculated ? amountIn : null,
              });

              // We need to know the maximum precision of the inputs (greatest
              // # of characters to the RIGHT of the decimal) for some error
              // checking operations (& display) later:
              maxDecimalPlaces = Math.max(maxDecimalPlaces, (amountIn.split(".")[1] || "").length);
              return;
            }

            // This is a non-blank line which did not match any pattern:
            warnAbout(lineIn, "Does not match the format of a Flow or Node or Setting");
          });

        // TODO: Disable useless precision checkbox if maxDecimalPlaces === 0
        // TODO: Look for cycles and post errors about them

        // Mention any un-parseable lines:
        invalidLines.forEach((parsingError: any) => {
          console.error(
            `${parsingError.message}: ${highlightSafeValue(parsingError.value)}`,
            "issue"
          );
        });

        // Make the final list of Flows, linked to their Node objects:
        const graphIsReversed = false;
        goodFlows.forEach((flow: any) => {
          const thisFlow = {
              hovering: false,
              index: approvedFlows.length,
              sourceRow: flow.sourceRow,
              operation: flow.operation,
              value: flow.amount,
              color: "", // may be overwritten below
              opacity: "", // ""
            },
            // Try to parse any extra info that isn't actually the target's name.
            // The format of the Target string can be: "Name [#color[.opacity]]"
            //   e.g. 'x [...] y #99aa00' or 'x [...] y #99aa00.25'
            // Look for a candidate string starting with # for color info:
            flowTargetPlus = flow.target.match(reFlowTargetWithSuffix);
          if (flowTargetPlus !== null) {
            // IFF the # string matches a stricter pattern, separate the target
            // string into parts:
            const [, possibleNodeName, possibleColor] = flowTargetPlus,
              colorOpacity = possibleColor.match(reColorPlusOpacity);
            if (colorOpacity !== null) {
              // Looks like we found a color or opacity or both.
              // Update the target's name with the trimmed string:
              flow.target = possibleNodeName;
              // If there was a color, adopt it:
              if (colorOpacity[1]) {
                thisFlow.color = `#${colorOpacity[1]}`;
              }
              // If there was an opacity, adopt it:
              if (colorOpacity[2]) {
                thisFlow.opacity = colorOpacity[2];
              }
            }
            // Otherwise we will treat it as part of the nodename, e.g. "Team #1"
          }

          // Make sure the node names get saved; it may be their only appearance:
          (thisFlow as any).source = setUpNode(flow.source, flow.sourceRow);
          (thisFlow as any).target = setUpNode(flow.target, flow.sourceRow + 0.5);

          if (graphIsReversed) {
            [(thisFlow as any).source, (thisFlow as any).target] = [
              (thisFlow as any).target,
              (thisFlow as any).source,
            ];
            // Calculations must also flow in the opposite direction:
            if (thisFlow.operation) {
              thisFlow.operation =
                thisFlow.operation === SYM_USE_REMAINDER ? SYM_FILL_MISSING : SYM_USE_REMAINDER;
            }
          }

          approvedFlows.push(thisFlow);
        });

        // Calculate any dependent amounts

        // Set up constants we will need:
        // SYM_USE_REMAINDER = Adopt any remainder from this flow's SOURCE
        // SYM_FILL_MISSING = Adopt any unused amount from this flow's TARGET
        const outOfSource = { node: "source", dir: OUT },
          intoTarget = { node: "target", dir: IN },
          calculationKeys = {
            [SYM_USE_REMAINDER]: {
              leaving: outOfSource,
              arriving: intoTarget,
            },
            [SYM_FILL_MISSING]: {
              leaving: intoTarget,
              arriving: outOfSource,
            },
          },
          // Make a handy set containing all calculating flows:
          queueOfFlows = new Set(approvedFlows.filter((flow: any) => flow.operation)),
          // Track each Node touched by a calculated flow:
          involvedNodes = new Set();
        // Now, store in each Node references to each unknown Flow touching it.
        // Later we'll use the counts of unkonwns.
        queueOfFlows.forEach((f: any) => {
          const k = (calculationKeys as any)[f.operation];
          // Add references to the unknowns to their related Nodes.
          f[k.leaving.node].unknowns[k.leaving.dir].add(f);
          involvedNodes.add(f[k.leaving.node].name);
          f[k.arriving.node].unknowns[k.arriving.dir].add(f);
          involvedNodes.add(f[k.arriving.node].name);
        });

        if (queueOfFlows.size) {
          // For each involvedNode: is it an endpoint or origin?
          // (Terminal nodes have an implicit additional unknown side.)
          // We'd rather check with n.flows[].length, but that's not set up yet.
          approvedFlows.forEach((f: any) => {
            // Initialize the struct if it's not present. Begin with both = true.
            f.source.terminates ??= { [IN]: true, [OUT]: true };
            f.target.terminates ??= { [IN]: true, [OUT]: true };
            // Update relevant values to false if they aren't already:
            f.source.terminates[OUT] &&= !involvedNodes.has(f.source.name);
            f.target.terminates[IN] &&= !involvedNodes.has(f.target.name);
          });
        }

        // Make a place to keep the unknown count for each calculated flow's parent.
        // (It is cleared & re-built each time through the loop.)
        const parentUnknowns = new Map();

        function resolveEligibleFlow(ef: any) {
          const k = (calculationKeys as any)[ef.operation],
            parentN = ef[k.leaving.node],
            unknownCt = Math.trunc(parentUnknowns.get(ef)), // strip any .5s
            unknownMsg =
              unknownCt > 1 ? ` (&lsquo;${parentN.tipname}&rsquo; had ${unknownCt} unknowns)` : "";
          // Find any flows which touch the 'parent' (i.e. data source).
          // We check af.value here, *not* .operation. If a calculation has been
          //   completed, we want to know that resulting amount.
          // (Note: We won't re-process flow 'ef' in this inner loop --
          //   the 'flowIsCalculated' filter excludes its unresolved .value)
          let [parentTotal, siblingTotal] = [0, 0];
          approvedFlows
            .filter(
              (af: any) =>
                !flowIsCalculated(af.value) &&
                [af[k.arriving.node].name, af[k.leaving.node].name].includes(parentN.name)
            )
            .forEach((af: any) => {
              if (parentN.name === af[k.arriving.node].name) {
                // Add up amounts arriving at the parent from the other side:
                parentTotal += Number(af.value);
              } else {
                // Add up sibling amounts (flows leaving the parent on our side):
                siblingTotal += Number(af.value);
              }
            });
          // Update this flow with the calculated amount (preventing negatives):
          ef.value = Math.max(0, parentTotal - siblingTotal);
          // Remove this flow from the 'unknowns' lists & from the queue:
          ef[k.leaving.node].unknowns[k.leaving.dir].delete(ef);
          ef[k.arriving.node].unknowns[k.arriving.dir].delete(ef);
          queueOfFlows.delete(ef);
        }

        /**
         * Test whether a flow's parent has only 1 unknown value left.
         * @param {object} flow - the specific flow to test
         * @returns true when the unknown count for the flow's parent is exactly 1
         */
        function has_one_unknown(flow: any) {
          return parentUnknowns.get(flow) === 1;
        }

        // Now, resolve the flows in order from most certain to least certain:
        while (queueOfFlows.size) {
          // First, (re)calculate every flow's count of unknowns on its parent:
          parentUnknowns.clear();
          queueOfFlows.forEach((f: any) => {
            const k = (calculationKeys as any)[f.operation],
              parentN = f[k.leaving.node];
            // If an unknown flow connects to a terminating node, it should be ranked
            // lower. All internal singletons should solidify first.
            // After we have resolved all other singletons, only then should we
            // resolve flows with terminating nodes before proceeding to the
            // indeterminate flows. To achieve this, we add 0.5 to a flow's
            // parentUnknowns value when either end terminates.
            f.terminalAdj ??= // Note: this only needs to be derived once.
              parentN.terminates[k.arriving.dir] || f[k.arriving.node].terminates[k.leaving.dir]
                ? 0.5
                : 0;
            parentUnknowns.set(
              f,
              parentN.unknowns[IN].size + parentN.unknowns[OUT].size + f.terminalAdj
            );
          });
          // Helpful for debugging - Array.from(parentUnknowns).sort((a, b) => a[1] - b[1])
          //   .forEach((x) => console.log(`${x[0].source.tipname} ${x[0].operation}`
          //     + ` ${x[0].target.tipname}: ${x[1]}`));
          // console.log('');

          // Next, prioritize the flows by their count of unknowns (ascending),
          // then by sourceRow (ascending):
          const sortedFlows = Array.from(queueOfFlows.values()).sort(
            (a: any, b: any) =>
              parentUnknowns.get(a) - parentUnknowns.get(b) || a.sourceRow - b.sourceRow
          );

          // Are there _any_ flows with a single unknown? (If so, they'll be
          // first in line.)
          if (has_one_unknown(sortedFlows[0])) {
            // We have /at least/ one. Resolve all the singletons we can!
            sortedFlows.filter((f) => has_one_unknown(f)).forEach((f) => resolveEligibleFlow(f));
          } else {
            // Here we had _no_ singletons.
            // Resolve ONE ambiguous flow, then loop again to look for any
            // fresh singletons which resulted.
            // But first: note we're in Ambiguous Territory (if we haven't already)
            console.error(
              "warnAboutAmbiguousFlows",
              "<em>Note: Beyond this point, some flow amounts depended on multiple unknown values.<br>" +
                "They will be resolved in the order of fewest unknowns + their order in the input data.</em>"
            );
            // (We do that first because the very next console msg will mention unknowns)
            resolveEligibleFlow(sortedFlows[0]);
          }
        }
        // Done calculating!

        // Construct the final list of approved_nodes, sorted by their order of
        // appearance in the source:
        Array.from(uniqueNodes.values())
          .sort((a, b) => a.sourceRow - b.sourceRow)
          .forEach((n: any) => {
            // Set up color inheritance signals from '<<' and '>>' indicators:
            const paintL = n.paintInputs.some((s: any) => s === "<<"),
              paintR = n.paintInputs.some((s: any) => s === ">>");
            // If the graph is reversed, swap the directions:
            n.paint = {
              [BEFORE]: graphIsReversed ? paintR : paintL,
              [AFTER]: graphIsReversed ? paintL : paintR,
            };
            // After establishing the above, the raw paint inputs aren't needed:
            delete n.paintInputs;
            n.index = approvedNodes.length;

            approvedNodes.push(n);
          });

        // MARK Import settings from the page's UI:

        let approvedCfg = {};

        skmSettings.forEach((fldData, fldName) => {
          const [dataType, defaultVal] = fldData;
          const typedVal = settingHtoC(defaultVal, dataType);
          (approvedCfg as any)[fldName] = typedVal;
        });

        approvedCfg = { ...approvedCfg, ...customSetting };

        // Since we know the canvas' intended size now, go ahead & set that up
        // (before we potentially quit):
        if (!chartRef?.current) return;
        const chartEl = chartRef.current;
        chartEl.style.height = `${(approvedCfg as any).size_h}px`;
        chartEl.style.width = `${(approvedCfg as any).size_w}px`;

        // Mark as 'applied' any setting line which was successful.
        // (This will put the interactive UI component in charge.)
        // Un-commenting a settings line will apply it again (and then immediately
        // comment it again).
        // Use origSourceLines so that any original indentation is preserved:
        const updatedSourceLines = origSourceLines.map((l, i) =>
          linesWithValidSettings.has(i) ? `${settingsAppliedPrefix}${l}` : l
        );

        // Were there any good flows at all? If not, offer a little help and then
        // EXIT EARLY:
        if (!goodFlows.length) {
          // TODO: throw error
          // Clear the contents of the graph in case there was an old graph left
          // over:
          initializeDiagram(approvedCfg);
          updateColorThemeDisplay();
          return null;
        }

        // MARK Diagram does have data, so prepare to render.

        // Set up the numberStyle object:
        const [groupMark, decimalMark] = (approvedCfg as any).value_format,
          numberStyle = {
            marks: {
              group: groupMark === "X" ? "" : groupMark,
              decimal: decimalMark,
            },
            decimalPlaces: maxDecimalPlaces,
            // 'trimString' = string to be used in the overriddend3.format expression later:
            trimString: (approvedCfg as any).labelvalue_fullprecision ? "" : "~",
            prefix: (approvedCfg as any).value_prefix,
            suffix: (approvedCfg as any).value_suffix,
          };

        // All is ready. Do the actual rendering:
        render_sankey(approvedNodes, approvedFlows, approvedCfg, numberStyle);

        // All done. Give control back to the browser:
        return null;
      };
      glob.process_sankey = process_sankey;

      // Render the present inputs:
      // glob.process_sankey();
      process_sankey();
    })(typeof window === "undefined" ? global : window);
  }, [inputDDL, customSetting]);

  return (
    <>
      <p id="chart" ref={chartRef}>
        <svg
          id="svg_scratch"
          ref={svgScratchRef}
          height="600"
          width="1000"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            opacity: 0 /* not gone, just not visible */,
            zIndex: -1 /* move under the regular UI */,
            position: "absolute" /* don't take up space in the visible layout */,
          }}
        ></svg>
        <svg
          id="sankey_svg"
          ref={sankeySvgRef}
          height="600"
          width="600"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
      </p>
    </>
  );
};

export default SankeyChart;
