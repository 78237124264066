import { MenuIcon, MenuItem, MenuText, PageTitle, Tooltip } from "pixel";
import { Link, LinkProps, useLocation } from "react-router-dom";
import useUIState from "../packages/hooks/useUIState";
import { ProtectedMenu } from "../packages/utils/routeHelpers";

type FpConsoleNavLinkProps = LinkProps & {
  children: React.ReactNode;
};

const FpConsoleNavLink = ({ children, onClick, ...rest }: FpConsoleNavLinkProps) => {
  const { handleToggleMobileMenu } = useUIState();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    handleToggleMobileMenu(true);
    onClick?.(e);
  };

  return (
    <Link onClick={handleClick} {...rest}>
      {children}
    </Link>
  );
};

interface FpConsoleNavItemsProps {
  isSideBarCollapsed: boolean;
}
const FpConsoleNavItems = ({ isSideBarCollapsed }: FpConsoleNavItemsProps) => {
  const location = useLocation();
  const matchCurrentPath = (requiredRoute: string) => {
    const currentPath = location.pathname;
    return currentPath.includes(requiredRoute);
  };

  return (
    <>
      <div className="mb-8">
        <MenuItem customClass="mb-4">
          <div className="collapse-menu-title">Home</div>
          <FpConsoleNavLink className="block" to="/">
            <Tooltip
              placement="right"
              content="Home"
              hidden={!isSideBarCollapsed}
              customClass="block"
            >
              <MenuIcon customClass="block">Home</MenuIcon>
            </Tooltip>

            <MenuText>Home</MenuText>
          </FpConsoleNavLink>
        </MenuItem>

        <ProtectedMenu matchRoute="/fp-console/action-center">
          <MenuItem customClass="mb-4">
            <div className="collapse-menu-title">Action center</div>
            <FpConsoleNavLink className="flex items-center" to="/fp-console/action-center">
              <Tooltip
                placement="right"
                content="Action center"
                hidden={!isSideBarCollapsed}
                customClass="block"
              >
                <MenuIcon
                  customClass="block"
                  active={matchCurrentPath("/fp-console/action-center")}
                >
                  Action
                </MenuIcon>
              </Tooltip>

              <MenuText active={matchCurrentPath("/fp-console/action-center")}>
                Action center
              </MenuText>
            </FpConsoleNavLink>
          </MenuItem>
        </ProtectedMenu>

        <ProtectedMenu matchRoute="/fp-console/order-failure-code-list">
          <MenuItem customClass="mb-4">
            <div className="collapse-menu-title">Failure codes</div>
            <FpConsoleNavLink className="block" to="/fp-console/order-failure-code-list">
              <Tooltip
                placement="right"
                content="Failure codes"
                hidden={!isSideBarCollapsed}
                customClass="block"
              >
                <MenuIcon active={matchCurrentPath("/fp-console/order-failure-code-list")}>
                  FailureCode
                </MenuIcon>
              </Tooltip>
              <MenuText active={matchCurrentPath("/fp-console/order-failure-code-list")}>
                Failure codes
              </MenuText>
            </FpConsoleNavLink>
          </MenuItem>
        </ProtectedMenu>
      </div>

      <ProtectedMenu matchRoute="/fp-console/fp-account-details">
        <>
          {!isSideBarCollapsed && <PageTitle variant="group" title="FP ACCOUNT DETAILS" />}

          <ProtectedMenu matchRoute="/fp-console/fp-account-details/tenants" matchExact={true}>
            <MenuItem customClass="mb-4">
              <div className="collapse-menu-title">Tenants</div>
              <FpConsoleNavLink className="block" to="/fp-console/fp-account-details/tenants">
                <Tooltip
                  placement="right"
                  content="FP accounts"
                  hidden={!isSideBarCollapsed}
                  customClass="block"
                >
                  <MenuIcon active={matchCurrentPath("/fp-console/fp-account-details/tenants")}>
                    Tenant
                  </MenuIcon>
                </Tooltip>

                <MenuText active={matchCurrentPath("/fp-console/fp-account-details/tenants")}>
                  FP accounts
                </MenuText>
              </FpConsoleNavLink>
            </MenuItem>
          </ProtectedMenu>

          <ProtectedMenu
            matchRoute="/fp-console/fp-account-details/user-management"
            matchExact={true}
          >
            <MenuItem customClass="mb-4">
              <div className="collapse-menu-title">Tenants</div>
              <FpConsoleNavLink
                className="block"
                to="/fp-console/fp-account-details/user-management"
              >
                <Tooltip
                  placement="right"
                  content="User management"
                  hidden={!isSideBarCollapsed}
                  customClass="block"
                >
                  <MenuIcon
                    active={matchCurrentPath("/fp-console/fp-account-details/user-management")}
                  >
                    UserFilled
                  </MenuIcon>
                </Tooltip>

                <MenuText
                  active={matchCurrentPath("/fp-console/fp-account-details/user-management")}
                >
                  User management
                </MenuText>
              </FpConsoleNavLink>
            </MenuItem>
          </ProtectedMenu>
        </>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/fp-console/pincodes">
        <>
          {!isSideBarCollapsed && <PageTitle variant="group" title="MASTER DATA" />}

          <MenuItem customClass="mb-4">
            <div className="collapse-menu-title">Tenants</div>
            <FpConsoleNavLink className="block" to="/fp-console/pincodes">
              <Tooltip
                placement="right"
                content="Pincodes"
                hidden={!isSideBarCollapsed}
                customClass="block"
              >
                <MenuIcon active={matchCurrentPath("/fp-console/pincodes")}>PIN</MenuIcon>
              </Tooltip>

              <MenuText active={matchCurrentPath("/fp-console/pincodes")}>Pincodes</MenuText>
            </FpConsoleNavLink>
          </MenuItem>

          <MenuItem customClass="mb-4">
            <div className="collapse-menu-title">Tenants</div>
            <FpConsoleNavLink className="block" to="/fp-console/ifsc-codes">
              <Tooltip
                placement="right"
                content="IFSC codes"
                hidden={!isSideBarCollapsed}
                customClass="block"
              >
                <MenuIcon active={matchCurrentPath("/fp-console/ifsc-codes")}>IFSC</MenuIcon>
              </Tooltip>

              <MenuText active={matchCurrentPath("/fp-console/ifsc-codes")}>IFSC codes</MenuText>
            </FpConsoleNavLink>
          </MenuItem>
        </>
      </ProtectedMenu>
    </>
  );
};
export default FpConsoleNavItems;
