import { LandingImage, Panel } from "pixel";
import { useEffect } from "react";

interface LandingProps {
  onRender?: () => void;
}

const Landing = ({ onRender }: LandingProps) => {
  useEffect(() => {
    onRender && onRender();
  }, [onRender]);

  return (
    <>
      <Panel>
        <div className="flex flex-col items-center text-center" style={{ minHeight: "80vh" }}>
          <h5 className="font-roboto text-neutral-110 dark:text-neutral-dark-110 mb-6 text-2xl leading-normal">
            FP Console
          </h5>
          <p className="font-roboto text-size-18 max-w-64 text-neutral-40 mb-8">
            Welcome! Use the left menu to navigate to the appropriate sections. Explore features and
            manage your account.
          </p>
          <div className="pb-5">
            <LandingImage customClass="w-full md:w-auto" />
          </div>
        </div>
      </Panel>
    </>
  );
};
export default Landing;
