import AppWithRoutes from "./AppWithRoutes";
import Masterlayout from "./Layout/Masterlayout";
import UIStateProvider from "./packages/providers/UIStateProvider";

const App = () => {
  return (
    <UIStateProvider>
      <Masterlayout>
        <AppWithRoutes />
      </Masterlayout>
    </UIStateProvider>
  );
};
export default App;
