import { j as c } from "./jsx-runtime-DmTjM30f.js";
import { createContext as m, useContext as u, useReducer as y, useEffect as S } from "react";
import { LoginContext as i } from "./LoginContext.js";
const _ = /* @__PURE__ */ m({}), d = (o, e) => {
  switch (e.type) {
    case "SHOW_WELCOME_STAGE":
      return {
        ...o,
        showWelcomePage: !0
      };
    case "DISABLE_WELCOME_STAGE":
      return {
        ...o,
        showWelcomePage: !1
      };
    case "ADD_EMPLOYER":
      return {
        ...o,
        employer: {
          ...e.payload
        }
      };
    case "SET_USERNAME":
      return {
        ...o,
        username: e.payload.username
      };
    case "SET_EMPLOYER_BEING_ONBOARDED":
      return {
        ...o,
        employerBeingOnboarded: e.payload.employerName
      };
    case "SET_TENANT":
      return {
        ...o,
        tenant: e.payload.tenant
      };
  }
  throw Error(`Unknown action: ${e.type}`);
};
function A() {
  const o = {
    employer: null,
    showWelcomePage: !0,
    username: void 0,
    tenant: ""
  }, e = sessionStorage.getItem("showWelcomePage");
  return e !== null && (o.showWelcomePage = e === "true"), o;
}
const W = ({ children: o, tenant: e = "" }) => {
  const { loggedInUser: n } = u(i), [a, r] = y(d, null, A), s = (t) => {
    sessionStorage.setItem("showWelcomePage", t), r({
      type: t ? "SHOW_WELCOME_STAGE" : "DISABLE_WELCOME_STAGE",
      payload: {}
    });
  }, E = (t) => {
    r({
      type: "ADD_EMPLOYER",
      payload: {
        ...t
      }
    });
  }, p = (t) => {
    r({
      type: "SET_USERNAME",
      payload: {
        username: t
      }
    });
  }, l = (t) => {
    r({
      type: "SET_EMPLOYER_BEING_ONBOARDED",
      payload: {
        employerName: t
      }
    });
  };
  return S(() => {
    var t;
    r({
      type: "SET_TENANT",
      payload: {
        tenant: e || ((t = n == null ? void 0 : n.profile) == null ? void 0 : t.tenant)
      }
    });
  }, [
    e,
    n
  ]), /* @__PURE__ */ c.jsx(_.Provider, {
    value: {
      AppState: a,
      setShowWelcomePage: s,
      setEmployer: E,
      setUsername: p,
      setEmployerBeingOnboarded: l
    },
    children: o
  });
};
export {
  _ as AppContext,
  W as AppProvider
};
