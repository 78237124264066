import { TextStatus } from "../../../utils/types";

export const getFileOperationsStatusColourCode = (status: string): TextStatus => {
  switch (status) {
    case "pending": {
      return "warning";
    }

    case "processed": {
      return "success";
    }

    case "failed": {
      return "error";
    }

    default: {
      return "default";
    }
  }
};
