export const dataset = [
  `Received [1000] Accepted\n\n
  Received [300] Waiting for 2FA\n\n
  Received [200] Cancelled\n
  Received [100] Expired\n\n
  Accepted[600] Payment Collected\n
  Accepted[100] Failed\n
  Accepted[50] Cancelled\n
  Accepted[50] Expired\n
  Accepted[200] Payment Pending\n\n
  Payment Collected[400] Sent for processing\n
  Payment Collected[100] Pending submission\n
  Payment Collected[100] Failed\n\n
  Sent for processing[250] Successful\n
  Sent for processing[50] Failed\n
  Sent for processing[50] Reversed\n
  Sent for processing[50] Waiting response from RTA\n`,

  `Received [800] Accepted\n\n
  Received [400] Waiting for 2FA\n\n
  Received [200] Cancelled\n
  Received [200] Expired\n\n
  Accepted [400] Payment Collected\n 
  Accepted [150] Failed\n
  Accepted [100] Cancelled\n  
  Accepted [50] Expired\n  
  Accepted [100] Payment Pending\n\n
  Payment Collected [300] Sent for processing\n  
  Payment Collected [50] Pending submission\n  
  Payment Collected [50] Failed\n\n
  Sent for processing [220] Successful\n  
  Sent for processing [30] Failed\n  
  Sent for processing [20] Reversed\n  
  Sent for processing [30] Waiting response from RTA\n`,

  `Received [1300] Accepted\n\n
  Received [400] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [200] Expired\n\n
  Accepted [800] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [150] Cancelled\n
  Accepted [100] Expired\n
  Accepted [50] Payment Pending\n\n
  Payment Collected [600] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [400] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1200] Accepted\n\n
  Received [400] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [100] Expired\n\n
  Accepted [700] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [150] Cancelled\n
  Accepted [100] Expired\n
  Accepted [50] Payment Pending\n\n
  Payment Collected [500] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [300] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1500] Accepted\n\n
  Received [500] Waiting for 2FA\n\n
  Received [400] Cancelled\n
  Received [300] Expired\n\n
  Accepted [800] Payment Collected\n
  Accepted [250] Failed\n
  Accepted [250] Cancelled\n
  Accepted [100] Expired\n
  Accepted [100] Payment Pending\n\n
  Payment Collected [600] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [400] Successful\n
  Sent for processing [150] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1100] Accepted\n\n
  Received [600] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [200] Expired\n\n
  Accepted [600] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [100] Cancelled\n
  Accepted [100] Expired\n
  Accepted [100] Payment Pending\n\n
  Payment Collected [400] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [200] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1200] Accepted\n\n
  Received [300] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [200] Expired\n\n
  Accepted [700] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [100] Cancelled\n
  Accepted [100] Expired\n
  Accepted [100] Payment Pending\n\n
  Payment Collected [500] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [300] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1250] Accepted\n\n
  Received [450] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [200] Expired\n\n
  Accepted [700] Payment Collected\n
  Accepted [300] Failed\n
  Accepted [100] Cancelled\n
  Accepted [50] Expired\n
  Accepted [100] Payment Pending\n\n
  Payment Collected [500] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [300] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1600] Accepted\n\n
  Received [600] Waiting for 2FA\n\n
  Received [400] Cancelled\n
  Received [200] Expired\n\n
  Accepted [900] Payment Collected\n
  Accepted [50] Failed\n
  Accepted [150] Cancelled\n
  Accepted [50] Expired\n
  Accepted [450] Payment Pending\n\n
  Payment Collected [600] Sent for processing\n
  Payment Collected [200] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [400] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1300] Accepted\n\n
  Received [700] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [900] Expired\n\n
  Accepted [800] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [150] Cancelled\n
  Accepted [50] Expired\n
  Accepted [100] Payment Pending\n\n
  Payment Collected [600] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [400] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1350] Accepted\n\n
  Received [650] Waiting for 2FA\n\n
  Received [300] Cancelled\n
  Received [200] Expired\n\n
  Accepted [800] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [150] Cancelled\n
  Accepted [100] Expired\n
  Accepted [100] Payment Pending\n\n
  Payment Collected [600] Sent for processing\n
  Payment Collected [100] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [400] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1500] Accepted\n\n
  Received [500] Waiting for 2FA\n\n
  Received [600] Cancelled\n
  Received [500] Expired\n\n
  Accepted [1000] Payment Collected\n
  Accepted [300] Failed\n
  Accepted [100] Cancelled\n
  Accepted [50] Expired\n
  Accepted [50] Payment Pending\n\n
  Payment Collected [700] Sent for processing\n
  Payment Collected [200] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [500] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1500] Accepted\n\n
  Received [600] Waiting for 2FA\n\n
  Received [400] Cancelled\n
  Received [200] Expired\n\n
  Accepted [1000] Payment Collected\n
  Accepted [300] Failed\n
  Accepted [100] Cancelled\n
  Accepted [50] Expired\n
  Accepted [50] Payment Pending\n\n
  Payment Collected [700] Sent for processing\n
  Payment Collected [200] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [500] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,

  `Received [1250] Accepted\n\n
  Received [600] Waiting for 2FA\n\n
  Received [400] Cancelled\n
  Received [200] Expired\n\n
  Accepted [700] Payment Collected\n
  Accepted [200] Failed\n
  Accepted [100] Cancelled\n
  Accepted [50] Expired\n
  Accepted [200] Payment Pending\n\n
  Payment Collected [400] Sent for processing\n
  Payment Collected [200] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [250] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [25] Reversed\n
  Sent for processing [25] Waiting response from RTA\n`,

  `Received [1600] Accepted\n\n
  Received [600] Waiting for 2FA\n\n
  Received [400] Cancelled\n
  Received [200] Expired\n\n
  Accepted [900] Payment Collected\n
  Accepted [300] Failed\n
  Accepted [150] Cancelled\n
  Accepted [50] Expired\n
  Accepted [200] Payment Pending\n\n
  Payment Collected [600] Sent for processing\n
  Payment Collected [200] Pending submission\n
  Payment Collected [100] Failed\n\n
  Sent for processing [400] Successful\n
  Sent for processing [100] Failed\n
  Sent for processing [50] Reversed\n
  Sent for processing [50] Waiting response from RTA\n`,
];
