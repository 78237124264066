import { format } from "date-fns";
import { Button, Description, Label, Message, Modal, PageTitle, Selector, Tabs } from "pixel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../../../../context/userinfo/useUserInfo";
import { useTheme } from "../../../../hooks/useTheme";
import { PURCHASE_OVERVIEW_ENABLED_TENANTS } from "../../../../utils/constants";
import { tenantEnv } from "../../../../utils/utils";
import SankeyChart from "../../../display/SankeyChart";
import UnauthorisedPage from "../../../display/UnauthorisedPage";
import { getPurchaseData } from "./dataRetrieval";
import Legend from "./Legend";
import { FundType } from "./types";

type FundTypeOption = { label: string; value: FundType };

const FUND_TYPE_OPTIONS: FundTypeOption[] = [
  { label: "Liquid scheme purchases", value: "liquid" },
  { label: "Non liquid scheme purchases", value: "non_liquid" },
];

interface TabLabelParams {
  businessDayStatus: "before_cut_off" | "after_cut_off" | "non_business_day";
  tabIndex: number;
  currentDate: Date;
}

// Helper function to get the Nth previous business day
function getNthPreviousBusinessDay(date: Date, n: number): Date {
  let current = new Date(date);
  let count = 0;
  while (count < n) {
    current.setDate(current.getDate() - 1);
    if (current.getDay() !== 0 && current.getDay() !== 6) {
      count++;
    }
  }
  return current;
}

interface TabLabelParams {
  businessDayStatus: "before_cut_off" | "after_cut_off" | "non_business_day";
  tabIndex: number;
  currentDate: Date;
  fundType: "liquid" | "non_liquid"; // Add fundType
}

function generateTabLabel(params: TabLabelParams): string {
  const { businessDayStatus, tabIndex, currentDate, fundType } = params;

  const cutOffHour = fundType === "liquid" ? 13.5 : 15; // Set cutoff hour based on fund type
  const cutOffTime = new Date(currentDate);
  cutOffTime.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0); // Set cutoff time correctly
  const startOfDay = new Date(currentDate);
  startOfDay.setHours(9, 0, 0, 0);

  let startDate: Date;
  let endDate: Date;

  if (businessDayStatus === "before_cut_off") {
    switch (tabIndex) {
      case 0: // "Since Last Market Close"
        startDate = new Date(cutOffTime);
        startDate = getNthPreviousBusinessDay(startDate, 1);
        startDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        endDate = new Date(currentDate);
        break;
      case 1: // "Previous Business Day"
      case 2: // "Two Business Days Ago"
      case 3: // "Three Business Days Ago"
      case 4: // "Four Business Days Ago"
        startDate = getNthPreviousBusinessDay(cutOffTime, tabIndex);
        startDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        break;
      default:
        throw new Error("Invalid tab index");
    }
  } else if (businessDayStatus === "after_cut_off") {
    switch (tabIndex) {
      case 0: // "Since Today's Cut-Off"
        startDate = new Date(cutOffTime);
        endDate = new Date(currentDate);
        break;
      case 1: // "Earlier Today"
        startDate = startOfDay;
        endDate = new Date(cutOffTime);
        break;
      case 2: // "Previous Business Day"
        startDate = getNthPreviousBusinessDay(startOfDay, 1);
        startDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        endDate = startOfDay;
        break;
      case 3: // "Two Business Days Ago"
      case 4: // "Three Business Days Ago"
        startDate = getNthPreviousBusinessDay(startOfDay, tabIndex - 1);
        startDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        endDate = new Date(getNthPreviousBusinessDay(startOfDay, tabIndex - 2));
        endDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        break;
      default:
        throw new Error("Invalid tab index");
    }
  } else {
    // non_business_day
    switch (tabIndex) {
      case 0: // "Since Last Market Close"
        startDate = new Date(cutOffTime);
        startDate = getNthPreviousBusinessDay(startDate, 1);
        startDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        endDate = new Date(currentDate);
        break;
      case 1: // "Previous Business Day"
      case 2: // "Two Business Days Ago"
      case 3: // "Three Business Days Ago"
      case 4: // "Four Business Days Ago"
        endDate = getNthPreviousBusinessDay(currentDate, tabIndex);
        startDate = getNthPreviousBusinessDay(endDate, 1);
        startDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        endDate.setHours(Math.floor(cutOffHour), (cutOffHour % 1) * 60, 0, 0);
        break;
      default:
        throw new Error("Invalid tab index");
    }
  }

  return `From ${format(startDate, "dd MMM (ccc)")} ${format(startDate, "h:mm a")} to ${format(
    endDate,
    "dd MMM (ccc)"
  )} ${format(endDate, "h:mm a")}`;
}

function PurchasesOverview() {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const userinfo = useUserInfo();

  const [isModalOpen, toggleModal] = useState(false);

  const [purchaseTypeSelected, setPurchaseTypeSelected] = useState<FundTypeOption>(
    FUND_TYPE_OPTIONS[0]
  );
  const [divWidth, setDivWidth] = useState<number>(1100);
  const sankeyWrapperRef = useRef<HTMLDivElement | null>(null);

  const businessDaysTabs = useMemo(
    () => ({
      before_cut_off: [
        "Since Last Market Close",
        "Previous Business Day",
        "Two Business Days Ago",
        "Three Business Days Ago",
        "Four Business Days Ago",
      ],
      after_cut_off: [
        "Since Today's Cut-Off",
        "Earlier Today",
        "Previous Business Day",
        "Two Business Days Ago",
        "Three Business Days Ago",
      ],
      non_business_day: [
        "Since Last Market Close",
        "Previous Business Day",
        "Two Business Days Ago",
        "Three Business Days Ago",
        "Four Business Days Ago",
      ],
    }),
    []
  );

  // Determine business day status and current timestamp
  const isBusinessDay = useMemo(() => {
    const today = new Date();
    const day = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    return day >= 1 && day <= 5; // Monday to Friday are business days
  }, []);

  const currentTimeStamp = Math.floor(+new Date() / 1000);
  const currentDaysCutOff = useMemo(
    () => ({
      liquid: Math.floor(new Date().setHours(13, 30, 0, 0) / 1000),
      non_liquid: Math.floor(new Date().setHours(15, 0, 0, 0) / 1000),
    }),
    []
  );

  // Determine business day status
  const getBusinessDayStatus = useCallback(() => {
    if (!isBusinessDay) return "non_business_day";

    const fundType = purchaseTypeSelected.value;
    return currentTimeStamp < currentDaysCutOff[fundType] ? "before_cut_off" : "after_cut_off";
  }, [isBusinessDay, purchaseTypeSelected.value, currentTimeStamp, currentDaysCutOff]);

  const [selectedTab, setSelectedTab] = useState<string>(
    businessDaysTabs[getBusinessDayStatus()][0]
  );

  // Generate seed data dynamically
  const inputDDL = useMemo(() => {
    const businessDayStatus = getBusinessDayStatus();
    return getPurchaseData(purchaseTypeSelected.value, selectedTab, businessDayStatus).data;
  }, [getBusinessDayStatus, purchaseTypeSelected.value, selectedTab]);

  useEffect(() => {
    function updateDivWidth() {
      if (sankeyWrapperRef.current) {
        setDivWidth(sankeyWrapperRef.current.offsetWidth);
      }
    }

    updateDivWidth();
    window.addEventListener("resize", updateDivWidth);

    return () => {
      window.removeEventListener("resize", updateDivWidth);
    };
  }, []);

  const customSetting = useMemo(
    () => ({
      size_h: 600,
      size_w: divWidth,
      node_theme: "a",
      flow_inheritfrom: "source",
      bg_color: theme === "dark" ? "#1F222A" : "#FFFFFF",
    }),
    [theme, divWidth]
  );

  const tabs = useMemo(() => {
    const businessDayStatus = getBusinessDayStatus();
    const currentTabs = businessDaysTabs[businessDayStatus];

    return currentTabs.map((tabTitle, index) => {
      const labelForTab = generateTabLabel({
        businessDayStatus: businessDayStatus,
        tabIndex: index,
        currentDate: new Date(),
        fundType: purchaseTypeSelected.value,
      });

      return {
        title: tabTitle,
        content: (
          <div ref={sankeyWrapperRef} id="sankey_wrapper" className="w-full">
            <div className="flex w-full items-center justify-center">
              <Description type="labelDesc" text={labelForTab} />
            </div>

            <SankeyChart inputDDL={inputDDL} customSetting={customSetting} />
          </div>
        ),
      };
    });
  }, [getBusinessDayStatus, businessDaysTabs, purchaseTypeSelected.value, inputDDL, customSetting]);

  function onFundTypeChange(type: FundTypeOption) {
    setPurchaseTypeSelected(type);

    // Reset to first tab for the new fund type's business day status
    const businessDayStatus = getBusinessDayStatus();
    const defaultTab = businessDaysTabs[businessDayStatus][0];
    setSelectedTab(defaultTab);
  }

  function onTabChange(tabNumber: number) {
    const newTab = tabs[tabNumber].title;
    setSelectedTab(newTab);
  }

  if (
    tenantEnv.isStagingOrSandbox &&
    PURCHASE_OVERVIEW_ENABLED_TENANTS?.includes(userinfo?.data?.activeTenant ?? "")
  ) {
    return (
      <>
        <PageTitle title="Purchases overview" />

        <div className="flex justify-between">
          <div className="mb-5 flex w-1/2 items-center">
            <Label message={"Purchase type"} customClass="mr-2 w-1/4" />
            <Selector
              value={purchaseTypeSelected}
              onChange={(value) => {
                value && onFundTypeChange(value);
              }}
              options={FUND_TYPE_OPTIONS}
            />
          </div>
          <div className="flex flex-col items-end text-right">
            <Button variant="text" onClick={() => toggleModal(!isModalOpen)}>
              <Message
                type="important"
                variant="field"
                title="Know more about purchase states"
                customClass="mb-5"
              />
            </Button>
          </div>
        </div>

        <Tabs group={tabs} onChange={onTabChange} />

        <Modal
          variant="curvedEdge"
          title="Status descriptions"
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          fullWidth
          size="md"
        >
          <Legend />
        </Modal>
      </>
    );
  }

  return (
    <UnauthorisedPage
      errorTitle="Access denied"
      actionText="Go to homepage"
      action={() => navigate("/")}
    />
  );
}

export default PurchasesOverview;
