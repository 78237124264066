import { DataItem, WidgetList, WidgetListItem } from "pixel";

function Legend() {
  return (
    <WidgetList>
      <WidgetListItem>
        <DataItem
          label="Received"
          value="Total purchase orders created (Lumpsum and SIP orders)."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Accepted"
          value="Purchases for which investors have given consent."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Waiting for 2FA"
          value="Purchases for which consent has not been given yet by investor. Even payments have not been collected yet."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Cancelled"
          value="Number of purchases which have been cancelled by investor."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Expired"
          value="Number of purchases which have been marked as expired due to investor inactivity."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Payment pending"
          value="Number of accepted purchases against which payment is yet to be collected."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Payment collected"
          value="Number of accepted purchases against which payment has been collected."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Pending submission"
          value="Number of payment collected purchases which are yet to be submitted to order gateway."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Sent for processing"
          value="Number of payment collected purchases which have been submitted to order gateway for processing."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Successful"
          value="Number of purchases which were sent for processing and have been successfully processed by order gateway."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Reversed"
          value="Number of purchases which were earlier marked as successful, but reversed by order gateway due to some reason."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Waiting response from RTA"
          value="Number of purchases which were sent for processing for which we are yet to receive response from order gateway."
          background
        />
      </WidgetListItem>
      <WidgetListItem>
        <DataItem
          label="Failed"
          value="Number of purchases which failed either due to rejection by order gateway or payment failure."
          background
        />
      </WidgetListItem>
    </WidgetList>
  );
}

export default Legend;
