import { dataset } from "./data";
import { BusinessDayStatus, FundType } from "./types";

export interface PurchaseData {
  timeframe: string;
  data: string;
}

export interface Option {
  label: string;
  value: string;
  id?: string | number;
}

export const getRandomElementFromArray = (arr: string[]): string => {
  const index = Math.floor(Math.random() * arr.length);
  const element = arr[index];
  return element;
};

export function getPurchaseData(
  fundType: FundType,
  timeframe: string,
  businessDayStatus: BusinessDayStatus
): PurchaseData {
  const dataMap: Record<FundType, Record<BusinessDayStatus, Record<string, string>>> = {
    liquid: {
      before_cut_off: {
        "Since Last Market Close": getRandomElementFromArray(dataset),
        "Previous Business Day": getRandomElementFromArray(dataset),
        "Two Business Days Ago": getRandomElementFromArray(dataset),
        "Three Business Days Ago": getRandomElementFromArray(dataset),
        "Four Business Days Ago": getRandomElementFromArray(dataset),
      },
      after_cut_off: {
        "Since Today's Cut-Off": getRandomElementFromArray(dataset),
        "Earlier Today": getRandomElementFromArray(dataset),
        "Previous Business Day": getRandomElementFromArray(dataset),
        "Two Business Days Ago": getRandomElementFromArray(dataset),
        "Three Business Days Ago": getRandomElementFromArray(dataset),
      },
      non_business_day: {
        "Since Last Market Close": getRandomElementFromArray(dataset),
        "Previous Business Day": getRandomElementFromArray(dataset),
        "Two Business Days Ago": getRandomElementFromArray(dataset),
        "Three Business Days Ago": getRandomElementFromArray(dataset),
        "Four Business Days Ago": getRandomElementFromArray(dataset),
      },
    },
    non_liquid: {
      before_cut_off: {
        "Since Last Market Close": getRandomElementFromArray(dataset),
        "Previous Business Day": getRandomElementFromArray(dataset),
        "Two Business Days Ago": getRandomElementFromArray(dataset),
        "Three Business Days Ago": getRandomElementFromArray(dataset),
        "Four Business Days Ago": getRandomElementFromArray(dataset),
      },
      after_cut_off: {
        "Since Today's Cut-Off": getRandomElementFromArray(dataset),
        "Earlier Today": getRandomElementFromArray(dataset),
        "Previous Business Day": getRandomElementFromArray(dataset),
        "Two Business Days Ago": getRandomElementFromArray(dataset),
        "Three Business Days Ago": getRandomElementFromArray(dataset),
      },
      non_business_day: {
        "Since Last Market Close": getRandomElementFromArray(dataset),
        "Previous Business Day": getRandomElementFromArray(dataset),
        "Two Business Days Ago": getRandomElementFromArray(dataset),
        "Three Business Days Ago": getRandomElementFromArray(dataset),
        "Four Business Days Ago": getRandomElementFromArray(dataset),
      },
    },
  };

  const data = dataMap[fundType]?.[businessDayStatus]?.[timeframe] || "";

  return {
    timeframe,
    data,
  };
}

export async function fetchPurchaseDataFromAPI(
  fundType: FundType,
  timeframe: string,
  businessDayStatus: BusinessDayStatus
): Promise<PurchaseData> {
  try {
    // TODO: Replace with actual API call
    // const response = await fetch(`/api/purchases?fundType=${fundType}&timeframe=${timeframe}&status=${businessDayStatus}`);
    // return await response.json();

    // For now, fall back to seed data generation
    return getPurchaseData(fundType, timeframe, businessDayStatus);
  } catch (error) {
    window.debug.error("Failed to fetch purchase data:", error);
    // Fallback to seed data in case of API failure
    return getPurchaseData(fundType, timeframe, businessDayStatus);
  }
}
