import { j as m } from "./jsx-runtime-DmTjM30f.js";
import { L as J, a as P } from "./index.esm-D91NLXFb.js";
import { createContext as W, useContext as U, useState as g, useEffect as $ } from "react";
import { A as h } from "./jwt-decode.esm-BlmmGNpJ.js";
import { F as q } from "./provider-D3V1ahjt.js";
import { a as b, s as E } from "./sentry-CAR5o4wv.js";
import { p as z } from "./utils-D5LKKDAj.js";
const B = /* @__PURE__ */ W({}), D = ({ userType: v, children: N, override: x = !1, loggedInPartnerID: c = void 0, loggedInPartnerLicenceCode: V = void 0 }) => {
  const { fpapi: l } = U(q), [F, o] = g(void 0), [I, p] = g(c), [M, f] = g(!1);
  let i, _;
  v === "INVESTOR_CIAM" && !x && (i = U(h), _ = (i == null ? void 0 : i.accessToken) ?? void 0);
  const O = () => {
    var y;
    if (x) {
      f(!0), p(c);
      return;
    }
    if (v === "INVESTOR_CIAM") {
      if (!(i != null && i.isAuthenticated) || !(i != null && i.accessToken)) {
        !(i != null && i.isLoading) && !(i != null && i.isAuthenticated) && (i == null || i.login());
        return;
      }
      const t = _ && z(_), e = t ? t == null ? void 0 : t.preferred_username : "";
      if (t && e) {
        const n = t == null ? void 0 : t.iss, s = n == null ? void 0 : n.match(/realms\/(.*?)-ciam/), A = s ? s[1] : null, w = `fpa_${A}`;
        l.fpClient.ciam_user_management().searchUser(w, e).then((r) => {
          var C, L, j, S;
          const a = {
            profile: {
              fp_identifier: (C = r == null ? void 0 : r.annexure) == null ? void 0 : C.fp_identifier,
              preferred_username: r == null ? void 0 : r.username,
              new_fp_identifier: (L = r == null ? void 0 : r.annexure) == null ? void 0 : L.fp_identifier,
              tenant: A,
              typ: t == null ? void 0 : t.typ,
              type: t == null ? void 0 : t.type,
              session_state: t == null ? void 0 : t.session_state,
              sid: t == null ? void 0 : t.sid,
              sub: t == null ? void 0 : t.sub
            }
          };
          o(a), b({
            id: (j = a == null ? void 0 : a.profile) == null ? void 0 : j.sub
          }), E({
            tagName: "invp",
            tagValue: (S = a == null ? void 0 : a.profile) == null ? void 0 : S.fp_identifier
          }), f(!0);
        }).catch(() => {
          f(!0);
        });
      }
      return;
    }
    (y = l == null ? void 0 : l.userAuthService) == null || y.getAuthenticatedUser().then((t) => {
      var e, n, s;
      t && ((e = t == null ? void 0 : t.profile) == null ? void 0 : e.type) === "investor" && (o(t), b({
        id: (n = t == null ? void 0 : t.profile) == null ? void 0 : n.sub
      }), E({
        tagName: "invp",
        tagValue: (s = t == null ? void 0 : t.profile) == null ? void 0 : s.fp_identifier
      })), f(!0);
    }), p(c || void 0);
  };
  return $(() => {
    O();
  }, [
    i == null ? void 0 : i.accessToken
  ]), /* @__PURE__ */ m.jsx(m.Fragment, {
    children: M ? /* @__PURE__ */ m.jsx(B.Provider, {
      value: {
        loggedInUser: F,
        loggedInPartner: I,
        loggedInPartnerLicenceCode: V,
        setLoggedInUser: o,
        setLoggedInPartner: p
      },
      children: N
    }) : /* @__PURE__ */ m.jsx(J, {
      variant: "plain",
      children: /* @__PURE__ */ m.jsx(P, {
        variant: "fullpage"
      })
    })
  });
};
export {
  B as LoginContext,
  D as LoginProvider
};
