import { FieldRenderProps } from "react-final-form";
import { Filter } from "../components/functional/OrderFailureCodes/types";
import { Role, RoleLabelMap, Roles } from "../context/userinfo/types";
import { FilterTruthyValues } from "./types";
import { SetURLSearchParams } from "react-router-dom";
import { MfFolioObject, SchemeResponse } from "@fintechprimitives/fpapi";
type CronType = "minute" | "hour" | "dayOfMonth" | "month" | "dayOfWeek";

export const TENANT_ENV: string = process.env?.REACT_APP_TENANT_ENV as string;

export const tenantEnv = {
  isQa: TENANT_ENV === "qa",
  isStaging: TENANT_ENV === "staging",
  isSandbox: TENANT_ENV === "sandbox",
  isProduction: TENANT_ENV === "production",
  isQaOrStaging: ["qa", "staging"].includes(TENANT_ENV),
  isStagingOrSandbox: ["staging", "sandbox"].includes(TENANT_ENV),
};

export const isEmptyObject = (obj: any) => {
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    return Object.keys(obj).length === 0;
  } else {
    return true;
  }
};

export const isEmptyArray = (arr: any[]) => {
  if (Array.isArray(arr) && arr.length) {
    return false;
  } else {
    return true;
  }
};

export function toTitleCase(str: string | undefined = undefined) {
  if (!str) {
    return undefined;
  }

  const spacedStr = str.split(" ");
  if (spacedStr.length) {
    const spacesTrim = spacedStr.filter((word) => word);
    const result = spacesTrim
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return result;
  }
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}

/**
 * @method toISOStringWithTZ - Convert input date object into ISO8601 string with time zone
 * @param date DateObject
 * USECASE: Factor in TimeZone while converting date object to ISO8601 string
 */
const toISOStringWithTZ = (date: Date) => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => {
    return (num < 10 ? "0" : "") + num;
  };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};

export const formatDate = (d: Date, format: string) => {
  switch (format) {
    case "yyyy-mm-dd": // 2022-10-28
      if (Object.prototype.toString.call(d) === "[object Date]") {
        return toISOStringWithTZ(d).split("T")[0];
      } else {
        return toISOStringWithTZ(new Date()).split("T")[0];
      }
    case "dd-MMM-yyyy": // 28-Nov-2022
      if (Object.prototype.toString.call(d) === "[object Date]") {
        const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        const month = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${day}-${month}-${year}`;
      } else {
        const date = new Date();
        const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
        const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
        const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
        return `${day}-${month}-${year}`;
      }
    case "dd-MM-yyyy":
      if (!d) {
        return undefined;
      }

      if (Object.prototype.toString.call(d) === "[object Date]") {
        const dateStr = d.toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata",
        });

        return dateStr.replaceAll("/", "-");
      } else {
        const dateObj = new Date(d);

        const dateStr = dateObj.toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata",
        });

        return dateStr.replaceAll("/", "-");
      }
    default:
      if (Object.prototype.toString.call(d) === "[object Date]") {
        return toISOStringWithTZ(d).split("T")[0];
      } else {
        return toISOStringWithTZ(new Date()).split("T")[0];
      }
  }
};

export const scrollToErrorField = (errorFieldName: string) => {
  if (errorFieldName) {
    const inputField = document.querySelector(`[data-name=${errorFieldName}]`);
    if (inputField) {
      inputField.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }
  return;
};

export const convert_dd_mm_yyyy_to_mm_dd_yyyy = (dateStr: string) => {
  const splitDateStr = dateStr?.split("-");

  if (splitDateStr?.length !== 3) {
    // current date str
    return new Date().toLocaleDateString("en").replaceAll("/", "-");
  }

  const [day, month, year] = splitDateStr;

  return `${month}-${day}-${year}`;
};

export const formatDateObjToMmDdYyyy = (date: Date) => {
  return date
    .toLocaleDateString("en-in", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replaceAll("/", "-");
};

export const formatDateObjToYyyyMmDd = (date: Date) => {
  return date
    .toLocaleDateString("en-in", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");
};

export const getLastMonthDateRange = (format: string = "MM-DD-YYYY") => {
  const currentDate = new Date();
  const lastMonth = currentDate.getMonth() - 1;
  const currentYear = currentDate.getFullYear();

  const startDate = new Date(currentYear, lastMonth, 1);

  const endDate = new Date(currentYear, lastMonth + 1, 0); // 0 sets the day to day before 1st of the given month

  if (format === "YYYY-MM-DD") {
    return {
      startDate: formatDateObjToYyyyMmDd(startDate),
      endDate: formatDateObjToYyyyMmDd(endDate),
    };
  }

  return {
    startDate: formatDateObjToMmDdYyyy(startDate),
    endDate: formatDateObjToMmDdYyyy(endDate),
  };
};

export const flattenBatchFiltersArrayForPills = (
  label: string | undefined,
  filters: Filter[] | undefined,
  fieldValuesDisplayLabel?: string | undefined
) => {
  if (
    filters &&
    filters?.length > 1 &&
    (label === "Created at" || label === "Generated date and time")
  ) {
    //This means it's a date range
    return [{ [label ?? "Label"]: fieldValuesDisplayLabel }];
  }

  const filterArray = filters?.map((filter: Filter) => {
    const { value } = filter;

    if (Array.isArray(value)) {
      return value.flatMap((val) => ({ [label ?? "Label"]: val }));
    } else {
      return [{ [label ?? "Label"]: value }];
    }
  });

  return filterArray?.flat();
};

export const activeTenantLocalStorage = {
  getActiveTenant() {
    return localStorage.getItem("activeTenant");
  },
  setActiveTenant(tenant: string) {
    return localStorage.setItem("activeTenant", tenant);
  },
  removeActiveTenant() {
    return localStorage.removeItem("activeTenant");
  },
};

export const getOptionsIndex = (
  data: any[],
  value?: string | number | null | boolean,
  options?: { isNotArrayOfObjects?: boolean; customProperty?: string }
) => {
  const isNotArrayOfObjects = options?.isNotArrayOfObjects;
  const customProperty = options?.customProperty || "value"; // by default searches for "value" in given array of objects. If we need to look for a custom key pass this optional argument

  return data.findIndex((ele: any) => {
    if (isNotArrayOfObjects) {
      return ele === value;
    } else {
      // Traversal inside a deeply nested object
      if (customProperty.includes(".")) {
        const nestedAccessArray = customProperty.split(".");

        let nestedAccessValue;

        for (const property of nestedAccessArray) {
          if (!nestedAccessValue) {
            nestedAccessValue = ele[property];
          } else {
            nestedAccessValue = nestedAccessValue[property];
          }
        }

        return nestedAccessValue === value;
      }

      return ele[customProperty] === value;
    }
  });
};

export const parseJWT = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const JSONPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(JSONPayload);
};

export const getFileNameFromContentDispositionHeader = (
  contentDispositionHeader: string | undefined
) => {
  if (!contentDispositionHeader) return undefined;

  const contentDispositionHeaderSplit = contentDispositionHeader?.split(";");
  const filenameHeaderValue = contentDispositionHeaderSplit
    ?.find((value) => value?.trim()?.startsWith("filename="))
    ?.trim();
  const filename = filenameHeaderValue?.split("=")?.[1]?.replaceAll('"', "");

  return filename;
};

export const removePrefixFromString = (
  fullString: string,
  prefixString: string,
  fallBackString?: string
) => {
  if (fullString && fullString?.startsWith(prefixString)) {
    return fullString?.slice(prefixString?.length);
  }

  return fallBackString ?? "/";
};

export const isAuthorisedRole = (allowedRoles: Roles, currentRoles: Roles | undefined) => {
  return allowedRoles.some((role) => currentRoles?.includes(role));
};

export const formatDateForTable = (
  date: Date | null | undefined | string,
  format: string = "dd MMM yyyy HH:mm"
): string => {
  if (!date) {
    return "-";
  }

  let options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  if (format.includes("HH:mm")) {
    options = { ...options, hour: "numeric", minute: "numeric", hour12: true };
  }

  if (format === "dd MMM yyyy HH:mm:ss:ms") {
    options.second = "numeric";
    options.fractionalSecondDigits = 2;
  }

  const formattedDate = new Date(date).toLocaleString("en-US", options);

  return formattedDate;
};

export const anyCharacterExcluding = (characters: any) => {
  return new RegExp(`[^${characters}]`, "g");
};

export const isUpstreamError = (e: any) => {
  const axiosProperties = {
    data: 1,
    status: 1,
    statusText: 1,
    headers: 1,
    config: 1,
  };

  for (const key in axiosProperties) {
    if (!Object.prototype.hasOwnProperty.call(e, key)) {
      return false;
    }
  }

  return true;
};

export const maskPassword = (password?: string) => {
  if (!password) {
    return "-";
  }

  if (password?.length >= 1 && password?.length <= 6) {
    return "********";
  }

  return "****" + password?.slice(-4);
};

export const getLast30MinsDateRangeStr = () => {
  const date30MinsAgo = new Date();
  date30MinsAgo.setMinutes(date30MinsAgo.getMinutes() - 30);

  return `${Number(date30MinsAgo)}-${Number(new Date())}`;
};

export const formatNumber = (num: number, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
  const number = num;
  if (num) {
    return number
      .toLocaleString("en-IN", {
        minimumFractionDigits,
        maximumFractionDigits,
        style: "currency",
        currency: "INR",
      })
      .split("₹")[1];
  } else {
    return 0;
  }
};

export const getCurrentFinancialYearDate = () => {
  const startDate = new Date();
  startDate.setMonth(3);
  startDate.setDate(1);

  const endDate = new Date();

  return `${formatDateForTable(startDate, "dd MMM yyyy")} - ${formatDateForTable(
    endDate,
    "dd MMM yyyy"
  )}`;
};

export const getRealmSpecificAuthorityURL = (authorityUrl: string, realm: string) => {
  const currentAuthorityURL = authorityUrl.split("/");
  currentAuthorityURL?.pop();
  const tenantAuthorityURL = currentAuthorityURL?.join("/") + `/${realm}`;

  return tenantAuthorityURL;
};

export const mutateField = ([field, value]: any, state: any, { changeValue }: any) => {
  changeValue(state, field, () => value);
};

const capitalWords = [
  "Dhfl",
  "Lic",
  "Icici",
  "Sbi",
  "Boi",
  "Axa",
  "Elss",
  "Idfc",
  "Hdfc",
  "L&t",
  "Dsp",
  "Uti",
];

export const formatBankName = (bankName: string) => {
  const abbreviatedBankNames = [...capitalWords];

  if (bankName) {
    const abbreviation = abbreviatedBankNames.find((abbreviatedName) => {
      return bankName.toLowerCase().includes(abbreviatedName.toLowerCase());
    });

    if (abbreviation) {
      return toTitleCase(bankName.replace(abbreviation, abbreviation.toUpperCase()));
    } else {
      return toTitleCase(bankName);
    }
  }
  return "";
};

export const memoize = (fn: any) => {
  let lastArg: any;
  let lastResult: any;
  return (arg: any) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

export const hasVisitedConsoleSessionStorage = {
  get() {
    return sessionStorage.getItem("hasVisitedConsole");
  },
  set(value: string) {
    sessionStorage.setItem("hasVisitedConsole", value);
  },
  remove() {
    sessionStorage.removeItem("hasVisitedConsole");
  },
};

export const hasVisitedInvestSessionStorage = {
  get() {
    return sessionStorage.getItem("hasVisitedInvest");
  },
  set(value: string) {
    sessionStorage.setItem("hasVisitedInvest", value);
  },
  remove() {
    sessionStorage.removeItem("hasVisitedInvest");
  },
};

export const showFieldError = (props: FieldRenderProps<any, HTMLElement, any>) => {
  return props.meta.error && props.meta.touched ? "error" : undefined;
};

export const getStatus = (props: FieldRenderProps<any>) => {
  return showFieldError(props) ? "error" : undefined;
};

export interface ListResponse<T> {
  object: "list";
  data: T[];
}

export const getEitherValue = (value1: any, value2: string | null = "-") => {
  return value1 ? value1 : value2;
};

export const restrictEditInProduction = (defaultExpression: boolean) => {
  return defaultExpression && tenantEnv.isProduction;
};

const daysOfWeekMap: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsMap: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function handleAsterisk(type: CronType): string {
  switch (type) {
    case "minute":
      return "every minute";
    case "hour":
      return "every hour";
    case "dayOfMonth":
      return "every day of the month";
    case "month":
      return "every month";
    case "dayOfWeek":
      return "every day";
  }
}

function handleSlash(field: string, type: CronType): string {
  const [start, interval] = field.split("/");
  switch (type) {
    case "minute":
      return `every ${interval} minutes starting at minute ${start}`;
    case "hour":
      return `every ${interval} hours starting at hour ${start}`;
    case "dayOfMonth":
      return `every ${interval} days starting at day ${start} of the month`;
    case "month":
      return `every ${interval} months starting at month ${start}`;
    case "dayOfWeek":
      return `every ${interval} days starting at ${daysOfWeekMap[parseInt(start, 10)]}`;
  }
}

function handleDash(field: string, type: CronType): string {
  const [start, end] = field.split("-");
  switch (type) {
    case "minute":
      return `every minute between ${start} and ${end}`;
    case "hour":
      return `every hour between ${start} and ${end}`;
    case "dayOfMonth":
      return `every day between ${start} and ${end} of the month`;
    case "month":
      return `every month between ${monthsMap[parseInt(start, 10) - 1]} and ${
        monthsMap[parseInt(end, 10) - 1]
      }`;
    case "dayOfWeek":
      return `every day between ${daysOfWeekMap[parseInt(start, 10)]} and ${
        daysOfWeekMap[parseInt(end, 10)]
      }`;
  }
}

function handleDefault(field: string, type: CronType): string {
  switch (type) {
    case "minute":
      return `at minute ${field}`;
    case "hour":
      return `at hour ${field}`;
    case "dayOfMonth":
      return `on day ${field} of the month`;
    case "month":
      return `in ${monthsMap[parseInt(field, 10) - 1]}`;
    case "dayOfWeek":
      return `on ${daysOfWeekMap[parseInt(field, 10)]}`;
  }
}

function parseCronField(field: string, type: CronType): string {
  if (field === "*") {
    return handleAsterisk(type);
  }

  if (field.includes("/")) {
    return handleSlash(field, type);
  }

  if (field.includes("-")) {
    return handleDash(field, type);
  }

  return handleDefault(field, type);
}

// Example output convertCronToText("0 7 * * *") = "At 07:00, every day of the month, every month, every day"
export function convertCronToText(cronExpression?: string): string {
  if (!cronExpression) {
    return "-";
  }
  const [minute, hour, dayOfMonth, month, dayOfWeek] = cronExpression.split(" ");

  const humanReadable = {
    minute: parseCronField(minute, "minute"),
    hour: parseCronField(hour, "hour"),
    dayOfMonth: parseCronField(dayOfMonth, "dayOfMonth"),
    month: parseCronField(month, "month"),
    dayOfWeek: parseCronField(dayOfWeek, "dayOfWeek"),
  };

  return `At ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}, ${humanReadable.dayOfMonth}, ${
    humanReadable.month
  }, ${humanReadable.dayOfWeek}`
    .replace(/\s+/g, " ")
    .trim();
}

export function isValidRole(role: string): role is Role {
  const validRoles = Object.entries(Role).map((val) => val[1].toString());
  return validRoles.includes(role);
}

export function fetchRoleLabel(role: string): string {
  if (isValidRole(role)) {
    return RoleLabelMap[role];
  } else {
    return role;
  }
}

export const filterTruthyValues = <T extends Record<string, any>>(
  obj: T
): FilterTruthyValues<T> => {
  return Object.fromEntries<FilterTruthyValues<T>>(
    Object.entries(obj).filter(([key, value]) => Boolean(value))
  ) as FilterTruthyValues<T>;
};

export const serialiseFilters = <T extends Record<string, string>>(
  obj: T
): Record<keyof T, string> => {
  const serialisedFilters = {} as Record<keyof T, string>;

  for (const key in obj) {
    serialisedFilters[key] = encodeURIComponent(obj[key]);
  }

  return serialisedFilters;
};

export const deserialiseFilters = <T extends Record<string, string>>(
  urlSearchParams: URLSearchParams
): Record<keyof T, string> => {
  const deserialisedFilters = {} as Record<keyof T, string>;

  urlSearchParams.forEach((value, key) => {
    if (value) {
      deserialisedFilters[key as keyof T] = decodeURIComponent(value);
    } else {
      deserialisedFilters[key as keyof T] = value;
    }
  });

  return deserialisedFilters;
};

export const sanitizeString = (value: any): string => {
  return value ? value.toString() : "";
};

export const formatNumberToIndianValue = (
  number?: number | null,
  options?: Intl.NumberFormatOptions
) => {
  if (typeof number !== "number") {
    return "-";
  }

  return number.toLocaleString("en-IN", options);
};

export const formatINR = (number?: number | null, options?: Intl.NumberFormatOptions) => {
  if (typeof number !== "number") {
    return "-";
  }

  return `₹ ${formatNumberToIndianValue(number, options)}`;
};

export const containsExactlyAllElements = (
  array: any[] | null | undefined,
  arrayToCheck: any[] | null | undefined
) => {
  if (!array || !arrayToCheck) {
    return false;
  }

  if (array?.length !== arrayToCheck?.length) {
    return false;
  }

  return arrayToCheck?.every((element) => array?.includes(element));
};

export function chooseVariant(check: boolean, value1: string, value2: string) {
  return check ? value1 : value2;
}

export const maskBankAccountNumber = (bankAccountNumber?: string) => {
  if (!bankAccountNumber || typeof bankAccountNumber !== "string") {
    return undefined;
  }

  const lastFourChars = bankAccountNumber.slice(-4);

  return `****${lastFourChars}`;
};

export const getErrorDescription = (e: any, defaultErrorMessage = "Something went wrong") => {
  if (isUpstreamError(e)) {
    return e?.data?.error?.message ?? defaultErrorMessage;
  }

  return e.message ?? defaultErrorMessage;
};

export const filterSearchParamsByKey = (
  rawParams: URLSearchParams,
  filterKey: string,
  deserialise = false
) => {
  const filteredParams = Array.from(rawParams.entries())
    .filter((param) => param[0].endsWith(filterKey))
    .map((param) => {
      if (param[1] && deserialise) {
        return [
          param[0].slice(0, param[0].length - filterKey.length),
          decodeURIComponent(param[1]),
        ];
      }
      return [param[0].slice(0, param[0].length - filterKey.length), param[1]];
    });
  return Object.fromEntries(filteredParams);
};

export const setSearchParamsByKey = (
  updatedParams: Record<string, string>,
  filterKey: string,
  setSearchParams: SetURLSearchParams
) => {
  setSearchParams(
    Object.fromEntries(
      Object.entries(updatedParams).map((param) => [`${param[0]}${filterKey}`, param[1]])
    )
  );
};

export const partnerSelectedFolioSessionStorage = {
  get() {
    return JSON.parse(sessionStorage.getItem("partnerSelectedFolio") || "{}");
  },
  set(value: MfFolioObject) {
    sessionStorage.setItem("partnerSelectedFolio", JSON.stringify(value));
  },
  remove() {
    sessionStorage.removeItem("partnerSelectedFolio");
  },
};

export const partnerSelectedSchemeSessionStorage = {
  get() {
    return JSON.parse(sessionStorage.getItem("partnerSelectedScheme") || "{}");
  },
  set(value: SchemeResponse) {
    sessionStorage.setItem("partnerSelectedScheme", JSON.stringify(value));
  },
  remove() {
    sessionStorage.removeItem("partnerSelectedScheme");
  },
};

export const downloadFile = (file: Blob, fileName: string) => {
  const link = document.createElement("a");

  const downloadURL = URL.createObjectURL(file);
  link.href = downloadURL;

  link.download = fileName;

  link.click();

  URL.revokeObjectURL(downloadURL);
};
