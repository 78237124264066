//Core imports
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

//Functional & Workflow imports
import ProtectedRoutes from "./ProtectedRoutes";
import {
  AddTenant,
  AdvisoryService,
  AmcConfigurationService,
  AssistedCheckout,
  AssistedFolios,
  AssistedInvestorDetails,
  AssistedMandateStatus,
  AssistedOnboarding,
  AssistedOrderHistory,
  AssistedPlanUpdate,
  AssistedPlansList,
  AssitedWithdraw,
  BITool,
  BankAccountVerificationsListing,
  BatchFilesListing,
  EditNotificationWebhooks,
  FolioDetails,
  FolioListing,
  FpAssist,
  FundSchemesListing,
  IFSCCodes,
  InternalUsersManagement,
  InvestmentAccountsDetails,
  InvestmentAccountsListing,
  InvestorDetails,
  InvestorProfileDetails,
  InvestorsListing,
  KYCRequestsDetails,
  KYCRequestsListing,
  KYCService,
  ListAgingOrders,
  ListApiLogs,
  ListFiles,
  ListPaymentTransfers,
  ManageConsolidatedActions,
  ManageOrderFailureCodes,
  ManageOrderOperations,
  ManagePaymentsGateway,
  ManageUser,
  MandatesDetails,
  MandatesListing,
  NonIndividualInvestorProfileDetails,
  NonIndividualInvestorProfilesListing,
  NotFoundPage,
  NotificationWebhooks,
  OrderConfigurationService,
  PartnerAddition,
  PartnerDetails,
  PartnersListing,
  PaymentDetails,
  PaymentListing,
  Pincodes,
  PurchaseDetails,
  PurchaseListing,
  PurchasePlanDetails,
  PurchasePlanListing,
  RedemptionDetails,
  RedemptionListing,
  RedemptionPlanDetails,
  RedemptionPlanListing,
  RegenerateFatcaAnnexure,
  ScheduledQueries,
  ScheduledQueryRuns,
  SchemeDetails,
  ShowTransferDetails,
  SwitchDetails,
  SwitchListing,
  SwitchPlanDetails,
  SwitchPlanListing,
  TenantUsersManagement,
  TenantsListing,
  TransactionsListing,
  VerificationService,
  ViewApiLogDetaills,
} from "./packages";

//Display
import Landing from "./packages/components/display/Landing";
import { EventsListing } from "./packages/components/functional/Events";
import FileOperationsListing from "./packages/components/functional/FileOperations/FileOperationsListing";
import ConfigurationStatus from "./packages/components/functional/FpAccount/ConfigurationStatus/ConfigurationStatus";
import PurchasesOverview from "./packages/components/functional/Orders/PurchaseOverview/PurchaseOverview";
import RoboxAppInit from "./packages/context/roboxFpapi/RoboxAppInit";
import { useUserInfo } from "./packages/context/userinfo/useUserInfo";
import { tenantEnv } from "./packages/utils/utils";
import { PURCHASE_OVERVIEW_ENABLED_TENANTS } from "./packages/utils/constants";

export default function AppWithRoutes() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = useUserInfo();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Landing
            onRender={() => {
              if (
                tenantEnv.isStagingOrSandbox &&
                PURCHASE_OVERVIEW_ENABLED_TENANTS?.includes(userInfo?.data?.activeTenant ?? "")
              ) {
                navigate("/orders/overview");
              }
            }}
          />
        }
      />

      {/*************************** FP Console Routes ***************************/}

      <Route
        path="/fp-console/order-failure-code-list"
        element={
          <ProtectedRoutes
            path="/fp-console/order-failure-code-list"
            component={<ManageOrderFailureCodes />}
          />
        }
      />

      <Route
        path="/fp-console/order-failure-code"
        element={
          <ProtectedRoutes
            path="/fp-console/order-failure-code"
            component={<ManageOrderFailureCodes />}
          />
        }
      />

      <Route
        path="/fp-console/action-center"
        element={
          <ProtectedRoutes
            path="/fp-console/action-center"
            component={<ManageConsolidatedActions />}
          />
        }
      />

      <Route
        path="/fp-console"
        element={<ProtectedRoutes path="/fp-console" component={<Landing />} />}
      />

      <Route
        path="/fp-console/fp-account-details/tenants"
        element={
          <ProtectedRoutes
            path="/fp-console/fp-account-details/tenants"
            component={<TenantsListing />}
          />
        }
      />

      <Route
        path="/fp-console/fp-account-details/tenants/new"
        element={
          <ProtectedRoutes
            path="/fp-console/fp-account-details/tenants/new"
            component={<AddTenant />}
          />
        }
      />

      <Route
        path="/fp-console/fp-account-details/user-management"
        element={
          <ProtectedRoutes
            path="/fp-console/fp-account-details/user-management"
            component={<InternalUsersManagement />}
          />
        }
      />

      <Route
        path="/fp-console/fp-account-details/user-management/new"
        element={
          <ProtectedRoutes
            path="/fp-console/fp-account-details/user-management/new"
            component={
              <ManageUser
                mode="CREATE"
                userCreationType="CYBRILLA_INTERNAL"
                onBack={() => {
                  navigate("/fp-console/fp-account-details/user-management");
                }}
                onComplete={() => {
                  navigate("/fp-console/fp-account-details/user-management");
                }}
              />
            }
          />
        }
      />

      <Route
        path="/fp-console/fp-account-details/user-management/root/new"
        element={
          <ProtectedRoutes
            path="/fp-console/fp-account-details/user-management/root/new"
            component={
              <ManageUser
                mode="CREATE"
                userCreationType="TENANT_ROOT"
                onBack={() => {
                  navigate("/fp-console/fp-account-details/user-management");
                }}
                onComplete={() => {
                  navigate("/fp-console/fp-account-details/user-management");
                }}
              />
            }
          />
        }
      />

      <Route
        path="/fp-console/fp-account-details/user-management/:id"
        element={
          <ProtectedRoutes
            path="/fp-console/fp-account-details/user-management/:id"
            component={
              <ManageUser
                mode="EDIT"
                userCreationType="CYBRILLA_INTERNAL"
                onBack={() => {
                  navigate("/fp-console/fp-account-details/user-management");
                }}
                onComplete={({ data }) => {
                  navigate("/fp-console/fp-account-details/user-management");

                  if (data?.username === userInfo?.data.userInfo?.email) {
                    window.location.reload();
                  }
                }}
              />
            }
          />
        }
      />

      <Route
        path="/fp-console/pincodes"
        element={<ProtectedRoutes path="/fp-console/pincodes" component={<Pincodes />} />}
      />

      <Route
        path="/fp-console/ifsc-codes"
        element={<ProtectedRoutes path="/fp-console/ifsc-codes" component={<IFSCCodes />} />}
      />

      {/*************************** Operations routes ***************************/}

      <Route
        path="/operations/order-operations"
        element={
          <ProtectedRoutes
            path="/operations/order-operations"
            component={<ManageOrderOperations />}
          />
        }
      />

      <Route
        path="/operations/order-operations/files/regenerate"
        element={
          <ProtectedRoutes
            path="/operations/order-operations/files/regenerate"
            component={
              <RegenerateFatcaAnnexure
                orderId={searchParams.get("order_id")}
                mfiaId={searchParams.get("mfia_id")}
              />
            }
          />
        }
      />

      <Route
        path="/operations/order-operations/files"
        element={
          <ProtectedRoutes path="/operations/order-operations/files" component={<ListFiles />} />
        }
      />

      <Route
        path="/operations/order-operations/batch-files"
        element={
          <ProtectedRoutes
            path="/operations/order-operations/batch-files"
            component={<BatchFilesListing />}
          />
        }
      />

      <Route
        path="/operations/aging-orders"
        element={
          <ProtectedRoutes component={<ListAgingOrders />} path="/operations/aging-orders" />
        }
      />

      <Route
        path="/operations/transactions"
        element={
          <ProtectedRoutes path="/operations/transactions" component={<TransactionsListing />} />
        }
      />

      <Route
        path="/operations/transfers"
        element={
          <ProtectedRoutes path="/operations/transfers" component={<ListPaymentTransfers />} />
        }
      />

      <Route
        path="/operations/transfers/:transfer_reference"
        element={
          <ProtectedRoutes
            path="/operations/transfers/:transfer_reference"
            component={<ShowTransferDetails paymentTransfers={[]} currentTransferId={null} />}
          />
        }
      />

      <Route
        path="/operations/folios"
        element={<ProtectedRoutes path="/operations/folios" component={<FolioListing />} />}
      />
      <Route
        path="/operations/folios/:folio_number"
        element={
          <ProtectedRoutes path="/operations/folios/:folio_number" component={<FolioDetails />} />
        }
      />

      <Route
        path="/operations/fund-schemes"
        element={
          <ProtectedRoutes path="/operations/fund-schemes" component={<FundSchemesListing />} />
        }
      />

      <Route
        path="/operations/fund-schemes/scheme-details/:scheme_isin"
        element={
          <ProtectedRoutes
            path="/operations/fund-schemes/scheme-details/:scheme_isin"
            component={<SchemeDetails />}
          />
        }
      />

      <Route
        path="/operations/order-operations/file-operations"
        element={
          <ProtectedRoutes
            path="/operations/order-operations/file-operations"
            component={<FileOperationsListing showBackButton={true} />}
          />
        }
      />

      {/*************************** Order Routes ***************************/}
      <Route
        path="/orders/overview"
        element={<ProtectedRoutes path="/orders/overview" component={<PurchasesOverview />} />}
      />

      <Route
        path="/orders/purchases"
        element={<ProtectedRoutes path="/orders/purchases" component={<PurchaseListing />} />}
      />

      <Route
        path="/orders/purchases/:purchaseId"
        element={
          <ProtectedRoutes path="/orders/purchases/:purchaseId" component={<PurchaseDetails />} />
        }
      />

      <Route
        path="/orders/redemptions"
        element={<ProtectedRoutes path="/orders/redemptions" component={<RedemptionListing />} />}
      />

      <Route
        path="/orders/redemptions/:redemptionId"
        element={
          <ProtectedRoutes
            path="/orders/redemptions/:redemptionId"
            component={<RedemptionDetails />}
          />
        }
      />

      <Route
        path="/orders/switches"
        element={<ProtectedRoutes path="/orders/switches" component={<SwitchListing />} />}
      />

      <Route
        path="/orders/switches/:switchId"
        element={
          <ProtectedRoutes path="/orders/switches/:switchId" component={<SwitchDetails />} />
        }
      />

      <Route
        path="/orders/purchase-plans"
        element={
          <ProtectedRoutes path="/orders/purchase-plans" component={<PurchasePlanListing />} />
        }
      />

      <Route
        path="/orders/purchase-plans/:purchasePlanId"
        element={
          <ProtectedRoutes
            path="/orders/purchase-plans/:purchasePlanId"
            component={<PurchasePlanDetails />}
          />
        }
      />

      <Route
        path="/orders/redemption-plans"
        element={
          <ProtectedRoutes path="/orders/redemption-plans" component={<RedemptionPlanListing />} />
        }
      />

      <Route
        path="/orders/redemption-plans/:redemptionPlanId"
        element={
          <ProtectedRoutes
            path="/orders/redemption-plans/:redemptionPlanId"
            component={<RedemptionPlanDetails />}
          />
        }
      />

      <Route
        path="/orders/switch-plans"
        element={<ProtectedRoutes path="/orders/switch-plans" component={<SwitchPlanListing />} />}
      />

      <Route
        path="/orders/switch-plans/:switchPlanId"
        element={
          <ProtectedRoutes
            path="/orders/switch-plans/:switchPlanId"
            component={<SwitchPlanDetails />}
          />
        }
      />

      {/*************************** Payment Routes ***************************/}
      <Route
        path="/payments"
        element={<ProtectedRoutes path="/payments" component={<PaymentListing />} />}
      />

      <Route
        path="/payments/:paymentId"
        element={<ProtectedRoutes path="/payments/:paymentId" component={<PaymentDetails />} />}
      />

      <Route
        path="/mandates"
        element={<ProtectedRoutes path="/mandates" component={<MandatesListing />} />}
      />

      <Route
        path="/mandates/:mandateId"
        element={<ProtectedRoutes path="/mandates/:mandateId" component={<MandatesDetails />} />}
      />

      {/*************************** Investor Routes ***************************/}

      <Route
        path="/investors/investment_accounts"
        element={
          <ProtectedRoutes
            path="/investors/investment_accounts"
            component={<InvestmentAccountsListing />}
          />
        }
      />

      <Route
        path="/investors/investment_accounts/:mfiaId"
        element={
          <ProtectedRoutes
            path="/investors/investment_accounts/:mfiaId"
            component={<InvestmentAccountsDetails />}
          />
        }
      />

      <Route
        path="/investors/investors-overview"
        element={
          <ProtectedRoutes path="/investors/investors-overview" component={<InvestorsListing />} />
        }
      />

      <Route
        path="/investors/investors-overview/v1/:investorId"
        element={
          <ProtectedRoutes
            path="/investors/investors-overview/v1/:investorId"
            component={<InvestorDetails />}
          />
        }
      />

      <Route
        path="/investors/investors-overview/v2/:investorProfileId"
        element={
          <ProtectedRoutes
            path="/investors/investors-overview/v2/:investorProfileId"
            component={<InvestorProfileDetails />}
          />
        }
      />

      <Route
        path="/investors/bank-account-verifications"
        element={
          <ProtectedRoutes
            path="/investors/bank-account-verifications"
            component={<BankAccountVerificationsListing />}
          />
        }
      />

      <Route
        path="/investors/non-individual-investors"
        element={
          <ProtectedRoutes
            path="/investors/non-individual-investors"
            component={<NonIndividualInvestorProfilesListing />}
          />
        }
      />

      <Route
        path="/investors/non-individual-investors/:nonIndividualInvestorProfileId"
        element={
          <ProtectedRoutes
            path="/investors/non-individual-investors/:nonIndividualInvestorProfileId"
            component={<NonIndividualInvestorProfileDetails />}
          />
        }
      />

      <Route
        path="/investors/kyc_requests"
        element={
          <ProtectedRoutes path="/investors/kyc_requests" component={<KYCRequestsListing />} />
        }
      />

      <Route
        path="/investors/kyc_requests/:kycRequestId"
        element={
          <ProtectedRoutes
            path="/investors/kyc_requests/:kycRequestId"
            component={<KYCRequestsDetails />}
          />
        }
      />

      {/*************************** Developer Routes ***************************/}

      <Route
        path="/developers/api-logs"
        element={<ProtectedRoutes path="/developers/api-logs" component={<ListApiLogs />} />}
      />

      <Route
        path="/developers/events"
        element={<ProtectedRoutes path="/developers/events" component={<EventsListing />} />}
      />

      <Route
        path="/developers/api-logs/:id"
        element={
          <ProtectedRoutes path="/developers/api-logs/:id" component={<ViewApiLogDetaills />} />
        }
      />

      {/*************************** FP Account Routes ***************************/}

      <Route
        path="/fp-account/verifications"
        element={
          <ProtectedRoutes path="/fp-account/verifications" component={<VerificationService />} />
        }
      />

      <Route
        path="/fp-account/payments_gateway"
        element={
          <ProtectedRoutes
            path="/fp-account/payments_gateway"
            component={<ManagePaymentsGateway />}
          />
        }
      />

      <Route
        path="/fp-account/mf_services"
        element={
          <ProtectedRoutes
            path="/fp-account/mf_services"
            component={<OrderConfigurationService />}
          />
        }
      />

      <Route
        path="/fp-account/amcs"
        element={
          <ProtectedRoutes path="/fp-account/amcs" component={<AmcConfigurationService />} />
        }
      />

      <Route
        path="/fp-account/kyc-services"
        element={<ProtectedRoutes path="/fp-account/kyc-services" component={<KYCService />} />}
      />

      <Route
        path="/fp-account/advisory"
        element={<ProtectedRoutes path="/fp-account/advisory" component={<AdvisoryService />} />}
      />

      <Route
        path="/fp-account/bi-tool"
        element={<ProtectedRoutes path="/fp-account/bi-tool" component={<BITool />} />}
      />

      <Route
        path="/fp-account/configuration-status"
        element={
          <ProtectedRoutes
            path="/fp-account/configuration-status"
            component={<ConfigurationStatus />}
          />
        }
      />

      <Route
        path="/fp-account/user_management"
        element={
          <ProtectedRoutes
            path="/fp-account/user_management"
            component={<TenantUsersManagement />}
          />
        }
      />

      <Route
        path="/fp-account/scheduled-queries"
        element={
          <ProtectedRoutes path="/fp-account/scheduled-queries" component={<ScheduledQueries />} />
        }
      />

      <Route
        path="/fp-account/scheduled-queries/scheduled-query-runs/:scheduled_query_id"
        element={
          <ProtectedRoutes
            path="/fp-account/scheduled-queries/scheduled-query-runs/:scheduled_query_id"
            component={<ScheduledQueryRuns />}
          />
        }
      />

      <Route
        path="/fp-account/notification-webhooks"
        element={
          <ProtectedRoutes
            path="/fp-account/notification-webhooks"
            component={<NotificationWebhooks />}
          />
        }
      />

      <Route
        path="/fp-account/notification-webhooks/:webhook_id"
        element={
          <ProtectedRoutes
            path="/fp-account/notification-webhooks/:webhook_id"
            component={<EditNotificationWebhooks />}
          />
        }
      />

      <Route
        path="/fp-account/user-management/new"
        element={
          <ProtectedRoutes
            path="/fp-account/user-management/new"
            component={
              <ManageUser
                mode="CREATE"
                userCreationType="TENANT"
                onBack={() => {
                  navigate("/fp-account/user_management");
                }}
                onComplete={() => {
                  navigate("/fp-account/user_management");
                }}
              />
            }
          />
        }
      />

      <Route
        path="/fp-account/user-management/:id"
        element={
          <ProtectedRoutes
            path="/fp-account/user-management/:id"
            component={
              <ManageUser
                mode="EDIT"
                userCreationType="TENANT"
                onBack={() => {
                  navigate("/fp-account/user_management");
                }}
                onComplete={({ data }) => {
                  navigate("/fp-account/user_management");

                  if (data?.username === userInfo?.data.userInfo?.email) {
                    window.location.reload();
                  }
                }}
              />
            }
          />
        }
      />

      <Route
        path="/fp-account/fp-assist"
        element={<ProtectedRoutes path="/fp-account/fp-assist" component={<FpAssist />} />}
      />

      {/*************************** Partner Routes ***************************/}
      <Route
        path="/partners"
        element={<ProtectedRoutes path="/partners" component={<PartnersListing />} />}
      />

      <Route
        path="/partners/new"
        element={<ProtectedRoutes path="/partners/new" component={<PartnerAddition />} />}
      />

      <Route
        path="/partners/:partnerId"
        element={<ProtectedRoutes path="/partners/:partnerId" component={<PartnerDetails />} />}
      />

      {/*************************** Partner flow ***************************/}
      <Route
        path="/partner/assisted-checkout"
        element={
          <ProtectedRoutes
            path="/partner/assisted-checkout"
            component={
              <RoboxAppInit>
                <AssistedCheckout
                  isin={searchParams.get("isin") ?? ""}
                  switch_out={searchParams.get("switch_out") ?? ""}
                  isPartner={true}
                />
              </RoboxAppInit>
            }
          />
        }
      />
      <Route
        path="/partner/onboarding"
        element={
          <RoboxAppInit>
            <AssistedOnboarding
              isPartner={searchParams.get("isPartner") === "true"}
              isGroupInvestment={false}
              redirectURL={decodeURIComponent(searchParams.get("redirectURL") ?? "")}
            />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/assisted-withdraw"
        element={
          <RoboxAppInit>
            <AssitedWithdraw
              isin={searchParams.get("isin")}
              folio={searchParams.get("folio")}
              isPartner={true}
            />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/assisted-profile-details"
        element={
          <RoboxAppInit>
            <AssistedInvestorDetails isPartner={true} />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/assisted-onboarding"
        element={
          <RoboxAppInit>
            <AssistedOnboarding
              isPartner={true}
              redirectURL={searchParams.get("redirectURL") ?? undefined}
            />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/assisted-order-history"
        element={
          <RoboxAppInit>
            <AssistedOrderHistory />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/assisted-plans-list"
        element={
          <RoboxAppInit>
            <AssistedPlansList
              isGroupInvestment={searchParams.get("isGroupInvestment") === "true"}
              mandate_id={searchParams.get("mandate_id") ?? ""}
              isPartner={true}
            />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/folios"
        element={
          <RoboxAppInit>
            <AssistedFolios isPartner={true} />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/plan-update"
        element={
          <RoboxAppInit>
            <AssistedPlanUpdate
              type={searchParams.get("type") ?? ""}
              id={searchParams.get("id") ?? ""}
              editField={searchParams.get("edit_field") ?? ""}
            />
          </RoboxAppInit>
        }
      />
      <Route
        path="/partner/status/mandate"
        element={
          <RoboxAppInit>
            <AssistedMandateStatus mandate_id={searchParams.get("id") ?? ""} />
          </RoboxAppInit>
        }
      />

      {/*************************** Not found route ***************************/}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
