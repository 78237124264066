import { MenuIcon, MenuItem, MenuText, Tooltip, WidgetListItem } from "pixel";
import { Link, useLocation } from "react-router-dom";
import { RenderByUserRole } from "../accessControl/ComponentRBAC";
import { RouteAccessMap, getMenuRoles } from "../accessControl/routeAccess";
import { useUserInfo } from "../context/userinfo/useUserInfo";
import useUIState from "../hooks/useUIState";
import { isAuthorisedRole } from "./utils";

export const ProtectedMenu = ({
  matchRoute,
  matchExact,
  children,
}: {
  matchRoute: string;
  children: React.ReactNode;
  matchExact?: boolean;
}) => {
  const userInfo = useUserInfo();
  const currentRoles = userInfo?.data.userInfo?.roles;

  return isAuthorisedRole(getMenuRoles(matchRoute, matchExact) ?? [], currentRoles) ? (
    children
  ) : (
    <></>
  );
};

export const ProtectedMenuItem = ({
  redirectTo,
  label,
  route,
}: {
  redirectTo: string;
  label: string;
  route?: string;
}) => {
  const location = useLocation();
  const userInfo = useUserInfo();
  const { handleToggleMobileMenu } = useUIState();
  const currentRoles = userInfo?.data.userInfo?.roles;

  const matchCurrentPath = (requiredRoute: string) => {
    const currentPath = location.pathname;
    return currentPath.includes(requiredRoute);
  };

  const requiredRoute = redirectTo.split("?")[0];

  return (
    isAuthorisedRole(
      (route ? RouteAccessMap[route] : RouteAccessMap[requiredRoute]) ?? [],
      currentRoles
    ) && (
      <WidgetListItem active={matchCurrentPath(requiredRoute)}>
        <Link to={redirectTo} onClick={() => handleToggleMobileMenu(true)}>
          {label}
        </Link>
      </WidgetListItem>
    )
  );
};

export const ProtectedExternalLink = ({
  link,
  label,
  shouldOpenLink = true,
  tooltipText,
}: {
  readonly link: string;
  readonly label: string;
  readonly shouldOpenLink?: boolean;
  readonly tooltipText?: string;
}) => {
  const { isSideBarCollapsed } = useUIState();

  return (
    <RenderByUserRole resource="routeHelpers.ProtectedExternalLink">
      <MenuItem>
        <Link
          to={link}
          onClick={
            shouldOpenLink
              ? undefined
              : (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {tooltipText ? (
            <Tooltip
              placement="right"
              content={tooltipText}
              hidden={!isSideBarCollapsed}
              customClass="block"
            >
              <MenuIcon>ExternalLink</MenuIcon>
            </Tooltip>
          ) : (
            <MenuIcon>ExternalLink</MenuIcon>
          )}
          <MenuText>{label}</MenuText>
        </Link>
      </MenuItem>
    </RenderByUserRole>
  );
};

export const ProtectedExternalLinkButton = ({
  link,
  label,
  shouldOpenLinkHandler,
  tooltipText,
}: {
  readonly link: string;
  readonly label: string;
  readonly tooltipText?: string;
  readonly shouldOpenLinkHandler?: () => Promise<boolean>;
}) => {
  const { isSideBarCollapsed } = useUIState();
  const handleClick = async () => {
    if (shouldOpenLinkHandler && (await shouldOpenLinkHandler())) {
      window.open(link, "_blank", "noopener noreferrer");
    }
  };

  return (
    <RenderByUserRole resource="routeHelpers.ProtectedExternalLinkButton">
      <MenuItem>
        <button onClick={handleClick} className="flex flex-1">
          {tooltipText ? (
            <Tooltip
              placement="right"
              content={tooltipText}
              hidden={!isSideBarCollapsed}
              customClass="block"
            >
              <MenuIcon>ExternalLink</MenuIcon>
            </Tooltip>
          ) : (
            <MenuIcon>ExternalLink</MenuIcon>
          )}
          <MenuText>{label}</MenuText>
        </button>
      </MenuItem>
    </RenderByUserRole>
  );
};
